import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Menu,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import BluePrimaryButton from "../../common/button/bluePrimaryButton";
import GreenButton from "../../common/button/greenButton";
import { cssProperties } from "../../utils/commonCssProperties";
import CommonTable from "./../../common/CommonTable";
import CloseButton from "../../common/button/closeButton";
import DangerButton from "../../common/button/DangerButton";
import BrowseUploadButton from "../../common/button/browseUploadButton";
import axios from "axios";
import { API_URL, NODE_ENV } from "../../utils/constants";
import { IoCloudUploadOutline } from "react-icons/io5";
import { PiXBold } from "react-icons/pi";
import xlsxpng from "../../assets/images/uploads/xlsx.png";
import MauvelousPrimaryButton from "./../../common/button/mauvelousPrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import masterActions from "../../redux/master/actions";
import venueActions from "../../redux/venue/actions";
import { useLocation } from "react-router-dom";
import { io } from "socket.io-client";
import xlsx from "json-as-xlsx";
import commonActions from "../../redux/common/actions";

// let data = [
//   {
//     name: "Candidates",
//     value: "1256",
//   },
//   {
//     name: "Venues",
//     value: "324",
//   },
//   {
//     name: "Venue Capacity for batch",
//     value: "324",
//   },
// ];
// let data2 = [
//   {
//     name: "Non Allocated Candidates",
//     value: "1256",
//   },
//   {
//     name: "Diability Candidates",
//     value: "324",
//   },
// ];
// let tableData = [
//   {
//     course_name: "course_1",
//     candidate_count: 10245,
//     exam_date: "23/05/2024",
//     batch_no: 1,
//     first_preference: "-----",
//     second_preference: "-----",
//     third_preference: "-----",
//   },
//   {
//     course_name: "course_2",
//     candidate_count: 10245,
//     exam_date: "23/05/2024",
//     batch_no: 1,
//     first_preference: "-----",
//     second_preference: "-----",
//     third_preference: "-----",
//   },
// ];

let data = [
  {
    sheet: "Venue Master Template",
    columns: [
      { label: "venuetype" },
      { label: "buildingtype" },
      { label: "village" },
      { label: "city" },
      { label: "taluka" },
      { label: "district" },
      { label: "state" },
      { label: "venueid" },
      { label: "seatsavailability" },
      { label: "venuename" },
      { label: "pincode" },
      { label: "landmark" },
      { label: "addressline1" },
      { label: "addressline2" },
      { label: "suitableforpwd" },
      { label: "googlemaplink" },
    ],
    content: [],
  },
];

let settings = {
  fileName: "venue allocation template",
  extraLength: 3,
  writeMode: "writeFile",
  writeOptions: {},
  RTL: false,
};

let callback = function (sheet) {
  // console.log("Download complete:", sheet);
};
let columnData = [
  {
    name: "Course Name",
    dataIndex: "coursename",
  },
  {
    name: "Candidate Count",
    dataIndex: "candidatecount",
  },
  {
    name: "Exam Date",
    dataIndex: "examdate",
  },
  {
    name: "Batch No",
    dataIndex: "batchno",
  },
  {
    name: "Allocated based on 1st preference",
    dataIndex: "preference1",
  },
  {
    name: "Allocated based on 2nd preference",
    dataIndex: "preference2",
  },
  {
    name: "Allocated based on 3rd preference",
    dataIndex: "preference3",
  },
  {
    name: "Allocated based on 4th preference",
    dataIndex: "preference4",
  },
  {
    name: "Manually Allocated Candidates",
    dataIndex: "preference5",
  },
];

const Index = () => {
  const socketRef = useRef(null);

  // const socket = io(`http://localhost:3001`);

  const [file, setFile] = useState(null);
  const [courseid, setCourseId] = useState(0);
  const [pcmb, setPcmb] = useState(0);
  const [open, setOpen] = useState(false);
  const [intervalId, setIntervalId] = useState(null);

  const [statusModalopen, setStatusModalOpen] = useState({
    open: false,
    statustext: "",
  });
  const [loading, setLoading] = useState(false);
  const [preferenceid, setPreferenceId] = useState(0);
  const [isLogicApplied, setIsLogicApplied] = useState("0");
  const id = useLocation()?.state?.id;
  // console.log(id, 'cid')

  const dispatch = useDispatch();
  const { courseList } = useSelector((state) => state.masterReducer);
  const {
    candidateCount,
    venueDetails,
    seatAllocationData,
    candidateAllocationDetails,
  } = useSelector((state) => state.venueReducer);

  const { backofficeTokenDetails } = useSelector((state) => state.authReducer);
  // console.log(courseList, 'clist')
  // console.log(candidateCount, 'cndcount')
  // console.log(candidateAllocationDetails, "cad");
  let getDataInterval = (courseid, currentVenueId) => {
    let intervalId = setInterval(() => {
      socketRef?.current?.emit("listenstatus", { courseid, currentVenueId });
    }, 1000);
    return intervalId;
  };

  useEffect(() => {
    socketRef.current = io(
      `${API_URL}`,
      NODE_ENV === "production" && {
        path: "/api/socket.io",
        //     transports: ['websocket', 'polling'],
        // reconnection: true,
        // reconnectionAttempts: 5,
        // reconnectionDelay: 1000
      }
    );
    socketRef?.current?.on("listenmessage", (message) => {
      console.log(message.data, "mess", courseid);
      // let data = message?.data
      if (message?.statusCode === 200) {
        let statustext = message.data.trim();
        // console.log(statustext, 'stt',statustext?.toLowerCase())
        if (statustext) {
          if (statustext?.toLowerCase() !== "completed") {
            //   console.log("not completed");
            setStatusModalOpen({
              open: true,
              statustext:
                statustext?.trim() !== "" && statustext !== null
                  ? statustext
                  : statusModalopen.statustext,
            });
          } else {
            //   console.log("completed");
            setStatusModalOpen({
              open: false,
              statustext:
                statustext?.toLowerCase() !== "completed"
                  ? ""
                  : statusModalopen.statustext,
            });
            statustext?.toLowerCase() !== "completed" &&
              dispatch({
                type: venueActions.GET_SEAT_ALLOCATION,
                payload: { courseid },
              });
            dispatch({
              type: venueActions.GET_CANDIDATE_ALLOCATION_DEAILS,
              payload: { courseid },
            });
            clearInterval(intervalId);
            dispatch({
              type: commonActions.SET_ALERT,
              payload: {
                status: "success",
                show: true,
                message: "seat allocated successfully",
              },
            });

            setTimeout(function () {
              dispatch({
                type: commonActions.SET_ALERT,
                payload: {
                  status: "success",
                  show: false,
                  message: null,
                },
              });
            }, 2000);
          }
        }
        //  else {
        //   setStatusModalOpen({
        //     open: false,
        //     statustext: "",
        //   });
        // }
      }
    });
    return () => (socketRef.current = null);
  }, [courseid, dispatch, intervalId, socketRef]);
  useEffect(() => {
    id ? setCourseId(id) : setCourseId(0);
    id &&
      dispatch({
        type: venueActions.GET_VENUE_DETAILS,
        payload: { courseid: id },
      });
    id &&
      dispatch({
        type: venueActions.GET_CANDIDATE_COUNT,
        payload: { courseid: id },
      });
    id &&
      dispatch({
        type: venueActions.GET_SEAT_ALLOCATION,
        payload: { courseid: id },
      });
    id &&
      dispatch({
        type: venueActions.GET_CANDIDATE_ALLOCATION_DEAILS,
        payload: { id },
      });
  }, [id, dispatch]);
  useEffect(() => {
    dispatch({
      type: venueActions.GET_VENUE_DETAILS,
      payload: { courseid: courseid },
    });
    dispatch({
      type: venueActions.GET_CANDIDATE_COUNT,
      payload: { courseid: courseid },
    });
    dispatch({
      type: venueActions.GET_SEAT_ALLOCATION,
      payload: { courseid: courseid },
    });
    dispatch({
      type: venueActions.GET_CANDIDATE_ALLOCATION_DEAILS,
      payload: { courseid },
    });
  }, [courseid, dispatch]);
  useEffect(() => {
    dispatch({ type: masterActions.GET_COURSE_LIST });
    dispatch({ type: venueActions.GET_SEAT_ALLOCATION_DETAILS });
  }, [dispatch]);
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy"; // Show the copy cursor when dragging
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleDocumentUpload(e);
  };
  const handleDocumentUpload = (e) => {
    const file = e.target.files[0] || e.dataTransfer.files[0];
    // console.log(file, 'file')
    setFile(file);
  };
  const handleUploadVenue = async () => {
    console.log("entered");
    // setFile(e.target.files[0])
    dispatch({
      type: venueActions.UPLOAD_VENUE_DATA,
      payload: { courseid, file, setOpen, setLoading },
    });
  };
  const ToggleOpen = () => {
    setOpen(!open);
  };
  const onChangeCourse = async (e) => {
    setPcmb(0);
    setCourseId(e.target.value);
    dispatch({
      type: venueActions.GET_CANDIDATE_COUNT,
      payload: { courseid: e.target.value },
    });
    dispatch({
      type: venueActions.GET_VENUE_DETAILS,
      payload: { courseid: e.target.value },
    });
    dispatch({
      type: venueActions.GET_SEAT_ALLOCATION,
      payload: { courseid: e.target.value },
    });
  };

  const onRunPreference = async () => {
    if (
      courseid
      // && (courseList?.all?.find(obj=>obj.courseid == courseid)?.ispcmb ? pcmb : 1)
    ) {
      dispatch({
        type: venueActions.RUN_SEAT_ALLOCATION,
        payload: {
          courseid: courseid,
          preferenceid: preferenceid,
          adminuserid: backofficeTokenDetails?.id,
          getDataInterval: getDataInterval,
          setIntervalId: setIntervalId,
          pcmb: pcmb,
          ispcmb: courseList?.all?.find((obj) => obj.courseid == courseid)
            ?.ispcmb,
          setStatusModalOpen: setStatusModalOpen,
          //   getDataInterval: () =>
          //     socketRef?.current?.emit("listenstatus", { courseid }),
        },
      });
    }
  };
  const handlePreferenceChange = (e) => {
    setPreferenceId(e.target.value);
  };

  const onReset = (courseid, preferenceid = 0) => {
    courseid &&
      dispatch({
        type: venueActions.RESET_SEAT_ALLOCATION,
        payload: { courseid, preferenceid, userid: backofficeTokenDetails?.id },
      });
  };
  const onChangePCMB = (e) => {
    setPcmb(e.target.value);
  };
  const handleLogicAppliedChange = (e) => {
    setIsLogicApplied(e.target.value);
  };
  return (
    <>
      <Card
        sx={{
          width: "100%",
          textAlign: "start",
          borderRadius: "8px",
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "none",
          padding: 2,
          background: "none",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Typography
            variant="p"
            color="#212121"
            fontWeight={600}
            sx={{ paddingBottom: 2 }}
            component="p"
          >
            Get Allocated Data
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item md={5}>
            <Box>
              <Select
                size="small"
                onChange={onChangeCourse}
                value={courseid}
                defaultValue={0}
                sx={{ width: "100%", minWidth: "200px" }}
              >
                <MenuItem value={0} selected>
                  Courses
                </MenuItem>
                {courseList?.all?.map((course, i) => (
                  <MenuItem key={i} value={course.courseid}>
                    {course.coursename}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          <Grid item md={5}>
            {courseList?.all?.find((obj) => obj.courseid == courseid)
              ?.ispcmb && (
              <Box>
                <Select
                  size="small"
                  onChange={onChangePCMB}
                  value={pcmb}
                  defaultValue={0}
                  sx={{ width: "100%", minWidth: "200px" }}
                >
                  <MenuItem value={0} selected>
                    Select
                  </MenuItem>
                  <MenuItem value={1}>PCM</MenuItem>

                  <MenuItem key={2} value={2}>
                    PCB
                  </MenuItem>
                </Select>
              </Box>
            )}
          </Grid>

          <Grid item md={12}>
            <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
              <BluePrimaryButton title="Get Venue data(API)" />
              <BluePrimaryButton
                handleSubmit={ToggleOpen}
                title="Browse and Upload Venue data"
              />
              <GreenButton
                handleSubmit={() => xlsx(data, settings, callback)}
                title="Download Template"
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} paddingTop={2}>
          <Grid item md={2} sm={4}>
            <Card
              sx={{
                height: "100%",
                width: "100%",
                textAlign: "center",
                borderRadius: "8px",
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "none",
                padding: 2,
                border: `1px solid ${cssProperties.bordercolor.secondary}`,
              }}
            >
              <Typography
                variant="p"
                color={cssProperties.color.failure}
                fontWeight={600}
                sx={{ paddingBottom: 2 }}
                component="p"
              >
                {candidateCount || 0}
              </Typography>
              <Typography
                variant="p"
                color="#212121"
                fontWeight={600}
                sx={{ paddingBottom: 2 }}
                component="p"
              >
                {"Candidates"}
              </Typography>
            </Card>
          </Grid>
          <Grid item md={2} sm={4}>
            <Card
              sx={{
                height: "100%",
                width: "100%",
                textAlign: "center",
                borderRadius: "8px",
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "none",
                padding: 2,
                border: `1px solid ${cssProperties.bordercolor.secondary}`,
              }}
            >
              <Typography
                variant="p"
                color={cssProperties.color.failure}
                fontWeight={600}
                sx={{ paddingBottom: 2 }}
                component="p"
              >
                {venueDetails?.venues || 0}
              </Typography>
              <Typography
                variant="p"
                color="#212121"
                fontWeight={600}
                sx={{ paddingBottom: 2 }}
                component="p"
              >
                {"Venues"}
              </Typography>
            </Card>
          </Grid>
          <Grid item md={2} sm={4}>
            <Card
              sx={{
                height: "100%",
                width: "100%",
                textAlign: "center",
                borderRadius: "8px",
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "none",
                padding: 2,
                border: `1px solid ${cssProperties.bordercolor.secondary}`,
              }}
            >
              <Typography
                variant="p"
                color={cssProperties.color.failure}
                fontWeight={600}
                sx={{ paddingBottom: 2 }}
                component="p"
              >
                {venueDetails?.venueCount || 0}
              </Typography>
              <Typography
                variant="p"
                color="#212121"
                fontWeight={600}
                sx={{ paddingBottom: 2 }}
                component="p"
              >
                {"Venue Capacity for batch"}
              </Typography>
            </Card>
          </Grid>
        </Grid>
        <Box sx={{ width: "100%", paddingTop: 2 }}>
          <Typography
            variant="p"
            color="#212121"
            fontWeight={600}
            sx={{ paddingBottom: 2 }}
            component="p"
          >
            Allocate Venue
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item md={5}>
            <Box>
              <Select
                fullWidth
                defaultValue={0}
                size="small"
                onChange={handlePreferenceChange}
              >
                <MenuItem value={0}>Overall Preference</MenuItem>
                <MenuItem value={1}>Preference 1</MenuItem>
                <MenuItem value={2}>Preference 2</MenuItem>
                <MenuItem value={3}>Preference 3</MenuItem>
                <MenuItem value={4}>Preference 4</MenuItem>
                <MenuItem value={5}>Preference 5</MenuItem>
              </Select>
            </Box>
          </Grid>
          <Grid item md={3}>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Is Logic Applied
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={isLogicApplied}
                onChange={handleLogicAppliedChange}
                row
              >
                <FormControlLabel value="1" control={<Radio />} label="Yes" />
                <FormControlLabel value="0" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item md={4}>
            <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
              <BluePrimaryButton title="Run" handleSubmit={onRunPreference} />
              <BluePrimaryButton
                title="Reset Data"
                handleSubmit={() => onReset(courseid, preferenceid)}
              />
              {/* <GreenButton title="Download Venue Template" /> */}
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ width: "100%", textAlign: "center" }} paddingTop={2}>
          <CommonTable tableData={seatAllocationData} columnData={columnData} />
        </Box>
        <Grid container spacing={2} paddingTop={2}>
          <Grid item md={2} sm={4}>
            <Card
              onClick={() => {
                // console.log(candidateAllocationDetails?.allocated);
                // candidateAllocationDetails?.allocated.length > 0 &&
                // xlsx(
                //   candidateAllocationDetails?.allocated,
                //   { ...settings, fileName: "Allocated Candidates" },
                //   callback
                // );
                console.log(
                  candidateAllocationDetails?.allocated,
                  "data for download"
                );
                if (
                  candidateAllocationDetails?.allocated &&
                  candidateAllocationDetails?.allocated.length > 0
                ) {
                  let columns = [];

                  for (
                    let i = 0;
                    i <
                    Object.keys(candidateAllocationDetails?.allocated[0])
                      .length;
                    i++
                  ) {
                    let obj = {};
                    obj.label = Object.keys(
                      candidateAllocationDetails?.allocated[0]
                    )[i];
                    obj.value = Object.keys(
                      candidateAllocationDetails?.allocated[0]
                    )[i];
                    columns.push(obj);
                  }

                  let data = [
                    {
                      sheet: "Allocated Candidate Details",
                      columns: columns,
                      content: candidateAllocationDetails?.allocated,
                    },
                  ];
                  console.log(columns, data);
                  xlsx(
                    data,
                    { ...settings, fileName: "Allocated Candidates" },
                    callback
                  );
                } else {
                  console.log("No data available to download");
                }
              }}
              sx={{
                height: "100%",
                width: "100%",
                textAlign: "center",
                borderRadius: "8px",
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "none",
                padding: 2,
                border: `1px solid ${cssProperties.bordercolor.secondary}`,
              }}
            >
              <Typography
                variant="p"
                color={cssProperties.color.failure}
                fontWeight={600}
                sx={{ paddingBottom: 2 }}
                component="p"
              >
                {candidateAllocationDetails?.allocated.length}
              </Typography>
              <Typography
                variant="p"
                color="#212121"
                fontWeight={600}
                sx={{ paddingBottom: 2 }}
                component="p"
              >
                Allocated Candidates
              </Typography>
            </Card>
          </Grid>
          <Grid item md={2} sm={4}>
            <Card
              onClick={() => {
                // console.log(candidateAllocationDetails?.allocated);
                // candidateAllocationDetails?.allocated.length > 0 &&
                // xlsx(
                //   candidateAllocationDetails?.allocated,
                //   { ...settings, fileName: "Allocated Candidates" },
                //   callback
                // );
                console.log(
                  candidateAllocationDetails?.notallocated,
                  "data for download"
                );
                if (
                  candidateAllocationDetails?.notallocated &&
                  candidateAllocationDetails?.notallocated.length > 0
                ) {
                  let columns = [];

                  for (
                    let i = 0;
                    i <
                    Object.keys(candidateAllocationDetails?.notallocated[0])
                      .length;
                    i++
                  ) {
                    let obj = {};
                    obj.label = Object.keys(
                      candidateAllocationDetails?.notallocated[0]
                    )[i];
                    obj.value = Object.keys(
                      candidateAllocationDetails?.notallocated[0]
                    )[i];
                    columns.push(obj);
                  }

                  let data = [
                    {
                      sheet: "notallocated Candidate Details",
                      columns: columns,
                      content: candidateAllocationDetails?.notallocated,
                    },
                  ];
                  console.log(columns, data);
                  xlsx(
                    data,
                    { ...settings, fileName: "notallocated Candidates" },
                    callback
                  );
                } else {
                  console.log("No data available to download");
                }
              }}
              sx={{
                height: "100%",
                width: "100%",
                textAlign: "center",
                borderRadius: "8px",
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "none",
                padding: 2,
                border: `1px solid ${cssProperties.bordercolor.secondary}`,
              }}
            >
              <Typography
                variant="p"
                color={cssProperties.color.failure}
                fontWeight={600}
                sx={{ paddingBottom: 2 }}
                component="p"
              >
                {candidateAllocationDetails?.notallocated.length}
              </Typography>
              <Typography
                variant="p"
                color="#212121"
                fontWeight={600}
                sx={{ paddingBottom: 2 }}
                component="p"
              >
                Non Allocated Candidates
              </Typography>
            </Card>
          </Grid>
          <Grid item md={2} sm={4}>
            <Card
              onClick={() => {
                // console.log(candidateAllocationDetails?.allocated);
                // candidateAllocationDetails?.allocated.length > 0 &&
                // xlsx(
                //   candidateAllocationDetails?.allocated,
                //   { ...settings, fileName: "Allocated Candidates" },
                //   callback
                // );
                console.log(
                  candidateAllocationDetails?.disabilityallocated,
                  "data for download"
                );
                if (
                  candidateAllocationDetails?.disabilityallocated &&
                  candidateAllocationDetails?.disabilityallocated.length > 0
                ) {
                  let columns = [];

                  for (
                    let i = 0;
                    i <
                    Object.keys(
                      candidateAllocationDetails?.disabilityallocated[0]
                    ).length;
                    i++
                  ) {
                    let obj = {};
                    obj.label = Object.keys(
                      candidateAllocationDetails?.disabilityallocated[0]
                    )[i];
                    obj.value = Object.keys(
                      candidateAllocationDetails?.disabilityallocated[0]
                    )[i];
                    columns.push(obj);
                  }

                  let data = [
                    {
                      sheet: "disabilityallocated",
                      columns: columns,
                      content: candidateAllocationDetails?.disabilityallocated,
                    },
                  ];
                  console.log(columns, data);
                  xlsx(
                    data,
                    { ...settings, fileName: "disabilityallocated" },
                    callback
                  );
                } else {
                  console.log("No data available to download");
                }
              }}
              sx={{
                height: "100%",
                width: "100%",
                textAlign: "center",
                borderRadius: "8px",
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "none",
                padding: 2,
                border: `1px solid ${cssProperties.bordercolor.secondary}`,
              }}
            >
              <Typography
                variant="p"
                color={cssProperties.color.failure}
                fontWeight={600}
                sx={{ paddingBottom: 2 }}
                component="p"
              >
                {candidateAllocationDetails?.disabilityallocated.length}
              </Typography>
              <Typography
                variant="p"
                color="#212121"
                fontWeight={600}
                sx={{ paddingBottom: 2 }}
                component="p"
              >
                Allocated Disability Candidates
              </Typography>
            </Card>
          </Grid>
          <Grid item md={2} sm={4}>
            <Card
              onClick={() => {
                // console.log(candidateAllocationDetails?.allocated);
                // candidateAllocationDetails?.allocated.length > 0 &&
                // xlsx(
                //   candidateAllocationDetails?.allocated,
                //   { ...settings, fileName: "Allocated Candidates" },
                //   callback
                // );
                console.log(
                  candidateAllocationDetails?.disabilitynotallocated,
                  "data for download"
                );
                if (
                  candidateAllocationDetails?.disabilitynotallocated &&
                  candidateAllocationDetails?.disabilitynotallocated.length > 0
                ) {
                  let columns = [];

                  for (
                    let i = 0;
                    i <
                    Object.keys(
                      candidateAllocationDetails?.disabilitynotallocated[0]
                    ).length;
                    i++
                  ) {
                    let obj = {};
                    obj.label = Object.keys(
                      candidateAllocationDetails?.disabilitynotallocated[0]
                    )[i];
                    obj.value = Object.keys(
                      candidateAllocationDetails?.disabilitynotallocated[0]
                    )[i];
                    columns.push(obj);
                  }

                  let data = [
                    {
                      sheet: "Non Allocated Disability",
                      columns: columns,
                      content:
                        candidateAllocationDetails?.disabilitynotallocated,
                    },
                  ];
                  console.log(columns, data);
                  xlsx(
                    data,
                    {
                      ...settings,
                      fileName: "disabilitynotallocated Candidates",
                    },
                    callback
                  );
                } else {
                  console.log("No data available to download");
                }
              }}
              sx={{
                height: "100%",
                width: "100%",
                textAlign: "center",
                borderRadius: "8px",
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "none",
                padding: 2,
                border: `1px solid ${cssProperties.bordercolor.secondary}`,
              }}
            >
              <Typography
                variant="p"
                color={cssProperties.color.failure}
                fontWeight={600}
                sx={{ paddingBottom: 2 }}
                component="p"
              >
                {candidateAllocationDetails?.disabilitynotallocated.length}
              </Typography>
              <Typography
                variant="p"
                color="#212121"
                fontWeight={600}
                sx={{ paddingBottom: 2 }}
                component="p"
              >
                Non Allocated Disability Candidates
              </Typography>
            </Card>
          </Grid>
        </Grid>

        {/* <Grid container paddingTop={2} justifyContent="end">
                    <Grid item>
                        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', justifyContent: 'end' }}>
                            <DangerButton title="Get Candidate data(API)" />
                            <DangerButton title="Browse and Upload Candidate Data" />
                            <DangerButton title="Download candidate data " />

                        </Box>
                    </Grid>
                </Grid> */}
      </Card>
      <Modal
        open={open}
        sx={{
          display: "flex",
          p: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 5,
            // border: '2px solid #000',
            boxShadow: (theme) => theme.shadows[5],
            p: 4,
          }}
        >
          {file ? (
            <Box
              pl={2}
              style={{
                width: "fit-content",
                // maxWidth: "18em",
                height: "45px",
                border: "1px solid #D5D5D5",
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                marginTop: "1rem",
                paddingLeft: "0",
                // overflow: "hidden",
                // whiteSpace: "nowrap",
                // position: 'relative'
              }}
            >
              <img
                src={xlsxpng}
                style={{ height: "auto", width: "30px", marginLeft: "5px" }}
                alt="xlsx"
              />
              <Box sx={{ paddingLeft: "10px" }}>{file?.name}</Box>
              <Box
                onClick={() => setFile(null)}
                sx={{ paddingRight: "10px", cursor: "pointer" }}
                //  sx={{position: 'absolute', right: 0, width: '1.5rem', paddingLeft: '5px', backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`}}
              >
                <PiXBold />
              </Box>
            </Box>
          ) : (
            <Box
              style={{
                border: "1px dashed gray",
                maxWidth: "330px",
                minHeight: "120px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexShrink: 340,
                position: "relative",
              }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <input
                type="file"
                name="document"
                id="document"
                accept=".xlsx"
                alt=""
                style={{
                  width: "0.1px",
                  height: "0.1px",
                  opacity: 0,
                  overflow: "hidden",
                  position: "absolute",
                  zIndex: -1,
                }}
                onChange={handleDocumentUpload}
              />

              <label
                htmlFor="document"
                style={{
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IoCloudUploadOutline style={{ marginLeft: "8px" }} size={22} />{" "}
                <span>
                  {" "}
                  &nbsp; Drag or drop your file, or{" "}
                  <span style={{ color: "blue" }}>&nbsp;browse</span>
                </span>
                <small style={{ color: "rgb(102, 102, 102)" }}>
                  (xlsx) (5mb max.)
                </small>
              </label>
            </Box>
          )}
          <Box
            mt={2}
            justifyContent="end"
            alignItems="center"
            display="flex"
            gap={1}
          >
            <BluePrimaryButton
              disabled={loading}
              handleSubmit={ToggleOpen}
              title="Close"
            />
            <MauvelousPrimaryButton
              disabled={loading || !file || courseid === 0}
              handleClick={handleUploadVenue}
              title="Submit"
            />
          </Box>
        </Box>
      </Modal>
      <Modal
        open={statusModalopen.open}
        sx={{
          display: "flex",
          p: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 5,
            // border: '2px solid #000',
            boxShadow: (theme) => theme.shadows[5],
            p: 4,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box my={2}>
            <CircularProgress
              sx={{ color: `${cssProperties?.color?.primary}` }}
              size={50}
              thickness={2}
            />
          </Box>
          {statusModalopen.statustext}
        </Box>
      </Modal>
    </>
  );
};

export default Index;
