import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  css,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { cssProperties } from "../../utils/commonCssProperties";
import BluePrimaryButton from "../../common/button/bluePrimaryButton";
import { TbBrowser, TbFileTypeXls } from "react-icons/tb";
import BackButton from "../../common/button/backButton";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import Searchbar from "../../common/searchbar";
import { FaPlus } from "react-icons/fa";
import MasterModel from "./masterModel.js";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import masterFormsActions from "../../redux/masterForms/actions.js";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import courseExamAndBatchActions from "../../redux/courseExamAndBatch/actions.js";
import commonActions from "../../redux/common/actions.js";
import dayjs from "dayjs";
import forcePushAllocationActions from "../../redux/forcePushAllocation/actions.js";
import moment from "moment-timezone";

export default function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { driveList } = useSelector((state) => state.masterFormsReducer);

  const { courseListByDrive } = useSelector(
    (state) => state.courseExamAndBatchReducer
  );

  const { showModal } = useSelector((state) => state.commonReducer);

  const { backofficeTokenDetails } = useSelector((state) => state.authReducer);

  const { examdateByCourse } = useSelector(
    (state) => state.forcePushAllocationReducer
  );
  console.log("examdateByCourse", examdateByCourse);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    trigger,
    watch,
  } = useForm();

  // const [modelOpen, setModelOpen] = useState({
  //   show: false,
  //   type: ''
  // })
  useEffect(() => {
    let startTime = watch("starttime");
    let endTime = watch("endtime");
    console.log(startTime, endTime, typeof startTime, typeof endTime);
    if (startTime && endTime) {
      const start = moment(startTime, "HH:mm");
      const end = moment(endTime, "HH:mm");
      console.log(start, end);
      console.log(startTime, endTime, "in condition");
      const duration = moment(endTime, "HH:mm").diff(
        moment(startTime, "HH:mm"),
        "minute"
      );
      console.log(duration);
      setValue(
        "duration",
        duration
          ? `${String(Math.floor(duration / 60)).padStart(2, "0")}:${String(
              duration % 60
            ).padStart(2, "0")}`
          : ""
      );
    }
  }, [watch("starttime"), watch("endtime"), setValue]);

  useEffect(() => {
    dispatch({ type: masterFormsActions.GET_DRIVE });
  }, []);

  const handleModelOpen = (type) => {
    dispatch({
      type: commonActions.SET_SHOW_MODAL,
      payload: { show: true, type: type, method: null, data: null },
    });
    // setModelOpen({ show: true, type: type });
  };

  const handleCreateExamDate = (value, handleReset) => {
    dispatch({
      type: courseExamAndBatchActions.CREATE_EXAMDATE,
      payload: {
        data: {
          courseid: parseInt(getValues("courseid")),
          examdate: dayjs(value?.examdate).format("YYYY-MM-DD"),
          userid: parseInt(backofficeTokenDetails?.id),
        },
        handleReset: handleReset,
      },
    });
  };

  const handleCreate = (data) => {
    console.log(data);
    dispatch({
      type: courseExamAndBatchActions.CREATE_EXAM_BATCH,
      payload: { data: {...data,userid: parseInt(backofficeTokenDetails?.id),}, handleReset: reset,navigate:navigate },
    })
  };

  return (
    <>
      {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '24px', marginBottom: '14px' }}>
        <Box></Box>
      </Box> */}
      <Grid
        style={{
          padding: "24px",
          // minHeight: "60vh",
          backgroundColor: "#fff",
          borderRadius: "8px",
          marginTop: "12px",
        }}
      >
        <Grid container spacing={2}>
          {/* <Grid container sx={{ display: "felx", flexDirection: "column", border: `1px solid ${cssProperties?.bordercolor?.primary2blue}`, padding: "24px 16px 24px 16px", borderRadius: "4px", justifyContent: "space-between" }}> */}
          {/* <Grid item xs={12} sm={12} md={12} lg={12} > */}
          {/* <Grid container gap={3} > */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {/* <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}> */}
            <FormControl fullWidth error={!!errors.driveid}>
              <InputLabel id="demo-simple-select-label">Drive Name</InputLabel>
              <Select
                size="small"
                id="demo-simple-select"
                label="Drive Name"
                {...register("driveid", {
                  required: "Drive is required",
                })}
                onChange={(e) => {
                  setValue("driveid", e.target.value);
                  trigger(["driveid"]);
                  dispatch({
                    type: courseExamAndBatchActions.GET_COURSE_BY_DRIVE,
                    payload: { data: parseInt(e.target.value) },
                  });
                }}
                value={watch("driveid") ?? ""}
                sx={{
                  height: "56px",
                }}
              >
                <MenuItem value={""} disabled>
                  --Select--
                </MenuItem>
                {driveList?.all?.map((val, i) => (
                  <MenuItem value={val?.driveid} key={i}>
                    {val?.drivename}
                  </MenuItem>
                ))}
              </Select>
              {errors?.driveid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors?.driveid?.message}
                </FormHelperText>
              )}
            </FormControl>

            {/* <Button variant='outlined' onClick={() => handleModelOpen('Drive Name')} sx={{ color: "black", borderColor: "#A3A3A3", padding: 1, minWidth: '0', }}
                  ><FaPlus /></Button> */}
            {/* </Box> */}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {/* <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}> */}
            <FormControl fullWidth error={!!errors.courseid}>
              <InputLabel id="demo-simple-select-label">Add course</InputLabel>
              <Select
                size="small"
                id="demo-simple-select"
                label="Add course"
                {...register("courseid", {
                  required: "Drive is required",
                })}
                disabled={watch("driveid") ? false : true}
                onChange={(e) => {
                  setValue("courseid", e.target.value);
                  trigger(["courseid"]);
                  dispatch({
                    type: forcePushAllocationActions.GET_EXAM_DATES_BY_COURSEID,
                    payload: { courseid: parseInt(e.target.value) },
                  });
                }}
                value={watch("courseid") ?? ""}
                sx={{
                  height: "56px",
                }}
              >
                <MenuItem value={""} disabled>
                  --Select--
                </MenuItem>
                {courseListByDrive?.map((val, i) => (
                  <MenuItem value={val?.courseid} key={i}>
                    {val?.coursename}
                  </MenuItem>
                ))}
              </Select>
              {errors?.courseid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors?.courseid?.message}
                </FormHelperText>
              )}
            </FormControl>

            {/* <Button variant='outlined' onClick={() => handleModelOpen('Course')} sx={{ color: "black", borderColor: "#A3A3A3", padding: 1, minWidth: '0', }} ><FaPlus /></Button> */}
            {/* </Box> */}
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: 2,
              }}
            >
              <FormControl fullWidth error={!!errors.courseexamid}>
                <InputLabel id="demo-simple-select-label">
                  Add Exam Date
                </InputLabel>
                <Select
                  size="small"
                  id="demo-simple-select"
                  label="Add Exam Date"
                  {...register("courseexamid", {
                    required: "Exam Date is required",
                  })}
                  onChange={(e) => {
                    setValue("courseexamid", e.target.value);
                    trigger(["courseexamid"]);
                  }}
                  value={watch("courseexamid") ?? ""}
                  disabled={watch("courseid") ? false : true}
                  sx={{
                    height: "56px",
                  }}
                  // inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
                >
                  <MenuItem value={""} disabled>
                    --Select--
                  </MenuItem>
                  {examdateByCourse?.map((val, i) => (
                    <MenuItem value={val?.courseexamid} key={i}>
                      {val?.examdate}
                    </MenuItem>
                  ))}
                </Select>
                {errors?.courseexamid && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors?.courseexamid?.message}
                  </FormHelperText>
                )}
              </FormControl>
              <Button
                variant="outlined"
                onClick={() => handleModelOpen("Date")}
                sx={{
                  color: "black",
                  borderColor: "#A3A3A3",
                  padding: 1,
                  minWidth: "0",
                  height: "56px",
                }}
                disabled={watch("driveid") && watch("courseid") ? false : true}
              >
                <FaPlus />
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              fullWidth
              variant="outlined"
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
              }}
              type="text"
              label={
                <span>
                  Batchname
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                </span>
              }
              placeholder="Enter Batchname"
              {...register("batchname", {
                required: "Batchname is required",
              })}
              error={!!errors.batchname}
              helperText={errors.batchname?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
            />
            {/* <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Add Batch</InputLabel>
                <Select size='small'
                  id="demo-simple-select"
                  label="Add Batch"
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>

              <Button variant='outlined' onClick={() => handleModelOpen('Batch')} sx={{ color: "black", borderColor: "#A3A3A3", padding: 1, minWidth: '0', }}
              ><FaPlus /></Button>
            </Box> */}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              label={
                <span>
                  Select Exam Start Time
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                </span>
              }
              type="time"
              // defaultValue="07:30"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              fullWidth
              {...register("starttime", {
                required: "Start time is required",
              })}
              error={!!errors.starttime}
              helperText={errors.starttime?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              label={
                <span>
                  Select Exam End Time
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                </span>
              }
              type="time"
              // defaultValue="07:30"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5
                views: ["hours", "mitenus"],
                format: "HH:mm",
                ampm: false,
              }}
              fullWidth
              {...register("endtime", {
                required: "End time is required",
              })}
              error={!!errors.endtime}
              helperText={errors.endtime?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              label={
                <span>
                  Exam Total Duration
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                </span>
              }
              // type="time"
              // defaultValue="07:30"
              InputLabelProps={{
                shrink: true,
              }}
              readOnly
              inputProps={{
                step: 60, // 5 min
                readOnly: true,
              }}
              fullWidth
              {...register("duration", {
                required: "Duration is required",
              })}
              error={!!errors.duration}
              helperText={errors.duration?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              label={
                <span>
                  Select Gate Closing Time
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                </span>
              }
              type="time"
              // defaultValue="07:30"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              fullWidth
              {...register("gateclosingtime", {
                required: "Gate closing time is required",
              })}
              error={!!errors.gateclosingtime}
              helperText={errors.gateclosingtime?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              label={
                <span>
                  Select Reporting Time
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                </span>
              }
              type="time"
              // defaultValue="07:30"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              fullWidth
              {...register("reportingtime", {
                required: "Reporting time is required",
              })}
              error={!!errors.reportingtime}
              helperText={errors.reportingtime?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          display={"flex"}
          gap={2}
          mt={5}
        >
          {BackButton({
            handleBack: () =>
              navigate(`/${Cookies.get("mhet_mst_project")}/master-list`),
          })}
          {MauvelousPrimaryButton({
            title: "Submit",
            handleClick: handleSubmit(handleCreate),
          })}
        </Grid>
      </Grid>

      {/* </Grid> */}
      {/* </Grid> */}
      <MasterModel
        modelOpen={showModal}
        // setModelOpen={setModelOpen}
        handleCreateExamDate={handleCreateExamDate}
      />
    </>
  );
}
