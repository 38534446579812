import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { cssProperties } from "../utils/commonCssProperties";

export default function ReportTable({ tableData, columnData, totalData }) {
  const [rows, setRows] = React.useState(tableData);

  React.useEffect(() => {
    setRows(tableData);
  }, [tableData]);

  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{
          background: "none",
          border: `1px solid ${cssProperties.bordercolor.primary2blue}`,
          boxShadow: "none",
          mt:3
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columnData?.map((obj, i) => (
                <TableCell
                  sx={{
                    color: cssProperties.bordercolor.primary2blue,
                    textAlign: "center",
                    borderRight: `1px solid ${cssProperties.bordercolor.primary2blue}`,
                  }}
                  key={i}
                >
                  {obj.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.length > 0 ? (
              tableData.map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {columnData.map((param, cellIndex) => (
                    <TableCell
                      component="td"
                      key={cellIndex}
                      sx={{
                        textAlign: "center",
                        borderRight: `1px solid ${cssProperties.bordercolor.primary2blue}`,
                      }}
                    >
                      {param.render
                        ? param.render(row[param.dataIndex], row)
                        : row[param.dataIndex]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columnData.length}
                  component="td"
                  sx={{
                    textAlign: "center",
                    color: cssProperties.bordercolor.primary2blue,
                  }}
                >
                   No Result Found
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              {columnData.map((param, cellIndex) => (
                <TableCell
                  component="td"
                  key={cellIndex}
                  sx={{
                    textAlign: cellIndex === 0 ? "center" : "center",
                    borderRight: `1px solid ${cssProperties.bordercolor.primary2blue}`,
                    fontWeight: "bold",
                  }}
                >
                  {cellIndex === 0 ? "Total" : totalData[param.dataIndex] || ""}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
