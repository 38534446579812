import React from "react";
import GenderMaster from "./genderMaster";
import MothertongueMaster from "./mothertongueMaster";
import ReligionMaster from "./religionMaster";
import RegionMaster from "./regionMaster";
import FamilyincomeMaster from "./familyincomeMaster";
import NationalityMaster from "./nationalityMaster";
import LanguageMaster from "./languageMaster";
import MinorityMaster from "./minorityMaster";
import StateMaster from "./stateMaster";
import DistrictMaster from "./districtMaster";
import TalukaMaster from "./talukaMaster";
import VillageMaster from "./villageMaster";
import CategoryMaster from "./categoryMaster";
import CasteMaster from "./casteMaster";
import PwdtypeMaster from "./pwdtypeMaster";
import EquivalentboardMaster from "./equivalentboardMaster";
import BoardMaster from "./boardMaster";
import GrievancetypeMaster from "./grievancetypeMaster";
import GrievanceCategoryMaster from "./grievanceCategegoryMaster";
import DocumentTypeMaster from "./documenttypeMaster";
import CourseCategoryMaster from "./courseCategoryMaster";
import CourseTabMaster from "./courseTabMaster";
import CoursefieldMaster from "./coursefieldMaster";
import BuildingTypeMaster from "./buildingTypeMaster";
import VenueTypeMaster from "./venueTypeMaster";
import Admitcard from "./admitCard";
import ScoreCard from "./scoreCard";
import AwardClassMaster from "./awardClassMaster";
import GraduationBranchMaster from "./graduationBranchMaster";
import SportsBodyMaster from "./sportsBodyMaster";
import SportsMaster from "./sportsMaster";
import SportsParticipationMaster from "./sportsParticipationMaster";
import SportsRankMaster from "./sportsRankMaster";
import CetExamMaster from "./cetExamMaster";
import CvcAuthorityMaster from "./cvcAuthorityMaster";
import DriveMaster from "./driveMaster";
import EducationClassMaster from "./educationClassMaster";
import EducationStreamMaster from "./educationStreamMaster";
import EligibilityQualificationMaster from "./eligibilityQualificationMaster";
import ExamLanguageMaster from "./examLanguageMaster";
import GspecialEdmethodSubjectMaster from "./gspecialEdmethodSubjectMaster";
import MaritalStatusMaster from "./maritalStatusMaster";
import NclAuthorityMaster from "./nclAuthorityMaster";
import GspecializationSubjectMaster from "./gspecializationSubjectMaster";





export default function Index({ page }) {

    return (
        <>
            {page === 'gender' ?
                <GenderMaster /> :
                page === 'mothertongue' ?
                    <MothertongueMaster /> :
                    page === 'religion' ?
                        <ReligionMaster /> :
                        page === 'region' ?
                            <RegionMaster /> :
                            page === 'annualfamilyincome' ?
                                <FamilyincomeMaster /> :
                                page === 'nationality' ?
                                    <NationalityMaster /> :
                                    page === 'language' ?
                                        <LanguageMaster /> :
                                        page === 'minority' ?
                                            <MinorityMaster /> :
                                            page === 'state' ?
                                                <StateMaster /> :
                                                page === 'district' ?
                                                    <DistrictMaster /> :
                                                    page === 'taluka' ?
                                                        <TalukaMaster /> :
                                                        page === 'village' ?
                                                            <VillageMaster /> :
                                                            page === 'category' ?
                                                                <CategoryMaster /> :
                                                                page === 'caste' ?
                                                                    <CasteMaster /> :
                                                                    page === 'pwdtype' ?
                                                                        <PwdtypeMaster /> :
                                                                        page === 'equivalentboard' ?
                                                                            <EquivalentboardMaster /> :
                                                                            page === 'board' ?
                                                                                <BoardMaster /> :
                                                                                page === 'grievancetype' ?
                                                                                    <GrievancetypeMaster /> :
                                                                                    page === 'grievancecategory' ?
                                                                                        <GrievanceCategoryMaster /> :
                                                                                        page === 'documenttype' ?
                                                                                            <DocumentTypeMaster /> :
                                                                                            page === 'coursecategory' ?
                                                                                                <CourseCategoryMaster /> :
                                                                                                page === 'coursetab' ?
                                                                                                    <CourseTabMaster /> :
                                                                                                    page === "coursefields" ?
                                                                                                        <CoursefieldMaster /> :
                                                                                                        page === "buildingtype" ?
                                                                                                            <BuildingTypeMaster /> : page === "venuetype" ?
                                                                                                                <VenueTypeMaster /> :
                                                                                                                page === "scorecard" ?
                                                                                                                    <ScoreCard /> :
                                                                                                                    page === "admitcard" ?
                                                                                                                        <Admitcard /> :
                                                                                                                        page === "awardedclass" ?
                                                                                                                        <AwardClassMaster /> :
                                                                                                                        page === "cetexam" ?
                                                                                                                        <CetExamMaster /> :
                                                                                                                        page === "cvcauthority" ?
                                                                                                                        <CvcAuthorityMaster /> :
                                                                                                                        page === "drivemaster" ?
                                                                                                                        <DriveMaster /> :
                                                                                                                        page === "educationclass" ?
                                                                                                                        <EducationClassMaster /> :
                                                                                                                        page === "educationstream" ?
                                                                                                                        <EducationStreamMaster /> :
                                                                                                                        page === "eligibilityqualification" ?
                                                                                                                        <EligibilityQualificationMaster /> :
                                                                                                                        page === "examlanguage" ?
                                                                                                                        <ExamLanguageMaster /> :
                                                                                                                        page === "gspecialedmethodsubjects" ?
                                                                                                                        <GspecialEdmethodSubjectMaster /> :
                                                                                                                        page === "gspecializationsubject" ?
                                                                                                                        <GspecializationSubjectMaster /> :
                                                                                                                        page === "graduationbranch" ?
                                                                                                                        <GraduationBranchMaster /> :
                                                                                                                        page === "maritalstatus" ?
                                                                                                                        <MaritalStatusMaster /> :
                                                                                                                        page === "nclauthority" ?
                                                                                                                        <NclAuthorityMaster /> :
                                                                                                                        page === "sportsbody" ?
                                                                                                                        <SportsBodyMaster /> :
                                                                                                                        page === "sports" ?
                                                                                                                        <SportsMaster /> :
                                                                                                                        page === "sportsparticipation" ?
                                                                                                                        <SportsParticipationMaster /> :
                                                                                                                        page === "sportsrank" ?
                                                                                                                        <SportsRankMaster /> :
                                                                                                                        <></>
            }
        </>
    )
}