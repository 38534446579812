import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import BluePrimaryButton from "../../common/button/bluePrimaryButton";
import GreenButton from "../../common/button/greenButton";
import { cssProperties } from "../../utils/commonCssProperties";
import CommonTable from "./../../common/CommonTable";
import CloseButton from "../../common/button/closeButton";
import DangerButton from "../../common/button/DangerButton";
import BrowseUploadButton from "../../common/button/browseUploadButton";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import { itemsPerPage } from "../../utils/constants";
import TablePagination from "../../common/tablePagination";

import { NumbersValidation } from "../../utils/validation";
import { IoCloudUploadOutline } from "react-icons/io5";
import { PiXBold } from "react-icons/pi";
import xlsxpng from "../../assets/images/uploads/xlsx.png";
import MauvelousPrimaryButton from "./../../common/button/mauvelousPrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import masterActions from "../../redux/master/actions";
import venueActions from "../../redux/venue/actions";
import { useLocation, useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import xlsx from "json-as-xlsx";
import commonActions from "../../redux/common/actions";
import { PiPencilSimpleBold, PiTrashBold } from "react-icons/pi";
import Cookies from "js-cookie";
import { useForm, useWatch } from "react-hook-form";

// let data = [
//   {
//     name: "Candidates",
//     value: "1256",
//   },
//   {
//     name: "Venues",
//     value: "324",
//   },
//   {
//     name: "Venue Capacity for batch",
//     value: "324",
//   },
// ];
// let data2 = [
//   {
//     name: "Non Allocated Candidates",
//     value: "1256",
//   },
//   {
//     name: "Diability Candidates",
//     value: "324",
//   },
// ];
// let tableData = [
//   {
//     course_name: "course_1",
//     candidate_count: 10245,
//     exam_date: "23/05/2024",
//     batch_no: 1,
//     first_preference: "-----",
//     second_preference: "-----",
//     third_preference: "-----",
//   },
//   {
//     course_name: "course_2",
//     candidate_count: 10245,
//     exam_date: "23/05/2024",
//     batch_no: 1,
//     first_preference: "-----",
//     second_preference: "-----",
//     third_preference: "-----",
//   },
// ];

let data = [
  {
    sheet: "Venue Master Template",
    columns: [
      { label: "venuetype" },
      { label: "buildingtype" },
      { label: "village" },
      { label: "city" },
      { label: "taluka" },
      { label: "district" },
      { label: "state" },
      { label: "venueid" },
      { label: "seatsavailability" },
      { label: "venuename" },
      { label: "pincode" },
      { label: "landmark" },
      { label: "addressline1" },
      { label: "addressline2" },
      { label: "suitableforpwd" },
      { label: "googlemaplink" },
    ],
    content: [],
  },
];

let settings = {
  fileName: "venue allocation template",
  extraLength: 3,
  writeMode: "writeFile",
  writeOptions: {},
  RTL: false,
};

let callback = function (sheet) {
  // console.log("Download complete:", sheet);
};


const Index = () => {
  const socketRef = useRef(null);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    setValue,
    control,
    trigger,
    watch,
    clearErrors,
    setError,
    formState: { errors },
    setFocus,
  } = useForm({
    mode: "onChange",
  });

  // const socket = io(`http://localhost:3001`);

  const [file, setFile] = useState(null);
  const [courseid, setCourseId] = useState(0);
  const [open, setOpen] = useState(false);
  const [intervalId, setIntervalId] = useState(null);

  const [editmodal, setEditmodal] = useState({
    open: false,
    data: null,
  });
  const [statusModalopen, setStatusModalOpen] = useState({
    open: false,
    statustext: "",
  });
  const [loading, setLoading] = useState(false);
  const [preferenceid, setPreferenceId] = useState(0);
  const id = useLocation()?.state?.id;
  // console.log(id, 'cid')

  const dispatch = useDispatch();
  
  const { courseList } = useSelector((state) => state.masterReducer);
  const {
    // candidateCount,
    // venueDetails,
    // seatAllocationData,
    // candidateAllocationDetails,
    otbsVenueDetails,
  } = useSelector((state) => state.venueReducer);

  const { backofficeTokenDetails } = useSelector((state) => state.authReducer);
  // console.log(courseList, 'clist')
  // console.log(candidateCount, 'cndcount')
  // console.log(candidateAllocationDetails, "cad");
  //   const [tableData, setTableData] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = otbsVenueDetails?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(otbsVenueDetails?.length / itemsPerPage);

  const handlePageChange = (event, value) => {
    const newOffset = (value - 1) * itemsPerPage;
    setItemOffset(newOffset);
    setCurrentPage(value);
  };
  let columnData = [
    { name: "State", dataIndex: "state" },

    { name: "District", dataIndex: "district" },
    { name: "Venue Code", dataIndex: "referenceid" },

    { name: "Exam Center Name", dataIndex: "venuename" },
    { name: "Exam Center Address", dataIndex: "address1" },
    { name: "Capacity", dataIndex: "totalseats" },
    { name: "Utilized Count", dataIndex: "utilizedcount" },
    {
      name: "Available",
      dataIndex: "available",
      render: (val, row) =>
        parseInt(row?.totalseats) - parseInt(row?.utilizedcount),
    },
    {
      name: "Action",
      dataIndex: "action",
      render: (val, row) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            borderColor: `${cssProperties?.bordercolor?.primary}`,
            borderWidth: "1px",
            // borderRadius: 2,

            // backgroundColor: 'rgba(243, 243, 243, 1)',
            borderRadius: "6px 0",
            padding: "4px 7px",
            "& svg": {
              m: 1,
            },
          }}
        >
          {/* {row?.venueid} */}
          <PiPencilSimpleBold
            size={24}
            style={{
              marginRight: "7px",
              color: `${cssProperties?.bordercolor?.primary}`,
              cursor: "pointer",
            }}
            onClick={() => {
              // navigate(`/${Cookies.get("mhet_mst_project")}/allocate-venue`, {
              //   state: { id: row?.courseid },
              // });

              setEditmodal({
                open: true,
                data: row,
              });
            }}
          />
        </Box>
      ),
    },
  ];
  //   let getDataInterval = (courseid, currentVenueId) => {
  //     let intervalId = setInterval(() => {
  //       socketRef?.current?.emit("listenstatus", { courseid, currentVenueId });
  //     }, 10000);
  //     return intervalId;
  //   };

  //   useEffect(() => {
  //     socketRef.current = io(`${API_URL}`);
  //     socketRef?.current?.on("listenmessage", (message) => {
  //       console.log(message.data, "mess", courseid);
  //       // let data = message?.data
  //       if (message?.statusCode === 200) {
  //         let statustext = message.data.trim();
  //         // console.log(statustext, 'stt',statustext?.toLowerCase())
  //         if (statustext) {
  //           if (statustext?.toLowerCase() !== "completed") {
  //             //   console.log("not completed");
  //             setStatusModalOpen({
  //               open: true,
  //               statustext:
  //                 statustext?.trim() !== "" && statustext !== null
  //                   ? statustext
  //                   : statusModalopen.statustext,
  //             });
  //           } else {
  //             //   console.log("completed");
  //             setStatusModalOpen({
  //               open: false,
  //               statustext:
  //                 statustext?.toLowerCase() !== "completed"
  //                   ? ""
  //                   : statusModalopen.statustext,
  //             });
  //             statustext?.toLowerCase() !== "completed" &&
  //               dispatch({
  //                 type: venueActions.GET_SEAT_ALLOCATION,
  //                 payload: { courseid },
  //               });
  //             dispatch({
  //               type: venueActions.GET_CANDIDATE_ALLOCATION_DEAILS,
  //               payload: { courseid },
  //             });
  //             clearInterval(intervalId);
  //             dispatch({
  //               type: commonActions.SET_ALERT,
  //               payload: {
  //                 status: "success",
  //                 show: true,
  //                 message: "seat allocated successfully",
  //               },
  //             });

  //             setTimeout(function () {
  //               dispatch({
  //                 type: commonActions.SET_ALERT,
  //                 payload: {
  //                   status: "success",
  //                   show: false,
  //                   message: null,
  //                 },
  //               });
  //             }, 2000);
  //           }
  //         }
  //         //  else {
  //         //   setStatusModalOpen({
  //         //     open: false,
  //         //     statustext: "",
  //         //   });
  //         // }
  //       }
  //     });
  //     return () => (socketRef.current = null);
  //   }, [courseid, dispatch, intervalId, socketRef]);
  useEffect(()=>{
    dispatch({
      type: venueActions.OTBS_GET_VENUE,
      payload: { courseid:0 },
    });
  },[])
  useEffect(() => {
    dispatch({ type: masterActions.GET_COURSE_LIST });
    dispatch({ type: venueActions.GET_SEAT_ALLOCATION_DETAILS });
  }, [dispatch]);
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy"; // Show the copy cursor when dragging
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleDocumentUpload(e);
  };
  const handleDocumentUpload = (e) => {
    const file = e.target.files[0] || e.dataTransfer.files[0];
    // console.log(file, 'file')
    setFile(file);
  };
  const handleUploadVenue = async () => {
    console.log("entered", file);

    dispatch({
      type: venueActions.UPLOAD_OTBS_VENUE,
      payload: {
        courseid,
        file,
        setFile,
        setOpen,
        setLoading,
        userid: backofficeTokenDetails?.id,
        setStatusModalOpen: setStatusModalOpen,
      },
    });
  };
  const ToggleOpen = () => {
    setOpen(!open);
  };
  const onChangeCourse = async (e) => {
    setCourseId(e.target.value);
    dispatch({
      type: venueActions.OTBS_GET_VENUE,
      payload: { courseid: e.target.value },
    });
  };


  //   const onRunPreference = async () => {
  //     if (courseid) {
  //       setStatusModalOpen({
  //         open: true,
  //         statustext: "Processing...",
  //       });
  //     }
  //   };
  const handlePreferenceChange = (e) => {
    setPreferenceId(e.target.value);
  };

  const onError = (errors) => {
    const fError = Object.keys(errors)[0];
    if (fError) {
      setFocus(fError);
      document.getElementsByName(fError)[0]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const handleUpdate = async (data) => {
    console.log(data, "values");
    if (parseInt(data?.venuecount) < parseInt(editmodal?.data?.totalseats)) {
      setError("venuecount", {
        type: "manual",
        message: "Venue Count must be greater than existing value",
      });
    } else {
      clearErrors("venuecount");
      dispatch({
        type: venueActions.UPDATE_OTBS_VENUE,
        payload: {
          courseid: courseid,
          totalseats: data?.venuecount,
          venueid: data?.venueid,
          setEditmodal: setEditmodal,
        },
      });
    }
  };

  const onReset = (courseid, preferenceid = 0) => {};
  return (
    <>
      <Card
        sx={{
          width: "100%",
          textAlign: "start",
          borderRadius: "8px",
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "none",
          padding: 2,
          background: "none",
        }}
      >
        {/* <Box sx={{ width: "100%" }}>
          <Typography
            variant="p"
            color="#212121"
            fontWeight={600}
            sx={{ paddingBottom: 2 }}
            component="p"
          >
            Get Allocated Data
          </Typography>
        </Box> */}

        <Grid container spacing={2}>
          <Grid item md={5}>
            <Box>
              <Select
                size="small"
                onChange={onChangeCourse}
                value={courseid}
                defaultValue={0}
                sx={{ width: "100%", maxWidth: "200px" }}
              >
                <MenuItem value={0} selected>
                  Courses
                </MenuItem>
                {courseList?.all?.filter(obj=>obj?.isactive == 1 && obj?.isotbs == true)?.map((course, i) => (
                  <MenuItem key={i} value={course.courseid}>
                    {course.coursename}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          <Grid item md={12}>
            <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
              <BluePrimaryButton
                handleSubmit={ToggleOpen}
                title="Browse and Upload Venue data"
              />
              <GreenButton
                handleSubmit={() => xlsx(data, settings, callback)}
                title="Download Template"
              />
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ width: "100%", textAlign: "center" }} paddingTop={2}>
          <CommonTable tableData={currentItems} columnData={columnData} />
          <TablePagination
            pageCount={pageCount}
            handlePageChange={handlePageChange}
            currentPage={currentPage}
          />
        </Box>

        {/* <Grid container paddingTop={2} justifyContent="end">
                    <Grid item>
                        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', justifyContent: 'end' }}>
                            <DangerButton title="Get Candidate data(API)" />
                            <DangerButton title="Browse and Upload Candidate Data" />
                            <DangerButton title="Download candidate data " />

                        </Box>
                    </Grid>
                </Grid> */}
      </Card>
      <Modal
        open={open}
        sx={{
          display: "flex",
          p: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 5,
            // border: '2px solid #000',
            boxShadow: (theme) => theme.shadows[5],
            p: 4,
          }}
        >
          {file ? (
            <Box
              pl={2}
              style={{
                width: "fit-content",
                // maxWidth: "18em",
                height: "45px",
                border: "1px solid #D5D5D5",
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                marginTop: "1rem",
                paddingLeft: "0",
                // overflow: "hidden",
                // whiteSpace: "nowrap",
                // position: 'relative'
              }}
            >
              <img
                src={xlsxpng}
                style={{ height: "auto", width: "30px", marginLeft: "5px" }}
                alt="xlsx"
              />
              <Box sx={{ paddingLeft: "10px" }}>{file?.name}</Box>
              <Box
                onClick={() => setFile(null)}
                sx={{ paddingRight: "10px", cursor: "pointer" }}
                //  sx={{position: 'absolute', right: 0, width: '1.5rem', paddingLeft: '5px', backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`}}
              >
                <PiXBold />
              </Box>
            </Box>
          ) : (
            <Box
              style={{
                border: "1px dashed gray",
                maxWidth: "330px",
                minHeight: "120px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexShrink: 340,
                position: "relative",
              }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <input
                type="file"
                name="document"
                id="document"
                accept=".xlsx"
                alt=""
                style={{
                  width: "0.1px",
                  height: "0.1px",
                  opacity: 0,
                  overflow: "hidden",
                  position: "absolute",
                  zIndex: -1,
                }}
                onChange={handleDocumentUpload}
              />

              <label
                htmlFor="document"
                style={{
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IoCloudUploadOutline style={{ marginLeft: "8px" }} size={22} />{" "}
                <span>
                  {" "}
                  &nbsp; Drag or drop your file, or{" "}
                  <span style={{ color: "blue" }}>&nbsp;browse</span>
                </span>
                <small style={{ color: "rgb(102, 102, 102)" }}>
                  (xlsx) (5mb max.)
                </small>
              </label>
            </Box>
          )}
          <Box
            mt={2}
            justifyContent="end"
            alignItems="center"
            display="flex"
            gap={1}
          >
            <BluePrimaryButton
              disabled={loading}
              handleSubmit={ToggleOpen}
              title="Close"
            />
            <MauvelousPrimaryButton
              disabled={loading || !file || courseid === 0}
              handleClick={handleUploadVenue}
              title="Submit"
            />
          </Box>
        </Box>
      </Modal>
      <Modal
        open={statusModalopen?.open}
        sx={{
          display: "flex",
          p: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          {" "}
          <Box
            sx={{
              position: "relative",
              width: 400,
              bgcolor: "background.paper",
              borderRadius: 5,
              // border: '2px solid #000',
              boxShadow: (theme) => theme.shadows[5],
              p: 4,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box my={2}>
              <div
                dangerouslySetInnerHTML={{
                  __html: statusModalopen?.statustext,
                }}
              />
            </Box>

            <BluePrimaryButton
              //   disabled={loading}
              handleSubmit={() =>
                setStatusModalOpen({ open: false, statustext: "" })
              }
              title="Close"
            />
          </Box>
        </Box>
      </Modal>

      <Modal
        open={editmodal?.open}
        sx={{
          display: "flex",
          p: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 5,
            // border: '2px solid #000',
            boxShadow: (theme) => theme.shadows[5],
            p: 4,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="h4">
            Add Venue Count for{" "}
            {
              courseList?.all?.find((obj) => obj.courseid == courseid)
                ?.coursename
            }
          </Typography>

          <TextField
            fullWidth
            variant="outlined"
            type="number"
            label={
              <span>
                ADD COUNT
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </span>
            }
            placeholder="Enter the Venue Count"
            {...register("venuecount", {
              required: "           Venue Count is required",
              //  validate:NumbersValidation
            })}
            onChange={(e) => {
              const value = parseFloat(e.target.value);

              setValue("venuecount", e.target.value);
              if (parseInt(value) < parseInt(editmodal?.data?.totalseats)) {
                setError("venuecount", {
                  type: "manual",
                  message: "Venue Count must be greater than existing value",
                });
              } else {
                clearErrors("venuecount");
              }
            }}
            value={watch("venuecount") ?? editmodal?.data?.totalseats}
            onInput={NumbersValidation}
            InputProps={{ inputMode: "decimal", min: 200 }}
            error={!!errors.venuecount}
            helperText={errors.venuecount?.message}
            FormHelperTextProps={{ style: { margin: 0 } }}
            InputLabelProps={{ shrink: true }}
          />
          <input
            type="hidden"
            value={editmodal?.data?.venueid}
            {...register("venueid")}
          />
          {/* {JSON.stringify(editmodal?.data)} */}
          <Box sx={{ mt: 1, display: "flex", justifyContent: "center" }}>
            <MauvelousPrimaryButton
              handleClick={() => {
                setEditmodal({
                  open: false,
                  data: null,
                });
                reset({});
              }}
              title="Cancel"
            />
            <BluePrimaryButton
              title={"Update"}
              handleSubmit={handleSubmit(handleUpdate, onError)}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Index;
