import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import commonActions from "../../../redux/common/actions";
import {
  AlphabetsValidation,
  FamilyIncomeNumberValidation,
  NumbersValidation,
  pincodeValidation,
} from "../../../utils/validation";
import { cssProperties } from "../../../utils/commonCssProperties";
import CancelButton from "../../../common/button/cancelButton";
import BluePrimaryButton from "../../../common/button/bluePrimaryButton";
import ButtonLoader from "../../../common/buttonLoader";
import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: `${cssProperties?.backgroundcolor?.secondary}`,
  padding: "24px",
  borderRadius: "8px",
};

export default function EditMasterModal({
  register,
  handleSubmit,
  errors,
  handleUpdate,
  resetForm,
  watch,
  control,
  coursetabList,
  coursefieldList,
  setValue,
  getValues,
  trigger,
}) {
  const dispatch = useDispatch();

  const {
    stateList,
    districtList,
    talukaList,
    categoryList,
    equivalentboardList,
    grievanceCategoryList,
    graduationBranchList,
  } = useSelector((state) => state.masterFormsReducer);

  const { showModal, buttonLoader } = useSelector(
    (state) => state.commonReducer
  );
  useEffect(() => {
    trigger();
    // console.log(errors, 'errors intrigger')
  }, [showModal, trigger, errors]);
  const handleClose = () => {
    dispatch({
      type: commonActions.SET_SHOW_MODAL,
      payload: { show: false, type: null, method: null, data: null },
    });
    resetForm();
  };
  const focusedTextFieldStyles = {
    // "& .MuiInputLabel-root.Mui-focused": {
    //   color: "#3F41D1",
    // },
    height: 56,
    "& .MuiOutlinedInput-root": {
      // "& fieldset": {
      //   borderColor: "#3F41D1",
      // },
      "&:hover fieldset": {
        borderColor: "#3F41D1",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3F41D1",
        color: "#3F41D1",
      },
      "&.Mui-focused .MuiInputBase-input": {
        color: "#3F41D1", // Text color when focused
      },
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#3F41D1", // Label color when focused
    },
    // "& .MuiInputBase-input": {
    //   color: "#3F41D1",
    // },
  };
  const customSelectStyles = {
    // '& .MuiSelect-root': {
    //   color: '#3F41D1', // Text color
    //   borderColor: '#3F41D1', // Outline color when focused
    // },
    // height: 56,
    "& .MuiOutlinedInput-root": {
      // '& fieldset': {
      //   borderColor: '#3F41D1', // Outline color
      // },
      "&:hover fieldset": {
        borderColor: "#3F41D1", // Outline color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3F41D1", // Outline color when focused
      },
    },
    // '& .MuiInputLabel-root': {
    //   color: '#3F41D1', // Label color
    // },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#3F41D1", // Label color when focused
    },
    // '& .MuiFormHelperText-root': {
    //   color: 'red', // Helper text color (you can change this if needed)
    // },
  };
  return (
    <Modal
      open={showModal?.show && showModal?.method === "U"}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          variant="h5"
          sx={{
            color: `${cssProperties?.color?.primary}`,
            fontWeight: "500",
            marginBottom: "18px",
          }}
        >
          Update {showModal?.type}
        </Typography>
        <Grid container spacing={2}>
          {(showModal?.type === "district" || showModal?.type === "board") && (
            <Grid item md={12}>
              <FormControl fullWidth error={!!errors.stateid}>
                <InputLabel id="state-label" shrink>
                  Select state{" "}
                  <span className="error" style={{ color: "red" }}>
                    *
                  </span>
                </InputLabel>
                <Controller
                  name="stateid"
                  control={control}
                  defaultValue=""
                  rules={{
                    required:
                      (showModal?.type === "district" ||
                        showModal?.type === "board") &&
                      "State is required",
                  }}
                  render={({ field }) => (
                    <Select
                      sx={customSelectStyles}
                      {...field}
                      labelId="state-label"
                      label="Select state *"
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        --SELECT--
                      </MenuItem>
                      {stateList?.filtered?.map((val, i) => (
                        <MenuItem key={i} value={val?.stateid}>
                          {val?.state}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.stateid && (
                  <FormHelperText>{errors.stateid.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
          {showModal?.type === "taluka" && (
            <Grid item md={12}>
              <FormControl fullWidth error={!!errors.districtid}>
                <InputLabel id="district-label" shrink>
                  Select district{" "}
                  <span className="error" style={{ color: "red" }}>
                    *
                  </span>
                </InputLabel>
                <Controller
                  name="districtid"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "District is required",
                  }}
                  render={({ field }) => (
                    <Select
                      sx={customSelectStyles}
                      {...field}
                      labelId="district-label"
                      label="Select district *"
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        --SELECT--
                      </MenuItem>
                      {districtList?.filtered?.map((val, i) => (
                        <MenuItem key={i} value={val?.districtid}>
                          {val?.district}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.districtid && (
                  <FormHelperText>{errors.districtid.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
          {showModal?.type === "village" && (
            <Grid item md={12}>
              <FormControl fullWidth error={!!errors.talukaid}>
                <InputLabel id="taluka-label" shrink>
                  Select taluka{" "}
                  <span className="error" style={{ color: "red" }}>
                    *
                  </span>
                </InputLabel>
                <Controller
                  name="talukaid"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Taluka is required",
                  }}
                  render={({ field }) => (
                    <Select
                      sx={customSelectStyles}
                      {...field}
                      labelId="taluka-label"
                      label="Select taluka *"
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        --SELECT--
                      </MenuItem>
                      {talukaList?.filtered?.map((val, i) => (
                        <MenuItem key={i} value={val?.talukaid}>
                          {val?.taluka}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.talukaid && (
                  <FormHelperText>{errors.talukaid.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
          {showModal?.type === "caste" && (
            <Grid item md={12}>
              <FormControl fullWidth error={!!errors.categoryid}>
                <InputLabel id="category-label" shrink>
                  Select category{" "}
                  <span className="error" style={{ color: "red" }}>
                    *
                  </span>
                </InputLabel>
                <Controller
                  name="categoryid"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Category is required",
                  }}
                  render={({ field }) => (
                    <Select
                      sx={customSelectStyles}
                      {...field}
                      labelId="category-label"
                      label="Select category *"
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        --SELECT--
                      </MenuItem>
                      {categoryList?.filtered?.map((val, i) => (
                        <MenuItem key={i} value={val?.categoryid}>
                          {val?.category}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.categoryid && (
                  <FormHelperText>{errors.categoryid.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
          {showModal?.type === "board" && (
            <Grid item md={12}>
              <FormControl fullWidth error={!!errors.equivalentboardid}>
                <InputLabel id="equivalent-label" shrink>
                  Select equivalent board{" "}
                  <span className="error" style={{ color: "red" }}>
                    *
                  </span>
                </InputLabel>
                <Controller
                  name="equivalentboardid"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Equivalentboard is required",
                  }}
                  render={({ field }) => (
                    <Select
                      sx={customSelectStyles}
                      {...field}
                      labelId="equivalent-label"
                      label="Select equivalent board *"
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        --SELECT--
                      </MenuItem>
                      {equivalentboardList?.filtered?.map((val, i) => (
                        <MenuItem key={i} value={val?.equivalentboardid}>
                          {val?.board}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.equivalentboardid && (
                  <FormHelperText>
                    {errors.equivalentboardid.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
          {showModal?.type === "minority" && (
            <Grid item md={12}>
              <FormControl fullWidth error={!!errors.type}>
                <InputLabel id="type-label" shrink>
                  Select minority type{" "}
                  <span className="error" style={{ color: "red" }}>
                    *
                  </span>
                </InputLabel>
                <Controller
                  name="type"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: `${
                      showModal?.type === "minority" &&
                      "minority type is required"
                    }`,
                  }}
                  render={({ field }) => (
                    <Select
                      sx={customSelectStyles}
                      {...field}
                      labelId="type-label"
                      label="Select minority type *"
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        --SELECT--
                      </MenuItem>
                      <MenuItem value={"Linguistic Minority"}>
                        Linguistic Minority
                      </MenuItem>
                      <MenuItem value={"Religious Minority"}>
                        Religious Minority
                      </MenuItem>
                    </Select>
                  )}
                />
                {errors.type && (
                  <FormHelperText>{errors.type.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
          {showModal?.type === "grievancetype" && (
            <Grid item md={12}>
              <FormControl fullWidth error={!!errors.grievancecategory}>
                <InputLabel id="grievancecategory-label" shrink>
                  Select grievance category{" "}
                  <span className="error" style={{ color: "red" }}>
                    *
                  </span>
                </InputLabel>
                <Controller
                  name="grievancecategory"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: `${
                      showModal?.type === "grievancetype" &&
                      "Grievance category is required"
                    }`,
                  }}
                  render={({ field }) => (
                    <Select
                      sx={customSelectStyles}
                      {...field}
                      labelId="grievancecategory-label"
                      label="Select grievance category *"
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        --SELECT--
                      </MenuItem>
                      {grievanceCategoryList?.filtered?.map((val, i) => (
                        <MenuItem value={val?.categoryid} key={i}>
                          {val?.category}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.grievancecategory && (
                  <FormHelperText>
                    {errors.grievancecategory.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
          {showModal?.type === "grievancetype" && (
            <Grid item md={12}>
              <TextField
                sx={focusedTextFieldStyles}
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    Enter grievance code
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  </span>
                }
                placeholder="Enter grievance code"
                {...register("grievancecode", {
                  required: `${
                    (showModal?.type === "grievancetype" ||
                      showModal?.type === "grievancecategory") &&
                    "grievance code is required"
                  }`,
                })}
                onInput={AlphabetsValidation}
                error={!!errors.grievancecode}
                helperText={errors.grievancecode?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
              />
            </Grid>
          )}

          {showModal?.type === "gspecializationsubject" && (
            <Grid item md={12}>
              <FormControl fullWidth error={!!errors.graduationbranchid}>
                <InputLabel id="gbranch-label" shrink>
                  Select Graduation Branch{" "}
                  <span className="error" style={{ color: "red" }}>
                    *
                  </span>
                </InputLabel>
                <Controller
                  name="graduationbranchid"
                  control={control}
                  defaultValue={[]}
                  rules={{
                    required: "At least one Graduation Branch is required",
                  }}
                  render={({ field: { onChange, value, ...field } }) => (
                    <Select
                      sx={customSelectStyles}
                      {...field}
                      value={value.map(
                        (id) =>
                          graduationBranchList?.filtered?.find(
                            (branch) => branch.graduationbranchid === id
                          )?.graduationbranchid || id
                      )}
                      onChange={(event) => {
                        const selectedIds = event.target.value;
                        const selectedBranches = selectedIds.map((id) =>
                          graduationBranchList?.filtered?.find(
                            (branch) => branch.graduationbranchid === id
                          )
                        );
                        onChange(selectedIds);
                      }}
                      labelId="gbranch-label"
                      label="Select Graduation Branch *"
                      multiple
                      displayEmpty
                      renderValue={(selected) =>
                        selected.length === 0 ? (
                          "--SELECT--"
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                            }}
                          >
                            {selected.map((id) => {
                              const branchName =
                                graduationBranchList?.filtered?.find(
                                  (branch) => branch.graduationbranchid === id
                                )?.graduationbranch || id;
                              return <Chip key={id} label={branchName} />;
                            })}
                          </Box>
                        )
                      }
                    >
                      <MenuItem value="" disabled>
                        --SELECT--
                      </MenuItem>
                      {graduationBranchList?.filtered?.map((val, i) => (
                        <MenuItem key={i} value={val?.graduationbranchid}>
                          {val?.graduationbranch}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.graduationbranchid && (
                  <FormHelperText>
                    {errors.graduationbranchid.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

          <Grid item md={12}>
            {showModal?.type === "maritalstatus" && (
              <TextField
                sx={{ ...focusedTextFieldStyles }}
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    Enter{" "}
                    {showModal?.type === "maritalstatus"
                      ? "maritalstatuscode"
                      : showModal?.type}
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  </span>
                }
                placeholder="Enter Maritalstatuscode"
                {...register("maritalstatuscode", {
                  required: `${
                    (showModal?.type === "maritalstatus" ||
                      showModal?.type === "maritalstatuscode") &&
                    "Maritalstatuscode  required"
                  }`,
                })}
                onInput={AlphabetsValidation}
                error={!!errors.maritalstatuscode}
                helperText={errors.maritalstatuscode?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
              />
            )}
          </Grid>

          {(showModal?.type === "gender" ||
            showModal?.type === "mothertongue" ||
            showModal?.type === "religion" ||
            showModal?.type === "region" ||
            showModal?.type === "annualfamilyincome" ||
            showModal?.type === "nationality" ||
            showModal?.type === "language" ||
            showModal?.type === "state" ||
            showModal?.type === "district" ||
            showModal?.type === "taluka" ||
            showModal?.type === "village" ||
            showModal?.type === "category" ||
            showModal?.type === "buildingtype" ||
            showModal?.type === "venuetype" ||
            showModal?.type === "caste" ||
            showModal?.type === "pwdtype" ||
            showModal?.type === "equivalentboard" ||
            showModal?.type === "board" ||
            showModal?.type === "orderno" ||
            showModal?.type === "minority" ||
            showModal?.type === "grievancetype" ||
            showModal?.type === "grievancecategory" ||
            showModal?.type === "documenttype" ||
            showModal?.type === "awardedclass" ||
            showModal?.type === "cetexam" ||
            showModal?.type === "cvcauthority" ||
            showModal?.type === "drivemaster" ||
            showModal?.type === "educationclass" ||
            showModal?.type === "educationstream" ||
            showModal?.type === "eligibilityqualification" ||
            showModal?.type === "examlanguage" ||
            showModal?.type === "gspecialedmethodsubjects" ||
            showModal?.type === "gspecializationsubject" ||
            showModal?.type === "maritalstatus" ||
            showModal?.type === "nclauthority" ||
            showModal?.type === "graduationbranch" ||
            showModal?.type === "sportsbody" ||
            showModal?.type === "sports" ||
            showModal?.type === "sportsparticipation" ||
            showModal?.type === "sportsrank" ||
            showModal?.type === "coursetab") && (
            <Grid item md={12}>
              <TextField
                sx={focusedTextFieldStyles}
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    Enter{" "}
                    {showModal?.type === "grievancetype"
                      ? "grievance"
                      : showModal?.type}
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  </span>
                }
                placeholder={`Enter ${
                  showModal?.type === "grievancetype" ||
                  showModal?.type === "grievancecategory"
                    ? "grievance"
                    : showModal?.type
                }`}
                {...register("name", {
                  required: `${
                    (showModal?.type === "gender" ||
                      showModal?.type === "mothertongue" ||
                      showModal?.type === "religion" ||
                      showModal?.type === "region" ||
                      showModal?.type === "annualfamilyincome" ||
                      showModal?.type === "nationality" ||
                      showModal?.type === "language" ||
                      showModal?.type === "state" ||
                      showModal?.type === "district" ||
                      showModal?.type === "taluka" ||
                      showModal?.type === "village" ||
                      showModal?.type === "category" ||
                      showModal?.type === "buildingtype" ||
                      showModal?.type === "venuetype" ||
                      showModal?.type === "caste" ||
                      showModal?.type === "pwdtype" ||
                      showModal?.type === "equivalentboard" ||
                      showModal?.type === "board" ||
                      showModal?.type === "orderno" ||
                      showModal?.type === "minority" ||
                      showModal?.type === "coursetab" ||
                      showModal?.type === "grievancetype" ||
                      showModal?.type === "grievancecategory" ||
                      showModal?.type === "awardedclass" ||
                      showModal?.type === "cetexam" ||
                      showModal?.type === "cvcauthority" ||
                      showModal?.type === "drivemaster" ||
                      showModal?.type === "educationclass" ||
                      showModal?.type === "educationstream" ||
                      showModal?.type === "eligibilityqualification" ||
                      showModal?.type === "examlanguage" ||
                      showModal?.type === "gspecialedmethodsubjects" ||
                      showModal?.type === "gspecializationsubject" ||
                      showModal?.type === "maritalstatus" ||
                      showModal?.type === "nclauthority" ||
                      showModal?.type === "graduationbranch" ||
                      showModal?.type === "sportsbody" ||
                      showModal?.type === "sports" ||
                      showModal?.type === "sportsparticipation" ||
                      showModal?.type === "sportsrank" ||
                      showModal?.type === "documenttype") &&
                    `${
                      showModal?.type === "grievancetype"
                        ? "grievance"
                        : showModal?.type
                    } is required`
                  }`,
                })}
                onInput={
                  showModal?.type === "annualfamilyincome"
                    ? FamilyIncomeNumberValidation
                    : AlphabetsValidation
                }
                error={!!errors.name}
                helperText={errors.name?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
              />
            </Grid>
          )}

          <Grid item md={12}>
            {showModal?.type === "eligibilityqualification" && (
              <TextField
                sx={{ ...focusedTextFieldStyles }}
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    Enter{" "}
                    {showModal?.type === "eligibilityqualification"
                      ? "eligibilitycode"
                      : showModal?.type}
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  </span>
                }
                placeholder="Enter Eligibilitycode"
                {...register("eligibilitycode", {
                  required: `${
                    (showModal?.type === "eligibilityqualification" ||
                      showModal?.type === "eligibilitycode") &&
                    "Eligibilitycode  required"
                  }`,
                })}
                onInput={AlphabetsValidation}
                error={!!errors.eligibilitycode}
                helperText={errors.eligibilitycode?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
              />
            )}
          </Grid>

          <Grid item md={12}>
            {showModal?.type === "examlanguage" && (
              <TextField
                sx={{ ...focusedTextFieldStyles }}
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    Enter{" "}
                    {showModal?.type === "examlanguage"
                      ? "languagecode"
                      : showModal?.type}
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  </span>
                }
                placeholder="Enter Eligibilitycode"
                {...register("languagecode", {
                  required: `${
                    (showModal?.type === "examlanguage" ||
                      showModal?.type === "eligibilitycode") &&
                    "Languagecode  required"
                  }`,
                })}
                onInput={AlphabetsValidation}
                error={!!errors.languagecode}
                helperText={errors.languagecode?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
              />
            )}
          </Grid>

          <Grid item md={12} marginTop={2}>
            {showModal?.type === "board" && (
              <TextField
                sx={{ ...focusedTextFieldStyles }}
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    Enter{" "}
                    {showModal?.type === "board" ? "orderno" : showModal?.type}
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  </span>
                }
                placeholder="Enter Order No"
                {...register("orderno", {
                  required: `${
                    (showModal?.type === "board" ||
                      showModal?.type === "orderno") &&
                    "orderno  required"
                  }`,
                })}
                onInput={NumbersValidation}
                error={!!errors.orderno}
                helperText={errors.orderno?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
              />
            )}
          </Grid>
          {showModal?.type === "grievancetype" && (
            <Grid item md={12}>
              <FormControl fullWidth error={!!errors.isanswerable}>
                <InputLabel id="isanswerable-label" shrink>
                  Is Answerable{" "}
                  <span className="error" style={{ color: "red" }}>
                    *
                  </span>
                </InputLabel>
                <Controller
                  name="isanswerable"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: `${
                      showModal?.type === "grievancetype" &&
                      "Is answerable is required"
                    }`,
                  }}
                  render={({ field }) => (
                    <Select
                      sx={customSelectStyles}
                      {...field}
                      labelId="isanswerable-label"
                      label="Is Answerable *"
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        --SELECT--
                      </MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={0}>No</MenuItem>
                    </Select>
                  )}
                />
                {errors.isanswerable && (
                  <FormHelperText>{errors.isanswerable.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
          {showModal?.type === "grievancetype" &&
            watch("isanswerable")?.toString() === "1" && (
              <Grid item md={12}>
                <TextField
                  sx={focusedTextFieldStyles}
                  fullWidth
                  variant="outlined"
                  type="text"
                  label={
                    <span>
                      Enter Answer
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    </span>
                  }
                  placeholder="Enter answer"
                  {...register("answer", {
                    required: `${
                      showModal?.type === "grievancetype" &&
                      watch("isanswerable")?.toString() === "1" &&
                      "answer is required"
                    }`,
                  })}
                  onInput={AlphabetsValidation}
                  error={!!errors.answer}
                  helperText={errors.answer?.message}
                  FormHelperTextProps={{
                    style: { margin: 0 },
                  }}
                  multiline
                  maxRows={4}
                />
              </Grid>
            )}
          {showModal?.type === "grievancetype" && (
            <Grid item md={12}>
              <FormControl fullWidth error={!!errors.israiseticket}>
                <InputLabel id="israiseticket-label" shrink>
                  Is Raise ticket{" "}
                  <span className="error" style={{ color: "red" }}>
                    *
                  </span>
                </InputLabel>
                <Controller
                  name="israiseticket"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: `${
                      showModal?.type === "grievancetype" &&
                      "Is raise ticket is required"
                    }`,
                  }}
                  render={({ field }) => (
                    <Select
                      sx={customSelectStyles}
                      {...field}
                      labelId="israiseticket-label"
                      label="Is Raise ticket *"
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        --SELECT--
                      </MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={0}>No</MenuItem>
                    </Select>
                  )}
                />
                {errors.israiseticket && (
                  <FormHelperText>
                    {errors.israiseticket.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
          {showModal?.type === "minority" && (
            <Grid item md={12}>
              <TextField
                sx={focusedTextFieldStyles}
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    Enter short minority name
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  </span>
                }
                placeholder="Enter short minority"
                {...register("shortminority", {
                  required: `${
                    showModal?.type === "minority" &&
                    "short minority name is required"
                  }`,
                })}
                onInput={AlphabetsValidation}
                error={!!errors.shortminority}
                helperText={errors.shortminority?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
              />
            </Grid>
          )}
          {showModal?.type === "minority" && (
            <Grid item md={12}>
              <TextField
                sx={focusedTextFieldStyles}
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    Enter minority code
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  </span>
                }
                placeholder="Enter minority code"
                {...register("minoritycode", {
                  required: `${
                    showModal?.type === "minority" &&
                    "minority code is required"
                  }`,
                })}
                onInput={AlphabetsValidation}
                error={!!errors.minoritycode}
                helperText={errors.minoritycode?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
              />
            </Grid>
          )}
          {showModal?.type === "state" && (
            <Grid item md={12}>
              <TextField
                sx={{ ...focusedTextFieldStyles, textTransform: "uppercase" }}
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    Enter statecode
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  </span>
                }
                placeholder="Enter statecode"
                {...register("statecode", {
                  required: `${
                    showModal?.type === "state" ? `Statecode is required` : ""
                  }`,
                })}
                onInput={AlphabetsValidation}
                error={!!errors.statecode}
                helperText={errors.statecode?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
              />
            </Grid>
          )}
          {showModal?.type === "taluka" && (
            <Grid item md={12}>
              <TextField
                sx={focusedTextFieldStyles}
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    Enter Pincode
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  </span>
                }
                placeholder="Enter pincode"
                {...register("pincode", {
                  required: `${
                    showModal?.type === "taluka" && "Pincode is required"
                  }`,
                  validate: pincodeValidation,
                })}
                inputProps={{ maxLength: 6 }}
                onInput={NumbersValidation}
                error={!!errors.pincode}
                helperText={errors.pincode?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
              />
            </Grid>
          )}

          {showModal?.type === "coursefields" && (
            <>
              <Grid item md={12}>
                <FormControl fullWidth error={!!errors.coursetabid}>
                  <InputLabel id="coursetabid" shrink>
                    Select course tab{" "}
                    <span className="error" style={{ color: "red" }}>
                      *
                    </span>
                  </InputLabel>
                  <Select
                    sx={customSelectStyles}
                    {...register("coursetabid", {
                      required: "Select course tab is required",
                    })}
                    onChange={(e) => {
                      const value = e.target.value;
                      setValue("coursetabid", value);
                      trigger("coursetabid");
                    }}
                    value={getValues("coursetabid")}
                    labelId="coursetabid"
                    label="Select course tab*"
                    defaultValue=""
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      -- Select --
                    </MenuItem>
                    {coursetabList?.all.map((val, i) => (
                      <MenuItem key={i} value={val?.coursetabid}>
                        {val?.tabname}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.coursetabid && (
                    <FormHelperText sx={{ padding: 0, margin: 0 }}>
                      {errors.coursetabid.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item md={12}>
                <TextField
                  sx={focusedTextFieldStyles}
                  fullWidth
                  variant="outlined"
                  type="text"
                  label={
                    <span>
                      Enter coursefield
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    </span>
                  }
                  placeholder=""
                  {...register("name", {
                    required: "Course field is required",
                  })}
                  onInput={AlphabetsValidation}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  FormHelperTextProps={{
                    style: { margin: 0 },
                  }}
                />
              </Grid>

              <Grid item md={12}>
                <FormControl fullWidth>
                  <InputLabel id="parentfieldid" shrink>
                    Select parent field{" "}
                  </InputLabel>
                  <Select
                    sx={customSelectStyles}
                    {...register("parentfieldid")}
                    onChange={(e) => {
                      const value = e.target.value;
                      setValue("parentfieldid", value);
                      trigger("parentfieldid");
                    }}
                    value={getValues("parentfieldid")}
                    name="parentfieldid"
                    defaultValue=""
                    labelId="parentfieldid"
                    label="Select parent field"
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      -- Select --
                    </MenuItem>
                    {coursefieldList?.all.map((val, i) => (
                      <MenuItem key={i} value={val?.coursefieldid}>
                        {val?.fieldname}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "12px",
            marginTop: "32px",
          }}
        >
          <CancelButton variant="outlined" handleBack={handleClose} />
          <BluePrimaryButton
            title={buttonLoader ? <ButtonLoader /> : "Update"}
            handleSubmit={handleSubmit(handleUpdate)}
            disabled={Object.keys(errors).length !== 0}
          />
        </Box>
      </Box>
    </Modal>
  );
}
