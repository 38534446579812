import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, MenuItem } from "@mui/material";
import { cssProperties } from "../utils/commonCssProperties";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

const cellStyle = {
  overflow: "hidden",
  wordWrap: "break-word",
  whiteSpace: "nowrap",
  textAlign: "center",
};

const headerCellStyle = {
  ...cellStyle,
  whiteSpace: "nowrap",
};

export default function DatewiseTable({ tableData, columnData }) {
  const [rows, setRows] = React.useState(tableData);

  React.useEffect(() => {
    setRows(tableData);
  }, [tableData]);

  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{
          background: "none",
          border: `1px solid ${cssProperties.bordercolor.primary2blue}`,
          boxShadow: "none",
        }}
      >
        <Table aria-label="common table">
          <TableHead>
            <TableRow>
              {columnData.map((obj, i) => (
                <TableCell
                  key={i}
                  sx={{
                    color: cssProperties.bordercolor.primary2blue,
                    borderRight:
                      i === 0 || i === 1
                        ? `1px solid ${cssProperties.bordercolor.primary2blue}`
                        : "none",
                    ...headerCellStyle,
                  }}
                  colSpan={obj.colSpan}
                >
                  {obj.name}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              {columnData.map((obj, i) =>
                obj.subHeaders ? (
                  obj.subHeaders.map((subHeader, subIndex) => (
                    <TableCell
                      key={`${i}-${subIndex}`}
                      sx={{
                        color: cssProperties.bordercolor.primary2blue,
                        borderRight:
                          (i === 0 && subIndex !== 3) ||
                          (i === 1 && subIndex !== 3) ||
                          i === 3
                            ? `1px solid ${cssProperties.bordercolor.primary2blue}`
                            : "",
                        ...headerCellStyle,
                      }}
                    >
                      {subHeader.name}
                    </TableCell>
                  ))
                ) : (
                  <TableCell
                    key={i}
                    sx={{
                      color: cssProperties.bordercolor.primary2blue,
                      borderRight:
                        i === 0 || i === 1
                          ? `1px solid ${cssProperties.bordercolor.primary2blue}`
                          : "none",
                      ...headerCellStyle,
                    }}
                  />
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ? (
              rows.map((row, i) => (
                <TableRow
                  key={i}
                  // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {columnData.map((param, j) => {
                    return param.subHeaders ? (
                      param.subHeaders.map((subHeader, subIndex) => (
                        <TableCell
                          key={`${j}-${subHeader.dataIndex}`}
                          sx={{
                            borderRight:
                              (j === 0 && subIndex !== 3) ||
                              (j === 1 && subIndex !== 3) ||
                              j === 3
                                ? `1px solid ${cssProperties.bordercolor.primary2blue}`
                                : "none",
                            ...cellStyle,
                          }}
                        >
                          {row[subHeader.dataIndex]}
                        </TableCell>
                      ))
                    ) : (
                      <TableCell
                        component="td"
                        key={j}
                        sx={{
                          borderRight:
                            j === 0 || j === 1
                              ? `1px solid ${cssProperties.bordercolor.primary2blue}`
                              : "none",
                          ...cellStyle,
                        }}
                      >
                        {row[param.dataIndex]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columnData.length}
                  sx={{
                    textAlign: "center",
                    color: cssProperties.bordercolor.primary2blue,
                  }}
                >
                   No Result Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
