import cryptoJs from "crypto-js";

export const handleEncodeJson = async ({encodeJSON}) => {
  try {
      const data = JSON.stringify(encodeJSON);
      const wordArray = cryptoJs.enc.Utf8.parse(data);

      const key = cryptoJs.lib.WordArray.random(32);
      const iv = cryptoJs.lib.WordArray.random(16);

      const encrypted = cryptoJs.AES.encrypt(wordArray, key, {
          iv: iv,
          mode: cryptoJs.mode.CBC,
          padding: cryptoJs.pad.Pkcs7
      })
      return({
          encryptedData: encrypted.ciphertext.toString(cryptoJs.enc.Hex),
          key: key.toString(cryptoJs.enc.Hex),
          iv: iv.toString(cryptoJs.enc.Hex)
      });
  } catch (error) {
      console.log('There is an error while encoding body', error);
  }
}

export const handleDecodeJSON = async ({decodeJSON}) => {
    console.log("decodeJSON",decodeJSON);
    
  try {
      const { key, iv, encryptedData } = decodeJSON;
      const decrypted = cryptoJs.AES.decrypt(
          {
              ciphertext: cryptoJs.enc.Hex.parse(encryptedData)
          },
          cryptoJs.enc.Hex.parse(key),
          {
              iv: cryptoJs.enc.Hex.parse(iv),
              mode: cryptoJs.mode.CBC,
              padding: cryptoJs.pad.Pkcs7
          }
      );
      const decryptedText = decrypted.toString(cryptoJs.enc.Utf8);
      return JSON.parse(decryptedText);
  } catch (error) {
      console.log(error);
  }
}