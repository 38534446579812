import venueActions from "./actions";

const initialState = {
  candidateCount: 0,
  venueDetails: {
    venues: 0,
    venueCount: 0,
  },
  candidateAllocationDetails:{
    allocated:[],
    notallocated:[],
    disabilityallocated:[],
    disabilitynotallocated:[]  
  },
  seatAllocationData: [],
  seatAllocationDetails: [],
  otbsVenueDetails:[],
  venueDistricts:{}
 
};

const venueReducer = (state = initialState, action) => {
  switch (action.type) {
    case venueActions.SET_CANDIDATE_COUNT:
      return {
        ...state,
        candidateCount: action.payload,
      };

    case venueActions.SET_VENUE_DETAILS:
      return {
        ...state,
        venueDetails: action.payload,
      };
    case venueActions.SET_SEAT_ALLOCATION:
      return {
        ...state,
        seatAllocationData: action.payload,
      };

    case venueActions.SET_SEAT_ALLOCATION_DETAILS:
      return {
        ...state,
        seatAllocationDetails: action.payload,
      };

      case venueActions.SET_CANDIDATE_ALLOCATION_DEAILS:
      return {
        ...state,
        candidateAllocationDetails: action.payload,
      };
      case venueActions.OTBS_SET_VENUE:
      return {
        ...state,
        otbsVenueDetails: action.payload,
      };
      case venueActions.SET_VENUE_DISTRICT_DETAILS:
        return {
          ...state,
          venueDistricts: action.payload,
        };
      

    default:
      return state;
  }
};

export default venueReducer;
