const masterFormsActions = {
    GET_GENDER: 'GET_GENDER',
    SET_GENDER: 'SET_GENDER',
    GET_MOTHERTONGUE: 'GET_MOTHERTONGUE',
    SET_MOTHERTONGUE: 'SET_MOTHERTONGUE',
    GET_RELIGION : 'GET_RELIGION',
    SET_RELIGION :'SET_RELIGION',
    GET_REGION : 'GET_REGION',
    SET_REGION :'SET_REGION',
    GET_FAMILYINCOME : 'GET_FAMILYINCOME',
    SET_FAMILYINCOME :'SET_FAMILYINCOME',
    GET_NATIONALITY : 'GET_NATIONALITY',
    SET_NATIONALITY :'SET_NATIONALITY',
    GET_LANGUAGE : 'GET_LANGUAGE',
    SET_LANGUAGE :'SET_LANGUAGE',
    GET_MINORITY : 'GET_MINORITY',
    SET_MINORITY :'SET_MINORITY',
    GET_STATE: 'GET_STATE',
    SET_STATE: 'SET_STATE',
    GET_DISTRICT: 'GET_DISTRICT',
    SET_DISTRICT: 'SET_DISTRICT',
    GET_TALUKA: 'GET_TALUKA',
    SET_TALUKA: 'SET_TALUKA',
    GET_VILLAGE: 'GET_VILLAGE',
    SET_VILLAGE: 'SET_VILLAGE',
    GET_CATEGORY: 'GET_CATEGORY',
    SET_CATEGORY: 'SET_CATEGORY',
    GET_CASTE: 'GET_CASTE',
    SET_CASTE: 'SET_CASTE',
    GET_PWDTYPE: 'GET_PWDTYPE',
    SET_PWDTYPE: 'SET_PWDTYPE',
    GET_EQUIVALENTBOARD: 'GET_EQUIVALENTBOARD',
    SET_EQUIVALENTBOARD: 'SET_EQUIVALENTBOARD',
    GET_BOARD: 'GET_BOARD',
    SET_BOARD: 'SET_BOARD',
    GET_GRIEVANCETYPE: 'GET_GRIEVANCETYPE',
    SET_GRIEVANCETYPE: 'SET_GRIEVANCETYPE',
    GET_GRIEVANCECATEGORY: 'GET_GRIEVANCECATEGORY',
    SET_GRIEVANCECATEGORY: 'SET_GRIEVANCECATEGORY',
    GET_DOCUMENTTYPE: 'GET_DOCUMENTTYPE',
    SET_DOCUMENTTYPE: 'SET_DOCUMENTTYPE',
    GET_COURSECATEGORY: 'GET_COURSECATEGORY',
    SET_COURSECATEGORY: 'SET_COURSECATEGORY',
    GET_DRIVE: 'GET_DRIVE',
    SET_DRIVE: 'SET_DRIVE',
    GET_BUILDINGTYPE: 'GET_BUILDINGTYPE',
    SET_BUILDINGTYPE: 'SET_BUILDINGTYPE',
    GET_VENUETYPE: 'GET_VENUETYPE',
    SET_VENUETYPE: 'SET_VENUETYPE',
    GET_GRADUATION_BRANCH: 'GET_GRADUATION_BRANCH',
    SET_GRADUATION_BRANCH: 'SET_GRADUATION_BRANCH',
    GET_AWARDED_CLASS: 'GET_AWARDED_CLASS',
    SET_AWARDED_CLASS: 'SET_AWARDED_CLASS',
    GET_EXAM_LANGUAGE: 'GET_EXAM_LANGUAGE',
    SET_EXAM_LANGUAGE: 'SET_EXAM_LANGUAGE',

    GET_CET_EXAM: 'GET_CET_EXAM',
    SET_CET_EXAM: 'SET_CET_EXAM',

    GET_CVC_AUTHORITY: 'GET_CVC_AUTHORITY',
    SET_CVC_AUTHORITY: 'SET_CVC_AUTHORITY',

    GET_EDUCATION_CLASS: 'GET_EDUCATION_CLASS',
    SET_EDUCATION_CLASS: 'SET_EDUCATION_CLASS',

    GET_EDUCATION_STREAM: 'GET_EDUCATION_STREAM',
    SET_EDUCATION_STREAM: 'SET_EDUCATION_STREAM',

    GET_ELIGIBILITY_QUALIFICATION: 'GET_ELIGIBILITY_QUALIFICATION',
    SET_ELIGIBILITY_QUALIFICATION: 'SET_ELIGIBILITY_QUALIFICATION',

    
    GET_GSPECIAL_ED_METHOD_SUBJECT: 'GET_GSPECIAL_ED_METHOD_SUBJECT',
    SET_GSPECIAL_ED_METHOD_SUBJECT: 'SET_GSPECIAL_ED_METHOD_SUBJECT',

    GET_GSPECIALIZATION_SUBJECT: 'GET_GSPECIALIZATION_SUBJECT',
    SET_GSPECIALIZATION_SUBJECT: 'SET_GSPECIALIZATION_SUBJECT',
    

    GET_MARITAL_STATUS: 'GET_MARITAL_STATUS',
    SET_MARITAL_STATUS: 'SET_MARITAL_STATUS',

    GET_NCL_AUTHORITY: 'GET_NCL_AUTHORITY',
    SET_NCL_AUTHORITY: 'SET_NCL_AUTHORITY',

    GET_SPORTS_BODY: 'GET_SPORTS_BODY',
    SET_SPORTS_BODY: 'SET_SPORTS_BODY',

    GET_SPORTS: 'GET_SPORTS',
    SET_SPORTS: 'SET_SPORTS',

    GET_SPORTS_PARTICIPATION: 'GET_SPORTS_PARTICIPATION',
    SET_SPORTS_PARTICIPATION: 'SET_SPORTS_PARTICIPATION',

    GET_SPORTS_RANK: 'GET_SPORTS_RANK',
    SET_SPORTS_RANK: 'SET_SPORTS_RANK',
    
    CREATE_GENDER: 'CREATE_GENDER',
    UPDATE_GENDER: 'UPDATE_GENDER',
    CREATE_MOTHERTONGUE: 'CREATE_MOTHERTONGUE',
    UPDATE_MOTHERTONGUE: 'UPDATE_MOTHERTONGUE',
    CREATE_RELIGION :'CREATE_RELIGION',
    UPDATE_RELIGION :'UPDATE_RELIGION',
    CREATE_REGION :'CREATE_REGION',
    UPDATE_REGION :'UPDATE_REGION',
    CREATE_FAMILYINCOME :'CREATE_FAMILYINCOME',
    UPDATE_FAMILYINCOME :'UPDATE_FAMILYINCOME',
    CREATE_NATIONALITY :'CREATE_NATIONALITY',
    UPDATE_NATIONALITY :'UPDATE_NATIONALITY',
    CREATE_LANGUAGE :'CREATE_LANGUAGE',
    UPDATE_LANGUAGE :'UPDATE_LANGUAGE',
    CREATE_MINORITY :'CREATE_MINORITY',
    UPDATE_MINORITY :'UPDATE_MINORITY',
    CREATE_STATE: 'CREATE_STATE',
    UPDATE_STATE: 'UPDATE_STATE',
    CREATE_DISTRICT: 'CREATE_DISTRICT',
    UPDATE_DISTRICT: 'UPDATE_DISTRICT',
    CREATE_TALUKA: 'CREATE_TALUKA',
    UPDATE_TALUKA: 'UPDATE_TALUKA',
    CREATE_VILLAGE: 'CREATE_VILLAGE',
    UPDATE_VILLAGE: 'UPDATE_VILLAGE',
    CREATE_CATEGORY: 'CREATE_CATEGORY',
    UPDATE_CATEGORY: 'UPDATE_CATEGORY',
    CREATE_CASTE: 'CREATE_CASTE',
    UPDATE_CASTE: 'UPDATE_CASTE',
    CREATE_PWDTYPE: 'CREATE_PWDTYPE',
    UPDATE_PWDTYPE: 'UPDATE_PWDTYPE',
    CREATE_EQUIVALENTBOARD: 'CREATE_EQUIVALENTBOARD',
    UPDATE_EQUIVALENTBOARD: 'UPDATE_EQUIVALENTBOARD',
    CREATE_BOARD: 'CREATE_BOARD',
    UPDATE_BOARD: 'UPDATE_BOARD',
    CREATE_GRIEVANCETYPE: 'CREATE_GRIEVANCETYPE',
    UPDATE_GRIEVANCETYPE: 'UPDATE_GRIEVANCETYPE',
    CREATE_GRIEVANCECATEGORY: 'CREATE_GRIEVANCECATEGORY',
    UPDATE_GRIEVANCECATEGORY: 'UPDATE_GRIEVANCECATEGORY',
    CREATE_DOCUMENTTYPE: 'CREATE_DOCUMENTTYPE',
    UPDATE_DOCUMENTTYPE: 'UPDATE_DOCUMENTTYPE',
    CREATE_COURSECATEGORY: 'CREATE_COURSECATEGORY',
    UPDATE_COURSECATEGORY: 'UPDATE_COURSECATEGORY',
    GET_COURSETAB : 'GET_COURSETAB',
    SET_COURSETAB : 'SET_COURSETAB',
    CREATE_COURSETAB : 'CREATE_COURSETAB',
    UPDATE_COURSETAB : 'UPDATE_COURSETAB',
    GET_COURSEFIELD : 'GET_COURSEFIELD',
    SET_COURSEFIELD : 'SET_COURSEFIELD',
    CREATE_COURSEFIELD : 'CREATE_COURSEFIELD',
    UPDATE_COURSEFIELD : 'UPDATE_COURSEFIELD',
    CREATE_DRIVE : 'CREATE_DRIVE',
    UPDATE_DRIVE: 'UPDATE_DRIVE',
    CREATE_BUILDINGTYPE : 'CREATE_BUILDINGTYPE',
    UPDATE_BUILDINGTYPE : 'UPDATE_BUILDINGTYPE',
    CREATE_VENUETYPE : 'CREATE_VENUETYPE',
    UPDATE_VENUETYPE : 'UPDATE_VENUETYPE',

    CREATE_AWARDED_CLASS: 'CREATE_AWARDED_CLASS',
    UPDATE_AWARDED_CLASS: 'UPDATE_AWARDED_CLASS',


    CREATE_CET_EXAM: 'CREATE_CET_EXAM',
    UPDATE_CET_EXAM: 'UPDATE_CET_EXAM',


    CREATE_CVC_AUTHORITY: 'CREATE_CVC_AUTHORITY',
    UPDATE_CVC_AUTHORITY: 'UPDATE_CVC_AUTHORITY',

    CREATE_EDUCATION_CLASS: 'CREATE_EDUCATION_CLASS',
    UPDATE_EDUCATION_CLASS: 'UPDATE_EDUCATION_CLASS',

    CREATE_EDUCATION_STREAM: 'CREATE_EDUCATION_STREAM',
    UPDATE_EDUCATION_STREAM: 'UPDATE_EDUCATION_STREAM',

    CREATE_ELIGIBILITY_QUALIFICATION: 'CREATE_ELIGIBILITY_QUALIFICATION',
    UPDATE_ELIGIBILITY_QUALIFICATION: 'UPDATE_ELIGIBILITY_QUALIFICATION',

    CREATE_EXAM_LANGUAGE: 'CREATE_EXAM_LANGUAGE',
    UPDATE_EXAM_LANGUAGE: 'UPDATE_EXAM_LANGUAGE',
    

   
    

    CREATE_GRADUATION_BRANCH: 'CREATE_GRADUATION_BRANCH',
    UPDATE_GRADUATION_BRANCH: 'UPDATE_GRADUATION_BRANCH',


    CREATE_MARITAL_STATUS: 'CREATE_MARITAL_STATUS',
    UPDATE_MARITAL_STATUS: 'UPDATE_MARITAL_STATUS',

    CREATE_NCL_AUTHORITY: 'CREATE_NCL_AUTHORITY',
    UPDATE_NCL_AUTHORITY: 'UPDATE_NCL_AUTHORITY',

    CREATE_SPORTS_BODY: 'CREATE_SPORTS_BODY',
    UPDATE_SPORTS_BODY: 'UPDATE_SPORTS_BODY',

    CREATE_SPORTS: 'CREATE_SPORTS',
    UPDATE_SPORTS: 'UPDATE_SPORTS',

    CREATE_SPORTS_PARTICIPATION: 'CREATE_SPORTS_PARTICIPATION',
    UPDATE_SPORTS_PARTICIPATION: 'UPDATE_SPORTS_PARTICIPATION',

    CREATE_GSPECIAL_ED_METHOD_SUBJECT: 'CREATE_GSPECIAL_ED_METHOD_SUBJECT',
    UPDATE_GSPECIAL_ED_METHOD_SUBJECT: 'UPDATE_GSPECIAL_ED_METHOD_SUBJECT',

    CREATE_GSPECIALIZATION_SUBJECT: 'CREATE_GSPECIALIZATION_SUBJECT',
    UPDATE_GSPECIALIZATION_SUBJECT: 'UPDATE_GSPECIALIZATION_SUBJECT',


    

    CREATE_SPORTS_RANK: 'CREATE_SPORTS_RANK',
    UPDATE_SPORTS_RANK: 'UPDATE_SPORTS_RANK',


  


    
   

    
};

export default masterFormsActions;