import masterFormsActions from "./actions";

const initialState = {
    genderList: {
        all: [],
        filtered: []
    },
    mothertongueList: {
        all: [],
        filtered: []
    },
    religionList: {
        all: [],
        filtered: []
    },
    regionList: {
        all: [],
        filtered: []
    },
    familyincomeList: {
        all: [],
        filtered: []
    },
    nationalityList: {
        all: [],
        filtered: []
    },
    languageList: {
        all: [],
        filtered: []
    },
    minorityList: {
        all: [],
        filtered: []
    },
    stateList: {
        all: [],
        filtered: []
    },
    districtList: {
        all: [],
        filtered: []
    },
    talukaList: {
        all: [],
        filtered: []
    },
    villageList: {
        all: [],
        filtered: []
    },
    categoryList: {
        all: [],
        filtered: []
    },
    casteList: {
        all: [],
        filtered: []
    },
    pwdtypeList: {
        all: [],
        filtered: []
    },
    equivalentboardList: {
        all: [],
        filtered: []
    },
    boardList: {
        all: [],
        filtered: []
    },
    grievancetypeList: {
        all: [],
        filtered: []
    },
    grievanceCategoryList: {
        all: [],
        filtered: []
    },
    documentTypeList: {
        all: [],
        filtered: []
    },
    courseCategoryList: {
        all: [],
        filtered: []
    },
    coursetabList : {
        all : [],
        filtered : []
    },
    coursefieldList : {
        all : [],
        filtered : []
    },
    driveList : {
        all : [],
        filtered : []
    },
    buildingTypeList : {
        all : [],
        filtered : []
    },
    venueTypeList : {
        all : [],
        filtered : []
    },
   graduationBranchList : {
        all : [],
        filtered : []
    },
    awardedClassList : {
        all : [],
        filtered : []
    },
    examLanguageList:{
        all:[],
        filtered: []
    },
    cetExamList:{
        all:[],
        filtered: []
    },
    cvcAuthorityList:{
        all:[],
        filtered: []
    },
    educationClassList:{
        all:[],
        filtered: []
    },
    educationStreamList:{
        all:[],
        filtered: []
    },
    eligibilityQualificationList:{
        all:[],
        filtered: []
    },
    gspecialEdMethodSubjectList:{
        all:[],
        filtered: []
    },
    gspecializationSubjectList:{
        all:[],
        filtered: []
    },
    maritalStatusList:{
        all:[],
        filtered: []
    },
    nclAuthorityList:{
        all:[],
        filtered: []
    },
    sportsBodyList:{
        all:[],
        filtered: []
    },
    sportsList:{
        all:[],
        filtered: []
    },
    sportsParticipationList:{
        all:[],
        filtered: []
    },
    sportsRankList:{
        all:[],
        filtered: []
    }
    
    
};

const masterFormsReducer = (state = initialState, action) => {
    switch (action.type) {
        case masterFormsActions.SET_GENDER:
            return {
                ...state,
                genderList: action.payload
            }
        case masterFormsActions.SET_MOTHERTONGUE:
            return {
                ...state,
                mothertongueList: action.payload
            }
        case masterFormsActions.SET_RELIGION:
            return {
                ...state,
                religionList: action.payload
            }
        case masterFormsActions.SET_REGION:
            return {
                ...state,
                regionList: action.payload
            }
        case masterFormsActions.SET_FAMILYINCOME:
            return {
                ...state,
                familyincomeList: action.payload
            }
        case masterFormsActions.SET_NATIONALITY:
            return {
                ...state,
                nationalityList: action.payload
            }
        case masterFormsActions.SET_LANGUAGE:
            return {
                ...state,
                languageList: action.payload
            }
        case masterFormsActions.SET_MINORITY:
            return {
                ...state,
                minorityList: action.payload
            }
        case masterFormsActions.SET_STATE:
            return {
                ...state,
                stateList: action.payload
            }
        case masterFormsActions.SET_DISTRICT:
            return {
                ...state,
                districtList: action.payload
            }
        case masterFormsActions.SET_TALUKA:
            return {
                ...state,
                talukaList: action.payload
            }
        case masterFormsActions.SET_VILLAGE:
            return {
                ...state,
                villageList: action.payload
            }
        case masterFormsActions.SET_CATEGORY:
            return {
                ...state,
                categoryList: action.payload
            }
        case masterFormsActions.SET_CASTE:
            return {
                ...state,
                casteList: action.payload
            }
        case masterFormsActions.SET_PWDTYPE:
            return {
                ...state,
                pwdtypeList: action.payload
            }
        case masterFormsActions.SET_EQUIVALENTBOARD:
            return {
                ...state,
                equivalentboardList: action.payload
            }
        case masterFormsActions.SET_BOARD:
            return {
                ...state,
                boardList: action.payload
            }
        case masterFormsActions.SET_GRIEVANCETYPE:
            return {
                ...state,
                grievancetypeList: action.payload
            }
        case masterFormsActions.SET_GRIEVANCECATEGORY:
            return {
                ...state,
                grievanceCategoryList: action.payload
            }
        case masterFormsActions.SET_DOCUMENTTYPE:
            return {
                ...state,
                documentTypeList: action.payload
            }
        case masterFormsActions.SET_COURSECATEGORY:
            return {
                ...state,
                courseCategoryList: action.payload
            }
        case masterFormsActions.SET_COURSETAB:
            return {
                ...state,
                coursetabList : action.payload
            }
        case masterFormsActions.SET_COURSEFIELD:
            return {
                ...state,
                coursefieldList : action.payload
            }  
            case masterFormsActions.SET_DRIVE:
            return {
                ...state,
                driveList : action.payload
            }
            case masterFormsActions.SET_BUILDINGTYPE:
            return {
                ...state,
                buildingTypeList : action.payload
            } 
            case masterFormsActions.SET_VENUETYPE:
            return {
                ...state,
                venueTypeList : action.payload
            }  
            case masterFormsActions.SET_GRADUATION_BRANCH:
            return {
                ...state,
               graduationBranchList : action.payload
            }  
            case masterFormsActions.SET_AWARDED_CLASS:
                return {
                    ...state,
                   awardedClassList : action.payload
                }  
                case masterFormsActions.SET_EXAM_LANGUAGE:
                return {
                    ...state,
                   examLanguageList : action.payload
                }  
                case masterFormsActions.SET_CET_EXAM:
                return {
                    ...state,
                   cetExamList : action.payload
                }  
                case masterFormsActions.SET_CVC_AUTHORITY:
                    return {
                        ...state,
                       cvcAuthorityList : action.payload
                    } 
                    case masterFormsActions.SET_EDUCATION_CLASS:
                        return {
                            ...state,
                           educationClassList : action.payload
                        }  

                        case masterFormsActions.SET_EDUCATION_STREAM:
                            return {
                                ...state,
                               educationStreamList : action.payload
                            }  
                            case masterFormsActions.SET_ELIGIBILITY_QUALIFICATION:
                                return {
                                    ...state,
                                   eligibilityQualificationList : action.payload
                                } 
                                
                                case masterFormsActions.SET_GSPECIAL_ED_METHOD_SUBJECT:
                                    return {
                                        ...state,
                                        gspecialEdMethodSubjectList : action.payload
                                    } 

                                    case masterFormsActions.SET_GSPECIALIZATION_SUBJECT:
                                        return {
                                            ...state,
                                            gspecializationSubjectList : action.payload
                                        } 
                                    case masterFormsActions.SET_MARITAL_STATUS:
                                        return {
                                            ...state,
                                            maritalStatusList : action.payload
                                        } 
                                        case masterFormsActions.SET_NCL_AUTHORITY:
                                            return {
                                                ...state,
                                                nclAuthorityList : action.payload
                                            } 
                case masterFormsActions.SET_SPORTS_BODY:
                return {
                    ...state,
                    sportsBodyList : action.payload
                } 
                case masterFormsActions.SET_SPORTS:
                    return {
                        ...state,
                        sportsList : action.payload
                    }  
                    case masterFormsActions.SET_SPORTS_PARTICIPATION:
                    return {
                        ...state,
                        sportsParticipationList : action.payload
                    }  
                    case masterFormsActions.SET_SPORTS_RANK:
                        return {
                            ...state,
                            sportsRankList : action.payload
                        }  
                    
                    
                
                
               
        default:
            return state;
    }
};

export default masterFormsReducer;