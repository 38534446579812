import { put, call, all, takeEvery, delay } from "redux-saga/effects";
import masterFormsActions from "./actions";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import commonActions from "../common/actions";
import { handleEncodeJson } from "../../utils/encodeDecodeJson";

const masterFormsSagas = function* () {
  yield all([
    yield takeEvery(masterFormsActions.GET_GENDER, getGender),
    yield takeEvery(masterFormsActions.CREATE_GENDER, createGender),
    yield takeEvery(masterFormsActions.UPDATE_GENDER, updateGender),
    yield takeEvery(masterFormsActions.GET_MOTHERTONGUE, getMothertongue),
    yield takeEvery(masterFormsActions.CREATE_MOTHERTONGUE, createMothertongue),
    yield takeEvery(masterFormsActions.UPDATE_MOTHERTONGUE, updateMothertongue),
    yield takeEvery(masterFormsActions.GET_RELIGION, getReligion),
    yield takeEvery(masterFormsActions.CREATE_RELIGION, createReligion),
    yield takeEvery(masterFormsActions.UPDATE_RELIGION, updateReligion),
    yield takeEvery(masterFormsActions.GET_REGION, getRegion),
    yield takeEvery(masterFormsActions.CREATE_REGION, createRegion),
    yield takeEvery(masterFormsActions.UPDATE_REGION, updateRegion),
    yield takeEvery(masterFormsActions.GET_FAMILYINCOME, getFamilyincome),
    yield takeEvery(masterFormsActions.CREATE_FAMILYINCOME, createFamilyincome),
    yield takeEvery(masterFormsActions.UPDATE_FAMILYINCOME, updateFamilyincome),
    yield takeEvery(masterFormsActions.GET_NATIONALITY, getNationality),
    yield takeEvery(masterFormsActions.CREATE_NATIONALITY, createNationality),
    yield takeEvery(masterFormsActions.UPDATE_NATIONALITY, updateNationality),
    yield takeEvery(masterFormsActions.GET_LANGUAGE, getLanguage),
    yield takeEvery(masterFormsActions.CREATE_LANGUAGE, createLanguage),
    yield takeEvery(masterFormsActions.UPDATE_LANGUAGE, updateLanguage),
    yield takeEvery(masterFormsActions.UPDATE_NATIONALITY, updateNationality),
    yield takeEvery(masterFormsActions.GET_MINORITY, getMinority),
    yield takeEvery(masterFormsActions.CREATE_MINORITY, createMinority),
    yield takeEvery(masterFormsActions.UPDATE_MINORITY, updateMinority),
    yield takeEvery(masterFormsActions.GET_STATE, getState),
    yield takeEvery(masterFormsActions.CREATE_STATE, createState),
    yield takeEvery(masterFormsActions.UPDATE_STATE, updateState),
    yield takeEvery(masterFormsActions.GET_DISTRICT, getDistrict),
    yield takeEvery(masterFormsActions.CREATE_DISTRICT, createDistrict),
    yield takeEvery(masterFormsActions.UPDATE_DISTRICT, updateDistrict),
    yield takeEvery(masterFormsActions.GET_TALUKA, getTaluka),
    yield takeEvery(masterFormsActions.CREATE_TALUKA, createTaluka),
    yield takeEvery(masterFormsActions.UPDATE_TALUKA, updateTaluka),
    yield takeEvery(masterFormsActions.GET_VILLAGE, getVillage),
    yield takeEvery(masterFormsActions.CREATE_VILLAGE, createVillage),
    yield takeEvery(masterFormsActions.UPDATE_VILLAGE, updateVillage),
    yield takeEvery(masterFormsActions.GET_CATEGORY, getCategory),
    yield takeEvery(masterFormsActions.CREATE_CATEGORY, createCategory),
    yield takeEvery(masterFormsActions.UPDATE_CATEGORY, updateCategory),
    yield takeEvery(masterFormsActions.GET_CASTE, getCaste),
    yield takeEvery(masterFormsActions.CREATE_CASTE, createCaste),
    yield takeEvery(masterFormsActions.UPDATE_CASTE, updateCaste),
    yield takeEvery(masterFormsActions.GET_PWDTYPE, getPwdtype),
    yield takeEvery(masterFormsActions.CREATE_PWDTYPE, createPwdtype),
    yield takeEvery(masterFormsActions.UPDATE_PWDTYPE, updatePwdtype),
    yield takeEvery(masterFormsActions.GET_EQUIVALENTBOARD, getEquivalentboard),
    yield takeEvery(masterFormsActions.CREATE_EQUIVALENTBOARD, createEquivalentboard),
    yield takeEvery(masterFormsActions.UPDATE_EQUIVALENTBOARD, updateEquivalentboard),
    yield takeEvery(masterFormsActions.GET_BOARD, getboard),
    yield takeEvery(masterFormsActions.CREATE_BOARD, createboard),
    yield takeEvery(masterFormsActions.UPDATE_BOARD, updateboard),
    yield takeEvery(masterFormsActions.GET_GRIEVANCETYPE, getGrievancetype),
    yield takeEvery(masterFormsActions.CREATE_GRIEVANCETYPE, createGrievancetype),
    yield takeEvery(masterFormsActions.UPDATE_GRIEVANCETYPE, updateGrievancetype),
    yield takeEvery(masterFormsActions.GET_GRIEVANCECATEGORY, getGrievancecategory),
    yield takeEvery(masterFormsActions.CREATE_GRIEVANCECATEGORY, createGrievancecategory),
    yield takeEvery(masterFormsActions.UPDATE_GRIEVANCECATEGORY, updateGrievancecategory),
    yield takeEvery(masterFormsActions.GET_DOCUMENTTYPE, getDocumentType),
    yield takeEvery(masterFormsActions.CREATE_DOCUMENTTYPE, createDocumentType),
    yield takeEvery(masterFormsActions.UPDATE_DOCUMENTTYPE, updateDocumentType),
    yield takeEvery(masterFormsActions.GET_COURSECATEGORY, getCourseCategory),
    yield takeEvery(masterFormsActions.CREATE_COURSECATEGORY, createCourseCategory),
    yield takeEvery(masterFormsActions.UPDATE_COURSECATEGORY, updateCourseCategory),
    yield takeEvery(masterFormsActions.GET_COURSETAB, getCoursetab),
    yield takeEvery(masterFormsActions.CREATE_COURSETAB, createCoursetab),
    yield takeEvery(masterFormsActions.UPDATE_COURSETAB, updateCoursetab),
    yield takeEvery(masterFormsActions.GET_COURSEFIELD, getCoursefield),
    yield takeEvery(masterFormsActions.CREATE_COURSEFIELD, createCoursefield),
    yield takeEvery(masterFormsActions.UPDATE_COURSEFIELD, updateCoursefield),
    yield takeEvery(masterFormsActions.GET_DRIVE, getDrive),
    yield takeEvery(masterFormsActions.CREATE_DRIVE, createDrive),
    yield takeEvery(masterFormsActions.UPDATE_DRIVE, updateDrive),
    yield takeEvery(masterFormsActions.GET_BUILDINGTYPE, getBuildingtype),
    yield takeEvery(masterFormsActions.CREATE_BUILDINGTYPE, createBuildingtype),
    yield takeEvery(masterFormsActions.UPDATE_BUILDINGTYPE, updateBuildingtype),
    yield takeEvery(masterFormsActions.GET_VENUETYPE, getVenuetype),
    yield takeEvery(masterFormsActions.CREATE_VENUETYPE, createVenuetype),
    yield takeEvery(masterFormsActions.UPDATE_VENUETYPE, updateVenuetype),
    yield takeEvery(masterFormsActions.GET_GRADUATION_BRANCH, getGraduationBranch),
    yield takeEvery(masterFormsActions.CREATE_GRADUATION_BRANCH, createGraduationBranch),
    yield takeEvery(masterFormsActions.UPDATE_GRADUATION_BRANCH, updateGraduationBranch),

    yield takeEvery(masterFormsActions.GET_AWARDED_CLASS, getAwardedClass),
    yield takeEvery(masterFormsActions.CREATE_AWARDED_CLASS, createAwardClass),
    yield takeEvery(masterFormsActions.UPDATE_AWARDED_CLASS, updateAwardClass),

    yield takeEvery(masterFormsActions.GET_EXAM_LANGUAGE, getExamLanguage),
    yield takeEvery(masterFormsActions.CREATE_EXAM_LANGUAGE, createExamLanguage),
    yield takeEvery(masterFormsActions.UPDATE_EXAM_LANGUAGE, updateExamLanguage),

    yield takeEvery(masterFormsActions.GET_CET_EXAM, getCetExamName),
    yield takeEvery(masterFormsActions.CREATE_CET_EXAM, createCetExamName),
    yield takeEvery(masterFormsActions.UPDATE_CET_EXAM, updateCetExamName),

    yield takeEvery(masterFormsActions.GET_CVC_AUTHORITY, getCvcAuthority),
    yield takeEvery(masterFormsActions.CREATE_CVC_AUTHORITY, createCvcAuthority),
    yield takeEvery(masterFormsActions.UPDATE_CVC_AUTHORITY, updateCvcAuthority),

    yield takeEvery(masterFormsActions.GET_EDUCATION_CLASS, getEducationClass),
    yield takeEvery(masterFormsActions.CREATE_EDUCATION_CLASS, createEducationClass),
    yield takeEvery(masterFormsActions.UPDATE_EDUCATION_CLASS, updateEducationClass),

    yield takeEvery(masterFormsActions.GET_EDUCATION_STREAM, getEducationStream),
    yield takeEvery(masterFormsActions.CREATE_EDUCATION_STREAM, createEducationStream),
    yield takeEvery(masterFormsActions.UPDATE_EDUCATION_STREAM, updateEducationStream),

    yield takeEvery(masterFormsActions.GET_ELIGIBILITY_QUALIFICATION, getEligibilityQualification),
    yield takeEvery(masterFormsActions.CREATE_ELIGIBILITY_QUALIFICATION, createEligibilityQualification),
    yield takeEvery(masterFormsActions.UPDATE_ELIGIBILITY_QUALIFICATION, updateEligibilityQualification),

    yield takeEvery(masterFormsActions.GET_GSPECIAL_ED_METHOD_SUBJECT, getGspecialEdMethodSubject),
    yield takeEvery(masterFormsActions.CREATE_GSPECIAL_ED_METHOD_SUBJECT, createGspecialEdMethodSubject),
    yield takeEvery(masterFormsActions.UPDATE_GSPECIAL_ED_METHOD_SUBJECT, updateGspecialEdMethodSubject),

    yield takeEvery(masterFormsActions.GET_GSPECIALIZATION_SUBJECT, getGspecializationSubject),
    yield takeEvery(masterFormsActions.CREATE_GSPECIALIZATION_SUBJECT, createGspecializationSubject),
    yield takeEvery(masterFormsActions.UPDATE_GSPECIALIZATION_SUBJECT, updateGspecializationSubject),


    yield takeEvery(masterFormsActions.GET_MARITAL_STATUS, getMaritalStatus),
    yield takeEvery(masterFormsActions.CREATE_MARITAL_STATUS, createMaritalStatus),
    yield takeEvery(masterFormsActions.UPDATE_MARITAL_STATUS, updateMaritalStatus),

    yield takeEvery(masterFormsActions.GET_NCL_AUTHORITY, getNclAuthority),
    yield takeEvery(masterFormsActions.CREATE_NCL_AUTHORITY, createNclAuthority),
    yield takeEvery(masterFormsActions.UPDATE_NCL_AUTHORITY, updateNclAuthority),




    yield takeEvery(masterFormsActions.GET_SPORTS_BODY, getSportsBody),
    yield takeEvery(masterFormsActions.CREATE_SPORTS_BODY, createSportsBody),
    yield takeEvery(masterFormsActions.UPDATE_SPORTS_BODY, updateSportsBody),

    yield takeEvery(masterFormsActions.GET_SPORTS, getSports),
    yield takeEvery(masterFormsActions.CREATE_SPORTS, createSports),
    yield takeEvery(masterFormsActions.UPDATE_SPORTS, updateSports),

    yield takeEvery(masterFormsActions.GET_SPORTS_PARTICIPATION, getSportsParticipation),
    yield takeEvery(masterFormsActions.CREATE_SPORTS_PARTICIPATION, createSportsParticipation),
    yield takeEvery(masterFormsActions.UPDATE_SPORTS_PARTICIPATION, updateSportsParticipation),
    
    yield takeEvery(masterFormsActions.GET_SPORTS_RANK, getSportsRank),
    yield takeEvery(masterFormsActions.CREATE_SPORTS_RANK, createSportsRank),
    yield takeEvery(masterFormsActions.UPDATE_SPORTS_RANK, updateSportsRank),
    
    
    
  ])
};

const getGender = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/gender`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_GENDER,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createGender = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;
  const encodedData = yield call(handleEncodeJson, { encodeJSON: payload?.data });


  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/gender`, encodedData)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_GENDER });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateGender = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/gender/${payload?.id}`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_GENDER });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getMothertongue = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/master/mothertongue`)
    );
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_MOTHERTONGUE,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createMothertongue = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;
  const encodedData = yield call(handleEncodeJson, { encodeJSON: payload?.data });


  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/mothertongue`, encodedData)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_MOTHERTONGUE });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateMothertongue = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/mothertongue/${payload?.id}`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_MOTHERTONGUE });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getReligion = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/religion`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_RELIGION,
      payload: {
        all: result?.status === 200 ? finalResult : [],
        filtered: result?.status === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createReligion = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;
  const encodedData = yield call(handleEncodeJson, { encodeJSON: payload?.data });


  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/religion`, encodedData)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_RELIGION });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateReligion = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/religion/${payload?.id}`, payload?.data)
    );

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_RELIGION });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getRegion = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/region`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_REGION,
      payload: {
        all: result?.status === 200 ? finalResult : [],
        filtered: result?.status === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createRegion = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;
  const encodedData = yield call(handleEncodeJson, { encodeJSON: payload?.data });


  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/region`, encodedData)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_REGION });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateRegion = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/region/${payload?.id}`, payload?.data)
    );

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_REGION });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getFamilyincome = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/master/familyincome`)
    );
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_FAMILYINCOME,
      payload: {
        all: result?.status === 200 ? finalResult : [],
        filtered: result?.status === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createFamilyincome = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/familyincome`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_FAMILYINCOME });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateFamilyincome = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/familyincome/${payload?.id}`, payload?.data)
    );

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_FAMILYINCOME });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getNationality = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/nationality`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_NATIONALITY,
      payload: {
        all: result?.status === 200 ? finalResult : [],
        filtered: result?.status === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createNationality = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;
  const encodedData = yield call(handleEncodeJson, { encodeJSON: payload?.data });


  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/nationality`, encodedData)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_NATIONALITY });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateNationality = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/nationality/${payload?.id}`, payload?.data)
    );

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_NATIONALITY });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getLanguage = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/language`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_LANGUAGE,
      payload: {
        all: result?.status === 200 ? finalResult : [],
        filtered: result?.status === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createLanguage = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/language`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_LANGUAGE });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateLanguage = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/language/${payload?.id}`, payload?.data)
    );

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_LANGUAGE });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getMinority = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/minority`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_MINORITY,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createMinority = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/minority`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_MINORITY });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateMinority = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/minority/${payload?.id}`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_MINORITY });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getState = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/state`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_STATE,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createState = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/state`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_STATE });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateState = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/state/${payload?.id}`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_STATE });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getDistrict = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/district`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_DISTRICT,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createDistrict = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/district`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_DISTRICT });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateDistrict = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/district/${payload?.id}`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_DISTRICT });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getTaluka = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/taluka`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_TALUKA,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createTaluka = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/taluka`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_TALUKA });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateTaluka = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/taluka/${payload?.id}`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_TALUKA });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getVillage = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/village`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_VILLAGE,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createVillage = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/village`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_VILLAGE });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateVillage = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/village/${payload?.id}`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_VILLAGE });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getCategory = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/category`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_CATEGORY,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createCategory = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/category`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_CATEGORY });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateCategory = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/category/${payload?.id}`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_CATEGORY });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getCaste = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/caste`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_CASTE,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createCaste = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/caste`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_CASTE });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateCaste = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/caste/${payload?.id}`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_CASTE });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getPwdtype = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/pwdtype`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_PWDTYPE,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createPwdtype = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/pwdtype`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_PWDTYPE });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updatePwdtype = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/pwdtype/${payload?.id}`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_PWDTYPE });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getEquivalentboard = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/master/equivalentboard`)
    );
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_EQUIVALENTBOARD,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createEquivalentboard = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/equivalentboard`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_EQUIVALENTBOARD });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateEquivalentboard = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(
        `${API_URL}/master/equivalentboard/${payload?.id}`,
        payload?.data
      )
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_EQUIVALENTBOARD });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getboard = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/board`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_BOARD,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
};

const createboard = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;
  const encodedData = yield call(handleEncodeJson, { encodeJSON: payload?.data });

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/board`, encodedData)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_BOARD });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateboard = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/board/${payload?.id}`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_BOARD });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getGrievancetype = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/grievance`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return {
        ...val,
        id: i + 1,
        isanswerablevalue: parseInt(val?.isanswerable) === 1 ? "Yes" : "No",
        israiseticketvalue: parseInt(val?.israiseticket) === 1 ? "Yes" : "No",
      };
    });
    yield put({
      type: masterFormsActions.SET_GRIEVANCETYPE,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createGrievancetype = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/grievance`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_GRIEVANCETYPE });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateGrievancetype = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/grievance/${payload?.id}`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_GRIEVANCETYPE });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getGrievancecategory = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/master/grievancecategory`)
    );
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_GRIEVANCECATEGORY,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createGrievancecategory = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/grievancecategory`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_GRIEVANCECATEGORY });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateGrievancecategory = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;
  console.log(payload);

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(
        `${API_URL}/master/grievancecategory/${payload?.data?.categoryid}`,
        payload?.data
      )
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_GRIEVANCECATEGORY });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getDocumentType = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/document`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_DOCUMENTTYPE,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createDocumentType = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/document`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_DOCUMENTTYPE });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateDocumentType = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(
        `${API_URL}/master/document/${payload?.data?.id}`,
        payload?.data
      )
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_DOCUMENTTYPE });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getCourseCategory = function* () {

  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/master/coursecategory`)
    );
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 }
    })
    yield put({
      type: masterFormsActions.SET_COURSECATEGORY,
      payload: { all: result?.data?.statusCode === 200 ? finalResult : [], filtered: result?.data?.statusCode === 200 ? finalResult : [] }
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  };
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createCourseCategory = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/coursecategory`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: (result?.data?.statusCode === 200) ? 'success' : 'failed',
        show: true,
        message: result?.data?.message
      }
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_COURSECATEGORY });
      yield put({ type: commonActions.SET_SHOW_MODAL, payload: { show: false, type: null, method: null, data: null } });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null }
    });
  } catch (err) {
    console.log(err);
  };
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateCourseCategory = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/coursecategory/${payload?.data?.id}`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: (result?.data?.statusCode === 200) ? 'success' : 'failed',
        show: true,
        message: result?.data?.message
      }
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_COURSECATEGORY });
      yield put({ type: commonActions.SET_SHOW_MODAL, payload: { show: false, type: null, method: null, data: null } });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null }
    });
  } catch (err) {
    console.log(err);
  };
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getCoursetab = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/coursetab/0`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_COURSETAB,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};


const createCoursetab = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/coursetab`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_COURSETAB });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateCoursetab = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(
        `${API_URL}/master/coursetab/${payload?.data?.id}`,
        payload?.data
      )
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_COURSETAB });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};


const getCoursefield = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/coursefields/0`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_COURSEFIELD,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createCoursefield = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/coursefields`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_COURSEFIELD });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateCoursefield = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(
        `${API_URL}/master/coursefields/${payload?.data?.id}`,
        payload?.data
      )
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_COURSEFIELD });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};
const getDrive = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/drive`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_DRIVE,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createDrive = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/drive`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_DRIVE });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};


const updateDrive = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/drive/${payload?.id}`, payload?.data)
    );

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_DRIVE });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};




const getBuildingtype = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/buildingtype`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_BUILDINGTYPE,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createBuildingtype = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;
  const encodedData = yield call(handleEncodeJson, { encodeJSON: payload?.data });

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/buildingtype`, encodedData)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_BUILDINGTYPE });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateBuildingtype = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(
        `${API_URL}/master/buildingtype/${payload?.data?.buildingtypeid}`,
        payload?.data
      )
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_BUILDINGTYPE });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getVenuetype = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/venuetype`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_VENUETYPE,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createVenuetype = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/venuetype`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_VENUETYPE });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateVenuetype = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;
// console.log(payload,'payload')
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(
        `${API_URL}/master/venuetype/${payload?.data?.venuetypeid}`,
        payload?.data
      )
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_VENUETYPE });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getGraduationBranch = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/graduationbranch`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_GRADUATION_BRANCH,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createGraduationBranch  = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/graduationbranch`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_GRADUATION_BRANCH });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateGraduationBranch  = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/graduationbranch/${payload?.id}`, payload?.data)
    );

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_GRADUATION_BRANCH });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};


const getAwardedClass = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/awardedclass`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_AWARDED_CLASS,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createAwardClass = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/awardedclass`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_AWARDED_CLASS });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateAwardClass = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/awardedclass/${payload?.id}`, payload?.data)
    );

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_AWARDED_CLASS });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};


const getExamLanguage = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/examlanguage`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_EXAM_LANGUAGE,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createExamLanguage = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/examlanguage`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_EXAM_LANGUAGE });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateExamLanguage = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/examlanguage/${payload?.id}`, payload?.data)
    );

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_EXAM_LANGUAGE });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};




const getCetExamName = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/cetexamname`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_CET_EXAM,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createCetExamName = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/cetexamname`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_CET_EXAM });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateCetExamName = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/cetexamname/${payload?.id}`, payload?.data)
    );

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_CET_EXAM });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};



const getCvcAuthority = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/issuinguthority`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_CVC_AUTHORITY,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createCvcAuthority = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/issuinguthority`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_CVC_AUTHORITY });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateCvcAuthority = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/issuinguthority/${payload?.id}`, payload?.data)
    );

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_CVC_AUTHORITY });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};


const getEducationClass = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/educationclass`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_EDUCATION_CLASS,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createEducationClass = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/educationclass`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_EDUCATION_CLASS });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateEducationClass = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/educationclass/${payload?.id}`, payload?.data)
    );

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_EDUCATION_CLASS });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getEducationStream = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/educationstream`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_EDUCATION_STREAM,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createEducationStream = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/educationstream`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_EDUCATION_STREAM });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateEducationStream = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/educationstream/${payload?.id}`, payload?.data)
    );

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_EDUCATION_STREAM });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};


const getEligibilityQualification = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/eligibilityqualification`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_ELIGIBILITY_QUALIFICATION,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createEligibilityQualification = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/eligibilityqualification`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_ELIGIBILITY_QUALIFICATION });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateEligibilityQualification = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/eligibilityqualification/${payload?.id}`, payload?.data)
    );

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_ELIGIBILITY_QUALIFICATION });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};



const getGspecialEdMethodSubject = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/gspecialedmethodorsubject`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_GSPECIAL_ED_METHOD_SUBJECT,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createGspecialEdMethodSubject = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/gspecialedmethodorsubject`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_GSPECIAL_ED_METHOD_SUBJECT });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateGspecialEdMethodSubject = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/gspecialedmethodorsubject/${payload?.id}`, payload?.data)
    );

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_GSPECIAL_ED_METHOD_SUBJECT });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};


const getGspecializationSubject = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/gsecializationorsubject/0`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_GSPECIALIZATION_SUBJECT,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
};

const createGspecializationSubject = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/gsecializationorsubject`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_GSPECIALIZATION_SUBJECT });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateGspecializationSubject = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/gsecializationorsubject/${payload?.id}`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_GSPECIALIZATION_SUBJECT });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null, data: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};




const getMaritalStatus = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/maritalstatus`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_MARITAL_STATUS,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createMaritalStatus = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/maritalstatus`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_MARITAL_STATUS });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateMaritalStatus = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/maritalstatus/${payload?.id}`, payload?.data)
    );

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_MARITAL_STATUS });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};



const getNclAuthority = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/nclauthority`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_NCL_AUTHORITY,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createNclAuthority = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/nclauthority`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_NCL_AUTHORITY });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateNclAuthority = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/nclauthority/${payload?.id}`, payload?.data)
    );

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_NCL_AUTHORITY });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};





const getSportsBody = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/sportsbody`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_SPORTS_BODY,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createSportsBody  = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/sportsbody`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_SPORTS_BODY });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateSportsBody  = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/sportsbody/${payload?.id}`, payload?.data)
    );

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_SPORTS_BODY });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};



const getSports = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/sports`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_SPORTS,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createSports  = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/sports`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_SPORTS });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateSports  = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/sports/${payload?.id}`, payload?.data)
    );

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_SPORTS });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};





const getSportsParticipation = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/sportsparticipationlevel`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_SPORTS_PARTICIPATION,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createSportsParticipation  = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/sportsparticipationlevel`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_SPORTS_PARTICIPATION });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateSportsParticipation  = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/sportsparticipationlevel/${payload?.id}`, payload?.data)
    );

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_SPORTS_PARTICIPATION });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};



const getSportsRank = function* () {
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/sportsrank`));
    let finalResult = result?.data?.result?.map((val, i) => {
      return { ...val, id: i + 1 };
    });
    yield put({
      type: masterFormsActions.SET_SPORTS_RANK,
      payload: {
        all: result?.data?.statusCode === 200 ? finalResult : [],
        filtered: result?.data?.statusCode === 200 ? finalResult : [],
      },
    });
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createSportsRank  = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/master/sportsrank`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_SPORTS_RANK });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateSportsRank  = function* (data) {
  const { payload } = data;
  const { resetForm } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/master/sportsrank/${payload?.id}`, payload?.data)
    );

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      resetForm();
      yield put({ type: masterFormsActions.GET_SPORTS_RANK });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: false, type: null, method: null },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};


export default masterFormsSagas;
