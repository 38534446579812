import { Box, Card, Grid, MenuItem, Select, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CommonTable from "../../common/CommonTable";
import BluePrimaryButton from "../../common/button/bluePrimaryButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useDispatch, useSelector } from "react-redux";
import masterActions from "../../redux/master/actions";
import customerActions from "../../redux/customer/actions";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function CheckPaymentHistory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [courseid, setCourseId] = useState(0);
  const [emailid, setEmailId] = useState("");
  const [mobileno, setMobileNo] = useState("");
  const [applicantionid, setapplicantionId] = useState("");
  const [buttonClick, setButtonClick] = useState(false);
  const [courseError, setCourseError] = useState("");
  const [commonError, setCommonError] = useState("");

  const { courseList } = useSelector((state) => state.masterReducer);
  const { payments } = useSelector((state) => state.customerReducer);

  console.log("payments-history", payments);

  const paymentHistoryColumns = [
    {
      name: "Sr. No",
      dataIndex: "srNo",
    },
    {
      name: "Reference No",
      dataIndex: "referenceNo",
    },
    {
      name: "Total Fee (₹)",
      dataIndex: "totalFee",
    },
    {
      name: "Payment Initiated On",
      dataIndex: "paymentInitiatedOn",
    },
    {
      name: "Payment Date",
      dataIndex: "paymentDate",
    },
    {
      name: "Payment Status",
      dataIndex: "paymentStatus",
    },
    {
      name: "Refund Status",
      dataIndex: "refundStatus",
    },
    {
      name: "Print Receipt",
      dataIndex: "printReceipt",
      render: (text, record) =>
        record.paymentStatus === "Paid" ? (
          <BluePrimaryButton
            title="Print"
            variant="contained"
            color="primary"
            handleSubmit={() => navigateToReceipt(record)}
          />
        ) : null,
    },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    dispatch({ type: masterActions.GET_COURSE_LIST });

    const storedData = JSON.parse(localStorage.getItem("paymentFormData"));
    if (storedData) {
      setCourseId(storedData.courseid);
      setEmailId(storedData.emailid);
      setMobileNo(storedData.mobileno);
      setapplicantionId(storedData.applicantionid);
    }

    return () => {
    };
  }, [dispatch]);


  const onChangeCourse = (e) => {
    setCourseId(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmailId(e.target.value);
  };

  const handleMobileChange = (e) => {
    setMobileNo(e.target.value);
  };

  const handleApplicationChange = (e) => {
    setapplicantionId(e.target.value);
  };

  const handleGenerate = () => {
    setButtonClick(true);
    let hasError = false;

    if (courseid === 0) {
      setCourseError("Course is required");
      hasError = true;
    } else {
      setCourseError("");
    }

    if (!emailid && !mobileno && !applicantionid) {
      setCommonError(
        "At least Email ID or Mobile No or Application No is required"
      );
      hasError = true;
    } else {
      setCommonError("");
    }

    if (!hasError) {
      const formData = {
        courseid: courseid,
        emailid: emailid,
        mobileno: mobileno,
        applicantionid: applicantionid,
      };
      localStorage.setItem("paymentFormData", JSON.stringify(formData));

      dispatch({
        type: customerActions.GET_PAYMENT_HISTORY,
        payload: {
          courseid: courseid,
          emailid: emailid,
          mobileno: mobileno,
          applicantionid: applicantionid,
        },
      });
    }
  };

  const formattedTableData = payments?.paymenthistoryreport.map(
    (item, index) => ({
      srNo: index + 1,
      applicantionid: item.applicantionid,
      candidatename: item.candidatename,
      referenceNo: item.receiptno,
      totalFee: item.amount.toFixed(2),
      paymentInitiatedOn: formatDate(item.initiatedtime),
      paymentDate: item.paymentdate,
      paymentStatus: item.paymentstatus,
      paymentcompletiondate : item.paymentcompletiondate,
      paymentmode: item.paymentmode,
      refundStatus: item.refundstatus || "N/A",
      printReceipt: item.paymentstatus === "Paid",
      pursepose: item.pursepose,
    })
  );

  console.log("Formatted Table Data:", formattedTableData);

  const navigateToReceipt = (paymentRecord) => {
    navigate(
      `/${Cookies.get(
        "mhet_mst_project"
      )}/FeeProcess/Receipt/OnlinePaymentReceipt`,
      {
        state: { paymentRecord },
        replace: true,
      }
    );
  };



useEffect(() => {
  const removeLocalStorage = () => {
    localStorage.removeItem("paymentFormData");
  };

  window.addEventListener('beforeunload', removeLocalStorage);

  return () => {
    window.removeEventListener('beforeunload', removeLocalStorage);
  };
}, []);


  return (
    <>
      <Box
        sx={{
          margin: "1rem auto",
          backgroundColor: "rgba(255, 255, 255, 1)",
          borderRadius: "8px",
          padding: "10px",
        }}
      >
        <Card sx={{ p: 2 }}>
          <Typography>Check Payment History</Typography>

          <Grid item sm={12}>
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{ gap: 2, flexWrap: "wrap", mt: 2 }}
            >
              <FormControl error={!!courseError} sx={{ width: "220px" }}>
                <Select
                  size="small"
                  onChange={onChangeCourse}
                  value={courseid}
                  defaultValue={0}
                  sx={{ height: "56px" }}
                >
                  <MenuItem value={0} selected>
                    Select Course <span style={{ color: "red" }}>*</span>
                  </MenuItem>
                  {courseList?.all
                    ?.filter((course) => course.isactive === "1")
                    .map((course, i) => (
                      <MenuItem key={i} value={course.courseid}>
                        {course.coursename}
                      </MenuItem>
                    ))}
                </Select>
                {courseError && (
                  <Typography variant="caption" color="error">
                    {courseError}
                  </Typography>
                )}
              </FormControl>

              <FormControl
                sx={{ width: "220px" }}
                variant="outlined"
                error={!!commonError}
              >
                <InputLabel htmlFor="search-email">
                  Search by Email ID
                </InputLabel>
                <OutlinedInput
                  id="search-email"
                  value={emailid}
                  onChange={handleEmailChange}
                  label="Search by Email ID"
                />
                {commonError && (
                  <Typography variant="caption" color="error">
                    {commonError}
                  </Typography>
                )}
              </FormControl>

              <FormControl
                sx={{ width: "220px" }}
                variant="outlined"
                error={!!commonError}
              >
                <InputLabel htmlFor="search-mobile">
                  Search by Mobile No
                </InputLabel>
                <OutlinedInput
                  id="search-mobile"
                  value={mobileno}
                  onChange={handleMobileChange}
                  label="Search by Mobile No"
                />
                {commonError && (
                  <Typography variant="caption" color="error">
                    {commonError}
                  </Typography>
                )}
              </FormControl>

              <FormControl
                sx={{ width: "220px" }}
                variant="outlined"
                error={!!commonError}
              >
                <InputLabel htmlFor="search-application">
                  Search by Application No
                </InputLabel>
                <OutlinedInput
                  id="search-application"
                  value={applicantionid}
                  onChange={handleApplicationChange}
                  label="Search by Application No"
                />
                {commonError && (
                  <Typography variant="caption" color="error">
                    {commonError}
                  </Typography>
                )}
              </FormControl>

              <Box sx={{ marginTop: 1 }}>
                <BluePrimaryButton
                  title={"Generate"}
                  handleSubmit={handleGenerate}
                />
              </Box>
            </Box>
          </Grid>

          <Box sx={{ mb: 2, mt: 2 }}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "500",
                color: "black",
                fontSize: "20px",
                lineHeight: "24px",
                mb: 2,
                backgroundColor: "whitesmoke",
                padding: "8px",
                borderRadius: "4px",
              }}
            >
              Paid Transaction Details
            </Typography>
          </Box>

          <Box sx={{ mb: 2 }}>
            <Typography
              variant="subtitle1"
              sx={{ color: "red", fontWeight: "bold" }}
            >
              Instructions:
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "500",
                color: "red",
                fontSize: "16px",
                lineHeight: "24px",
                mb: 2,
                backgroundColor: "#f6e9e9",
                border: "1px solid red",
                padding: "8px",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: "24px", marginRight: "8px" }}>•</span>
              Receipt for Online / Netbanking payment can be taken by clicking
              on the "Print" button under the "Print Receipt" column.
            </Typography>
          </Box>
          {formattedTableData && formattedTableData?.length > 0 && (
            <CommonTable
              columnData={paymentHistoryColumns}
              tableData={formattedTableData || []}
            />
          )}

          {(formattedTableData?.length == 0 ||
            formattedTableData == undefined) &&
            buttonClick && (
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold", textAlign: "center" }}
                >
                  No Records Found
                </Typography>
              </Box>
            )}
        </Card>
      </Box>
    </>
  );
}
