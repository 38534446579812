import React,{useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import commonActions from "../../../redux/common/actions";
import forcePushAllocationActions from "../../../redux/forcePushAllocation/actions";
import courseExamAndBatchActions from "../../../redux/courseExamAndBatch/actions";
import {
  Box,
  Grid,
  Modal,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
} from "@mui/material";
import { cssProperties } from "../../../utils/commonCssProperties";
import { AlphabetsValidation } from "../../../utils/validation";
import CancelButton from "../../../common/button/cancelButton";
import BluePrimaryButton from "../../../common/button/bluePrimaryButton";
import BackButton from "../../../common/button/backButton";
import MauvelousPrimaryButton from "../../../common/button/mauvelousPrimaryButton";
import ButtonLoader from "../../../common/buttonLoader";
import moment from "moment-timezone";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: `${cssProperties?.backgroundcolor?.secondary}`,
  padding: "24px",
  borderRadius: "8px",
  overflowY: "auto",
  maxHeight: "calc(100vh - 20px)",
  scrollbarWidth: "thin",
};

export default function EditModal({
  register,
  handleSubmit,
  errors,
  handleCreateProject,
  resetForm,
  setValue,
  getValues,
  trigger,
  watch,
  reset,
  courseListByDrive,
  examdateByCourse,
  driveList,
  backofficeTokenDetails
}) {
  const dispatch = useDispatch();
console.log(driveList,'driveList')
  const { showModal, buttonLoader } = useSelector(
    (state) => state.commonReducer
  );

  useEffect(() => {
    console.log(showModal, "showModal");
    if (showModal.data) {
      reset({ ...showModal.data });
      dispatch({
        type: courseExamAndBatchActions.GET_COURSE_BY_DRIVE,
        payload: { data: parseInt(showModal?.data?.driveid) },
      });
      dispatch({
        type: forcePushAllocationActions.GET_EXAM_DATES_BY_COURSEID,
        payload: { courseid: parseInt(showModal?.data?.courseid) },
      });
    }
  }, [showModal]);
  useEffect(() => {
    let startTime = watch("starttime");
    let endTime = watch("endtime");
    console.log(startTime, endTime, typeof startTime, typeof endTime);
    if (startTime && endTime) {
      const start = moment(startTime, "HH:mm");
      const end = moment(endTime, "HH:mm");
      console.log(start, end);
      console.log(startTime, endTime, "in condition");
      const duration = moment(endTime, "HH:mm").diff(
        moment(startTime, "HH:mm"),
        "minute"
      );
      console.log(duration);
      setValue(
        "duration",
        duration
          ? `${String(Math.floor(duration / 60)).padStart(2, "0")}:${String(
              duration % 60
            ).padStart(2, "0")}`
          : ""
      );
    }
  }, [watch("starttime"), watch("endtime"), setValue]);
  const handleClose = () => {
    dispatch({
      type: commonActions.SET_SHOW_MODAL,
      payload: { show: false, type: null, method: null, data: null },
    });
    // resetForm()
  };
  const handleUpdate = (data) => {
    console.log(data,'data')
    dispatch({
      type: courseExamAndBatchActions.EDIT_EXAM_BATCH,
      payload: { data: {...data,userid: parseInt(backofficeTokenDetails?.id),}, handleReset: reset,handleClose:handleClose },
    })
    
  };

  return (
    <Modal
      open={showModal?.show && showModal?.method === "EMM"}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid
          style={{
            padding: "24px",
            // minHeight: "60vh",
            backgroundColor: "#fff",
            borderRadius: "8px",
            marginTop: "12px",
          }}
        >
          <Grid container spacing={2}>
            {/* <Grid container sx={{ display: "felx", flexDirection: "column", border: `1px solid ${cssProperties?.bordercolor?.primary2blue}`, padding: "24px 16px 24px 16px", borderRadius: "4px", justifyContent: "space-between" }}> */}
            {/* <Grid item xs={12} sm={12} md={12} lg={12} > */}
            {/* <Grid container gap={3} > */}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {/* <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}> */}
              <FormControl fullWidth error={!!errors.driveid}>
                <InputLabel id="demo-simple-select-label">
                  Drive Name
                </InputLabel>
                <Select
                  size="small"
                  id="demo-simple-select"
                  label="Drive Name"
                  {...register("driveid", {
                    required: "Drive is required",
                  })}
                  onChange={(e) => {
                    setValue("driveid", e.target.value);
                    trigger(["driveid"]);
                    dispatch({
                      type: courseExamAndBatchActions.GET_COURSE_BY_DRIVE,
                      payload: { data: parseInt(e.target.value) },
                    });
                  }}
                  value={watch("driveid") ?? ""}
                  sx={{
                    height: "56px",
                  }}
                >
                  <MenuItem value={""} disabled>
                    --Select--
                  </MenuItem>
                  {driveList?.all?.map((val, i) => (
                    <MenuItem value={val?.driveid} key={i}>
                      {val?.drivename}
                    </MenuItem>
                  ))}
                </Select>
                {errors?.driveid && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors?.driveid?.message}
                  </FormHelperText>
                )}
              </FormControl>

              {/* <Button variant='outlined' onClick={() => handleModelOpen('Drive Name')} sx={{ color: "black", borderColor: "#A3A3A3", padding: 1, minWidth: '0', }}
                  ><FaPlus /></Button> */}
              {/* </Box> */}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {/* <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}> */}
              <FormControl fullWidth error={!!errors.courseid}>
                <InputLabel id="demo-simple-select-label">
                  Add course
                </InputLabel>
                <Select
                  size="small"
                  id="demo-simple-select"
                  label="Add course"
                  {...register("courseid", {
                    required: "Drive is required",
                  })}
                  disabled={watch("driveid") ? false : true}
                  onChange={(e) => {
                    setValue("courseid", e.target.value);
                    trigger(["courseid"]);
                    dispatch({
                      type: forcePushAllocationActions.GET_EXAM_DATES_BY_COURSEID,
                      payload: { courseid: parseInt(e.target.value) },
                    });
                  }}
                  value={watch("courseid") ?? ""}
                  sx={{
                    height: "56px",
                  }}
                >
                  <MenuItem value={""} disabled>
                    --Select--
                  </MenuItem>
                  {courseListByDrive?.map((val, i) => (
                    <MenuItem value={val?.courseid} key={i}>
                      {val?.coursename}
                    </MenuItem>
                  ))}
                </Select>
                {errors?.courseid && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors?.courseid?.message}
                  </FormHelperText>
                )}
              </FormControl>

              {/* <Button variant='outlined' onClick={() => handleModelOpen('Course')} sx={{ color: "black", borderColor: "#A3A3A3", padding: 1, minWidth: '0', }} ><FaPlus /></Button> */}
              {/* </Box> */}
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  gap: 2,
                }}
              >
                <FormControl fullWidth error={!!errors.courseexamid}>
                  <InputLabel id="demo-simple-select-label">
                    Add Exam Date
                  </InputLabel>
                  <Select
                    size="small"
                    id="demo-simple-select"
                    label="Add Exam Date"
                    {...register("courseexamid", {
                      required: "Exam Date is required",
                    })}
                    onChange={(e) => {
                      setValue("courseexamid", e.target.value);
                      trigger(["courseexamid"]);
                    }}
                    value={watch("courseexamid") ?? ""}
                    disabled={watch("courseid") ? false : true}
                    sx={{
                      height: "56px",
                    }}
                    // inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
                  >
                    <MenuItem value={""} disabled>
                      --Select--
                    </MenuItem>
                    {examdateByCourse?.map((val, i) => (
                      <MenuItem value={val?.courseexamid} key={i}>
                        {val?.examdate}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors?.courseexamid && (
                    <FormHelperText style={{ margin: 0 }}>
                      {errors?.courseexamid?.message}
                    </FormHelperText>
                  )}
                </FormControl>
                {/* <Button
                  variant="outlined"
                  onClick={() => handleModelOpen("Date")}
                  sx={{
                    color: "black",
                    borderColor: "#A3A3A3",
                    padding: 1,
                    minWidth: "0",
                    height: "56px",
                  }}
                  disabled={
                    watch("driveid") && watch("courseid") ? false : true
                  }
                >
                  <FaPlus />
                </Button> */}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                type="text"
                label={
                  <span>
                    Batchname
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  </span>
                }
                placeholder="Enter Batchname"
                {...register("batchname", {
                  required: "Batchname is required",
                })}
                error={!!errors.batchname}
                helperText={errors.batchname?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
              />
              {/* <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Add Batch</InputLabel>
                <Select size='small'
                  id="demo-simple-select"
                  label="Add Batch"
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>

              <Button variant='outlined' onClick={() => handleModelOpen('Batch')} sx={{ color: "black", borderColor: "#A3A3A3", padding: 1, minWidth: '0', }}
              ><FaPlus /></Button>
            </Box> */}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label={
                  <span>
                    Select Exam Start Time
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  </span>
                }
                type="time"
                // defaultValue="07:30"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                fullWidth
                {...register("starttime", {
                  required: "Start time is required",
                })}
                error={!!errors.starttime}
                helperText={errors.starttime?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label={
                  <span>
                    Select Exam End Time
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  </span>
                }
                type="time"
                // defaultValue="07:30"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5
                  views: ["hours", "mitenus"],
                  format: "HH:mm",
                  ampm: false,
                }}
                fullWidth
                {...register("endtime", {
                  required: "End time is required",
                })}
                error={!!errors.endtime}
                helperText={errors.endtime?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label={
                  <span>
                    Exam Total Duration
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  </span>
                }
                // type="time"
                // defaultValue="07:30"
                InputLabelProps={{
                  shrink: true,
                }}
                readOnly
                inputProps={{
                  step: 60, // 5 min
                  readOnly: true,
                }}
                fullWidth
                {...register("duration", {
                  required: "Duration is required",
                })}
                error={!!errors.duration}
                helperText={errors.duration?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label={
                  <span>
                    Select Gate Closing Time
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  </span>
                }
                type="time"
                // defaultValue="07:30"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                fullWidth
                {...register("gateclosingtime", {
                  required: "Gate closing time is required",
                })}
                error={!!errors.gateclosingtime}
                helperText={errors.gateclosingtime?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label={
                  <span>
                    Select Reporting Time
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  </span>
                }
                type="time"
                // defaultValue="07:30"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                fullWidth
                {...register("reportingtime", {
                  required: "Reporting time is required",
                })}
                error={!!errors.reportingtime}
                helperText={errors.reportingtime?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            display={"flex"}
            gap={2}
            mt={5}
          >
            {BackButton({
              handleBack: handleClose,
            })}
            {MauvelousPrimaryButton({
              title: "Submit",
              handleClick: handleSubmit(handleUpdate),
            })}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
