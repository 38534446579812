import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { cssProperties } from "../utils/commonCssProperties";
import { Box } from "@mui/material";

export default function CommonTable({ tableData, columnData }) {
  let [rows, setRows] = React.useState(tableData);
  React.useEffect(() => {
    setRows(tableData);
  }, [tableData]);
  // function createData(name, calories, fat, carbs, protein) {
  //     return { name, calories, fat, carbs, protein };
  // }

  //   const rows = [
  //     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  //     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  //     createData('Eclair', 262, 16.0, 24, 6.0),
  //     createData('Cupcake', 305, 3.7, 67, 4.3),
  //     createData('Gingerbread', 356, 16.0, 49, 3.9),
  //   ];
  return (
    <div>
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
        mb: 2,
        pr:2,
        mt:4,
      }}
    >
    <TableContainer
      component={Paper}
      sx={{
        background: "none",
        border: `1px solid ${cssProperties.bordercolor.primary2blue}`,
        boxShadow: "none",
      }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columnData?.map((obj, i) => {
              return (
                <TableCell
                  sx={{
                    color: cssProperties.bordercolor.primary2blue,
                    textAlign: "center",
                  }}
                  key={i}
                >
                  {obj.name}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 ? (
            rows?.map((row, i) => (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {columnData?.map((param, i) => (
                  <TableCell
                    component="td"
                    key={i}
                    sx={{ textAlign: "center" }}
                  >
                    {param["render"]
                      ? param["render"](row[param.dataIndex], row)
                      : row[param.dataIndex]}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={columnData?.length}
                component="td"
               
                sx={{ textAlign: "center", color: cssProperties.bordercolor.primary2blue, }}
              >
                No Result Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
    </div>
  );
}
