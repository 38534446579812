import { call, delay, put, takeEvery } from "redux-saga/effects";
import venueActions from "./actions";
import axios, { all } from "axios";
import { API_URL } from "../../utils/constants";
import commonActions from "../common/actions";

const venueSagas = function* () {
  yield all([
    yield takeEvery(venueActions.GET_CANDIDATE_COUNT, getCandidateCount),
    yield takeEvery(venueActions.UPLOAD_VENUE_DATA, createVenueData),
    yield takeEvery(venueActions.GET_VENUE_DETAILS, getVenueDetails),
    yield takeEvery(
      venueActions.GET_VENUE_DISTRICT_DETAILS,
      getVenueDistrictDetails
    ),
    yield takeEvery(venueActions.RUN_SEAT_ALLOCATION, runSeatAllocation),
    yield takeEvery(venueActions.GET_SEAT_ALLOCATION, getSeatAllocation),
    yield takeEvery(
      venueActions.GET_SEAT_ALLOCATION_DETAILS,
      getVenueseatAllocationDetails
    ),
    yield takeEvery(
      venueActions.GET_CANDIDATE_ALLOCATION_DEAILS,
      getCandidateAllocationDetails
    ),
    yield takeEvery(
      venueActions.RESET_SEAT_ALLOCATION,
      resetSeatAllocationDetails
    ),
    yield takeEvery(
      venueActions.STOP_SEAT_ALLOCATION,
      stopSeatAllocationDetails
    ),

    yield takeEvery(venueActions.UPLOAD_OTBS_VENUE, uploadOtbsVenue),
    yield takeEvery(venueActions.UPDATE_OTBS_VENUE, updateOtbsVenue),

    yield takeEvery(venueActions.OTBS_GET_VENUE, getOtbsVenue),
  ]);
};
const createVenueData = function* (data) {
  const { payload } = data;
  payload.setLoading(true);
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    if (payload?.file && payload?.courseid) {
      let formData = new FormData();
      const documentFile = new File([payload?.file], payload?.file.name, {
        type: "application/xlsx",
      });
      formData.append("venueFile", documentFile);
      formData.append("courseid", payload?.courseid);
      let result = yield call(() =>
        axios.post(`${API_URL}/backoffice/venuedata`, formData)
      );
      yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      if (result?.data?.statusCode === 200) {
        payload.setOpen(false);
        payload.setLoading(false);
        yield put({
          type: venueActions.SET_VENUE_DETAILS,
          payload: result?.data?.data,
        });
      }

      yield delay(2000);
      yield put({
        type: commonActions.SET_ALERT,
        payload: { status: null, show: false, message: null },
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getCandidateCount = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/backoffice/venuedata/getcandidatecount/${payload?.courseid}`
      )
    );
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    yield put({
      type: venueActions.SET_CANDIDATE_COUNT,
      payload: result?.data?.statusCode === 200 ? result?.data?.data : null,
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
};
const getVenueDetails = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/backoffice/venuedata/getvenuedetails/${payload?.courseid}`
      )
    );
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    yield put({
      type: venueActions.SET_VENUE_DETAILS,
      payload: result?.data?.data,
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
};
const getVenueDistrictDetails = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/backoffice/venuedata/getdistrictvenuedetails/${payload?.courseid}`
      )
    );
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    yield put({
      type: venueActions.SET_VENUE_DISTRICT_DETAILS,
      payload: {
        all:
          result?.data?.statusCode === 200 ? result?.data?.districtResult : [],
        filtered:
          result?.data?.statusCode === 200 ? result?.data?.districtResult : [],
      },
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
};
const runSeatAllocation = function* (data) {
  const { payload } = data;
  // yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    // let result = yield call(() => axios.post(`http://localhost:5000/seatallocation/runseatallocation`, { courseid: payload?.courseid, preferenceid: payload?.preferenceid, adminuserid: payload?.adminuserid, status: 1 }))
    let result = yield call(() =>
      axios.post(`${API_URL}/backoffice/venuedata/runseatallocation`, {
        courseid: payload?.courseid,
        preferenceid: payload?.preferenceid,
        adminuserid: payload?.adminuserid,
        pcmb: payload?.pcmb,
        ispcmb: payload?.ispcmb,

        status: 1,
      })
    );
    // yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      payload?.setStatusModalOpen({
        open: true,
        statustext: "Processing...",
      });
      let intervalId = payload.getDataInterval(
        payload?.courseid,
        result?.data?.data
      );
      payload.setIntervalId(intervalId);
    } else {
      payload?.setStatusModalOpen({
        open: false,
        statustext: "",
      });
    }

    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
};
const getVenueseatAllocationDetails = function* (data) {
  // const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/backoffice/venuedata/getseatallocationdetails`)
    );
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    yield put({
      type: venueActions.SET_SEAT_ALLOCATION_DETAILS,
      payload: result?.data?.data,
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
};
const getSeatAllocation = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/backoffice/venuedata/getseatallocation/${payload.courseid}`
      )
    );
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    yield put({
      type: venueActions.SET_SEAT_ALLOCATION,
      payload: result?.data?.data,
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
};

const getCandidateAllocationDetails = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/backoffice/venuedata/getcandidateallocationdetails/${payload.courseid}`
      )
    );
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    yield put({
      type: venueActions.SET_CANDIDATE_ALLOCATION_DEAILS,
      payload: result?.data?.data,
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
};

const resetSeatAllocationDetails = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/backoffice/venuedata/resetseatallocation`, payload)
    );
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      yield put({
        type: venueActions.GET_SEAT_ALLOCATION,
        payload: { courseid: payload?.courseid },
      });
      yield put({
        type: venueActions.GET_CANDIDATE_COUNT,
        payload: { courseid:payload?.courseid },
      });
      yield put({
        type: venueActions.GET_VENUE_DETAILS,
        payload: { courseid:payload?.courseid },
      });
      yield put({
        type: venueActions.GET_CANDIDATE_ALLOCATION_DEAILS,
        payload: { courseid:payload?.courseid },
      });
    }
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
};

const stopSeatAllocationDetails = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/backoffice/venuedata/stopseatallocation`, payload)
    );
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      yield put({ type: venueActions.GET_SEAT_ALLOCATION_DETAILS });
    }

    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
};

const uploadOtbsVenue = function* (data) {
  const { payload } = data;
  payload.setLoading(true);
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    if (payload?.file && payload?.courseid) {
      let formData = new FormData();
      const documentFile = new File([payload?.file], payload?.file.name, {
        type: "application/xlsx",
      });
      formData.append("otbsFile", documentFile);
      formData.append("courseid", payload?.courseid);
      formData.append("userid", payload?.userid);
      let result = yield call(() =>
        axios.post(`${API_URL}/backoffice/venuedata/otbsbulkupload`, formData)
      );
      yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      // if (result?.data?.statusCode === 400) {
      //   payload.setOpen(false);
      //   payload.setLoading(false);
      //   payload.setFile(null)
      //   console.log(result.data,'result.data')
      //   payload?.setStatusModalOpen({open:true,statustext:

      //     `<h4>
      //     Total Empty venueid : ${result?.data?.data?.venueid} </br>
      //     Total Empty state : ${result?.data?.data?.state} </br>
      //     Total Empty district : ${result?.data?.data?.district } </br>
      //     Total Empty taluka : ${result?.data?.data?.taluka } </br>
      //     Total Empty village : ${result?.data?.data?.village } </br>
      //     Total Empty seatsavailability : ${result?.data?.data?.seatsavailability } </br>
      //     Total Empty addressline1 : ${result?.data?.data?.addressline1 } </br>
      //     Total Empty addressline2 : ${result?.data?.data?.addressline2 } </br>
      //     Total Empty buildingtype : ${result?.data?.data?.buildingtype } </br>
      //     Total Empty city : ${result?.data?.data?.city } </br>
      //     Total Empty googlemaplink : ${result?.data?.data?.googlemaplink } </br>
      //     Total Empty landmark : ${result?.data?.data?.landmark } </br>
      //     Total Empty pincode : ${result?.data?.data?.pincode } </br>
      //     Total Empty venuename : ${result?.data?.data?.venuename } </br>
      //     Total Empty venuetype : ${result?.data?.data?.venuetype } </br>
      //     Total Empty suitableforpwd : ${result?.data?.data?.suitableforpwd } </br>

      //     </h4>`})
      //   // yield put({
      //   //   type: venueActions.OTBS_SET_VENUE,
      //   //   payload: result?.data?.venueDetails,
      //   // });
      // }
      if (result?.data?.statusCode === 400) {
        payload.setOpen(false);
        payload.setLoading(false);
        payload.setFile(null);
        console.log(result.data, "result.data");

        let statustext = "<h4>";

        if (result?.data?.data?.venueid > 0) {
          statustext += `Total Empty venueid : ${result?.data?.data?.venueid} </br>`;
        }

        if (result?.data?.data?.state > 0) {
          statustext += `Total Empty state : ${result?.data?.data?.state} </br>`;
        }

        if (result?.data?.data?.district > 0) {
          statustext += `Total Empty district : ${result?.data?.data?.district} </br>`;
        }

        if (result?.data?.data?.taluka > 0) {
          statustext += `Total Empty taluka : ${result?.data?.data?.taluka} </br>`;
        }

        if (result?.data?.data?.village > 0) {
          statustext += `Total Empty village : ${result?.data?.data?.village} </br>`;
        }

        if (result?.data?.data?.seatsavailability > 0) {
          statustext += `Total Empty seatsavailability : ${result?.data?.data?.seatsavailability} </br>`;
        }

        if (result?.data?.data?.addressline1 > 0) {
          statustext += `Total Empty addressline1 : ${result?.data?.data?.addressline1} </br>`;
        }

        if (result?.data?.data?.addressline2 > 0) {
          statustext += `Total Empty addressline2 : ${result?.data?.data?.addressline2} </br>`;
        }

        if (result?.data?.data?.buildingtype > 0) {
          statustext += `Total Empty buildingtype : ${result?.data?.data?.buildingtype} </br>`;
        }

        if (result?.data?.data?.city > 0) {
          statustext += `Total Empty city : ${result?.data?.data?.city} </br>`;
        }

        if (result?.data?.data?.googlemaplink > 0) {
          statustext += `Total Empty googlemaplink : ${result?.data?.data?.googlemaplink} </br>`;
        }

        if (result?.data?.data?.landmark > 0) {
          statustext += `Total Empty landmark : ${result?.data?.data?.landmark} </br>`;
        }

        if (result?.data?.data?.pincode > 0) {
          statustext += `Total Empty pincode : ${result?.data?.data?.pincode} </br>`;
        }

        if (result?.data?.data?.venuename > 0) {
          statustext += `Total Empty venuename : ${result?.data?.data?.venuename} </br>`;
        }

        if (result?.data?.data?.venuetype > 0) {
          statustext += `Total Empty venuetype : ${result?.data?.data?.venuetype} </br>`;
        }

        if (result?.data?.data?.suitableforpwd > 0) {
          statustext += `Total Empty suitableforpwd : ${result?.data?.data?.suitableforpwd} </br>`;
        }

        statustext += "</h4>";

        if (statustext !== "<h4></h4>") {
          payload?.setStatusModalOpen({
            open: true,
            statustext: statustext,
          });
        }
      } else {
        payload.setLoading(false);
      }
      if (result?.data?.statusCode === 200) {
        payload.setOpen(false);
        payload.setLoading(false);
        payload.setFile(null);
        payload?.setStatusModalOpen({
          open: true,
          statustext: `<h4>
          Total Uploaded Venues : ${
            result?.data?.duplicatesCount + result?.data?.uniqueCount
          } </br>
          Total Inserted Venues : ${result?.data?.uniqueCount} </br>
          Total Existing Venues : ${result?.data?.duplicatesCount} </br>
          </h4>`,
        });
        yield put({
          type: venueActions.OTBS_SET_VENUE,
          payload: result?.data?.venueDetails,
        });
      } else {
        payload.setLoading(false);
      }

      yield delay(2000);
      yield put({
        type: commonActions.SET_ALERT,
        payload: { status: null, show: false, message: null },
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getOtbsVenue = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/backoffice/venuedata/${payload.courseid}`)
    );
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    yield put({
      type: venueActions.OTBS_SET_VENUE,
      payload: result?.data?.statusCode === 200 ? result?.data?.result : [],
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
};

const updateOtbsVenue = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(
        `${API_URL}/backoffice/venuedata/edit/${payload?.courseid}/${payload?.venueid}`,
        {
          totalseats: payload?.totalseats,
        }
      )
    );
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    if (result?.data?.statusCode === 200) {
      yield put({
        type: venueActions.OTBS_GET_VENUE,
        payload: { courseid: payload?.courseid },
      });
      payload?.setEditmodal({
        open: false,
        data: null,
      });
    }

    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
};

export default venueSagas;
