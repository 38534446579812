// export const API_URL = 'http://18.143.159.223:5000'; //old url

// export const API_URL = 'http://54.169.144.238:5000'; //TEST url
// export const API_URL = "http://34.93.35.227:3001"; //UAT url
// export const API_URL = "http://35.207.221.237/api";

// export const API_URL = 'https://mhcet-reg-uat.hubblehox.ai/api';

export const API_URL = 'https://mhcet-reg-dev.hubblehox.ai/api';
export const NODE_ENV = 'production'


// export const API_URL = 'http://localhost:3001';
// export const NODE_ENV = 'development'

// export const API_URL = 'https://mhcet-test.praathee.in/api';



export const itemsPerPage = 5;


