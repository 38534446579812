import { Box, Grid, styled, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { cssProperties } from "../../utils/commonCssProperties";

export default function PaymentReceipt() {
    const navigate = useNavigate();
  const location = useLocation();
  const paymentRecord = location.state?.paymentRecord;

  useEffect(() => {
    if (!paymentRecord) {
      navigate("/mhcet/check-payment-history", { replace: true });
    } else {
      window.print();

      navigate("/mhcet/check-payment-history", { replace: true });
    }
  }, [paymentRecord, navigate]);

  const style = styled({
    fontStyle: {
      fontWeight: 600,
    },
  });
  
  const classes = style();

  const coursename = paymentRecord?.pursepose;

  const displayText = coursename?.replace("Regisration for", "").trim();

  return (
    <Box sx={{ maxWidth: 800, margin: "0 auto" }}>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          padding: "25px 0",
          border: `5px solid ${cssProperties.bordercolor.primary}`,
          textAlign: "center",
        }}
      >
        <Grid
          item
          sm={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 24 }}
          >
            STATE COMMON ENTRANCE TEST CELL, MAHARASHTRA STATE
          </Typography>
          <Typography
            sx={{ ...classes.fontStyle, fontWeight: 500, fontSize: 16 }}
          >
            8th Floor, New Excelsior Building, A.K.Nayak Marg, Fort,
            Mumbai-400001. (M.S.)
          </Typography>
          <Typography
            sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 24 }}
          >
            Detailed Payment Receipt
          </Typography>
          <Typography
            sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 18 }}
          >
            ({displayText})
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",

          padding: "25px 0",
          border: `5px solid ${cssProperties.bordercolor.primary}`,
        }}
        rowSpacing={1}
      >
        <Grid
          item
          sm={6}
          sx={{
            display: "flex",

            alignItems: "center",
            padding: `0 5px`,
            justifyContent: "end",
          }}
        >
          <Typography
            sx={{ ...classes.fontStyle, fontWeight: 400, fontSize: 16 }}
          >
            Payee Id
          </Typography>
        </Grid>
        <Grid
          item
          sm={6}
          sx={{
            display: "flex",

            alignItems: "center",
            padding: `0 5px`,
            justifyContent: "start",
          }}
        >
          <Typography
            sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 16 }}
          >
            {paymentRecord?.applicantionid}
          </Typography>
        </Grid>
        <Grid
          item
          sm={6}
          sx={{
            display: "flex",

            alignItems: "center",
            padding: `0 5px`,
            justifyContent: "end",
          }}
        >
          <Typography
            sx={{ ...classes.fontStyle, fontWeight: 400, fontSize: 16 }}
          >
            Name Of Payee
          </Typography>
        </Grid>
        <Grid
          item
          sm={6}
          sx={{
            display: "flex",

            alignItems: "center",
            padding: `0 5px`,
            justifyContent: "start",
          }}
        >
          <Typography
            sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 16 }}
          >
            {paymentRecord?.candidatename}
          </Typography>
        </Grid>
        <Grid
          item
          sm={6}
          sx={{
            display: "flex",

            alignItems: "center",
            padding: `0 5px`,
            justifyContent: "end",
          }}
        >
          <Typography
            sx={{ ...classes.fontStyle, fontWeight: 400, fontSize: 16 }}
          >
            Reference Number
          </Typography>
        </Grid>
        <Grid
          item
          sm={6}
          sx={{
            display: "flex",

            alignItems: "center",
            padding: `0 5px`,
            justifyContent: "start",
          }}
        >
          <Typography
            sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 16 }}
          >
            {paymentRecord?.referenceNo}
          </Typography>
        </Grid>
        <Grid
          item
          sm={6}
          sx={{
            display: "flex",

            alignItems: "center",
            padding: `0 5px`,
            justifyContent: "end",
          }}
        >
          <Typography
            sx={{ ...classes.fontStyle, fontWeight: 400, fontSize: 16 }}
          >
            Transaction Amount
          </Typography>
        </Grid>
        <Grid
          item
          sm={6}
          sx={{
            display: "flex",

            alignItems: "center",
            padding: `0 5px`,
            justifyContent: "start",
          }}
        >
          <Typography
            sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 16 }}
          >
            {paymentRecord?.totalFee}
          </Typography>
        </Grid>

        <Grid
          item
          sm={6}
          sx={{
            display: "flex",

            alignItems: "center",
            padding: `0 5px`,
            justifyContent: "end",
          }}
        >
          <Typography
            sx={{ ...classes.fontStyle, fontWeight: 400, fontSize: 16 }}
          >
            Payment Initiation Date
          </Typography>
        </Grid>
        <Grid
          item
          sm={6}
          sx={{
            display: "flex",

            alignItems: "center",
            padding: `0 5px`,
            justifyContent: "start",
          }}
        >
          <Typography
            sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 16 }}
          >
            {paymentRecord?.paymentInitiatedOn}
          </Typography>
        </Grid>

        <Grid
          item
          sm={6}
          sx={{
            display: "flex",

            alignItems: "center",
            padding: `0 5px`,
            justifyContent: "end",
          }}
        >
          <Typography
            sx={{ ...classes.fontStyle, fontWeight: 400, fontSize: 16 }}
          >
            Payment Status
          </Typography>
        </Grid>
        <Grid
          item
          sm={6}
          sx={{
            display: "flex",

            alignItems: "center",
            padding: `0 5px`,
            justifyContent: "start",
          }}
        >
          <Typography
            sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 16 }}
          >
            {paymentRecord?.paymentStatus}
          </Typography>
        </Grid>



        
        {/* <Grid
          item
          sm={6}
          sx={{
            display: "flex",

            alignItems: "center",
            padding: `0 5px`,
            justifyContent: "end",
          }}
        >
          <Typography
            sx={{ ...classes.fontStyle, fontWeight: 400, fontSize: 16 }}
          >
            Payment Completion Date
          </Typography>
        </Grid>
        <Grid
          item
          sm={6}
          sx={{
            display: "flex",

            alignItems: "center",
            padding: `0 5px`,
            justifyContent: "start",
          }}
        >
          <Typography
            sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 16 }}
          >
            {paymentRecord?.paymentcompletiondate}
          </Typography>
        </Grid>


         */}
      
   

        <Grid
          item
          sm={6}
          sx={{
            display: "flex",

            alignItems: "center",
            padding: `0 5px`,
            justifyContent: "end",
          }}
        >
          <Typography
            sx={{ ...classes.fontStyle, fontWeight: 400, fontSize: 16 }}
          >
            Mode Of Payment
          </Typography>
        </Grid>
        <Grid
          item
          sm={6}
          sx={{
            display: "flex",

            alignItems: "center",
            padding: `0 5px`,
            justifyContent: "start",
          }}
        >
          <Typography
            sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 16 }}
          >
            {paymentRecord?.paymentmode}
          </Typography>
        </Grid>

        <Grid
          item
          sm={6}
          sx={{
            display: "flex",

            alignItems: "center",
            padding: `0 5px`,
            justifyContent: "end",
          }}
        >
          <Typography
            sx={{ ...classes.fontStyle, fontWeight: 400, fontSize: 16 }}
          >
            Purpose
          </Typography>
        </Grid>
        <Grid
          item
          sm={6}
          sx={{
            display: "flex",

            alignItems: "center",
            padding: `0 5px`,
            justifyContent: "start",
          }}
        >
          <Typography
            sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 16 }}
          >
            {paymentRecord?.pursepose}
          </Typography>
        </Grid>
      </Grid>
       {/* <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",

                padding: "25px 0",
                border: `5px solid ${cssProperties.bordercolor.primary}`,
              }}
              rowSpacing={1}
            >
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "end",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 400, fontSize: 16 }}
                >
                  Payee Id
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "start",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 16 }}
                >
                  {paymentRecord?.applicantid}
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "end",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 400, fontSize: 16 }}
                >
                  Name Of Payee
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "start",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 16 }}
                >
                  {paymentRecord?.name}
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "end",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 400, fontSize: 16 }}
                >
                  Reference Number
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "start",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 16 }}
                >
                  {paymentRecord?.receiptno}
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "end",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 400, fontSize: 16 }}
                >
                  Transaction Amount
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "start",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 16 }}
                >
                  {paymentRecord?.amount}
                </Typography>
              </Grid>

              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "end",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 400, fontSize: 16 }}
                >
                  Payment Initiation Date
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "start",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 16 }}
                >
                  {paymentRecord?.orderdate}
                </Typography>
              </Grid>

              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "end",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 400, fontSize: 16 }}
                >
                  Payment Status
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "start",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 16 }}
                >
                  {paymentRecord?.statustext}
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "end",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 400, fontSize: 16 }}
                >
                  Payment Completion Date
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "start",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 16 }}
                >
                  {paymentRecord?.paymentcompletiondate}
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "end",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 400, fontSize: 16 }}
                >
                  Mode Of Payment
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "start",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 16 }}
                >
                  {paymentRecord?.gatewaycourseid == 1
                    ? "Billdesk"
                    : paymentRecord?.gatewaycourseid == 2
                    ? "PayU"
                    : ""}
                </Typography>
              </Grid>

              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "end",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 400, fontSize: 16 }}
                >
                  Purpose
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "start",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 16 }}
                >
                  Registration fee - {paymentRecord?.coursename?.trim()}
                </Typography>
              </Grid>
            </Grid> */}
    </Box>
  );
}
