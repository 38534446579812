import { call, delay, put, takeEvery } from "redux-saga/effects";
import rollNumberActions from "./actions";
import axios, { all } from "axios";
import { API_URL } from "../../utils/constants";
import commonActions from "../common/actions";

const rollNumberSagas = function* () {
  yield all([
    yield takeEvery(
      rollNumberActions.GET_EXAM_DETAILS_WITH_COURSEID,
      getExamDetailsWithCourseid
    ),
    yield takeEvery(rollNumberActions.GET_BATCH_CANDIDATES, getBatchCandidates),
    yield takeEvery(rollNumberActions.GENERATE_ROLL_NUMBER, generateRollNumber),
    yield takeEvery(
      rollNumberActions.GET_DOWNLOAD_ROLL_NO_DATA,
      getDownloadRollNoData
    ),
    yield takeEvery(
      rollNumberActions.RESET_ROLL_NUMBER_GENERATION,
      resetRollNumber
    ),
  ]);
};

const getBatchCandidates = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/backoffice/rollnumber/${payload?.courseid}/${payload?.batchid}`
      )
    );
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    yield put({
      type: rollNumberActions.SET_BATCH_CANDIDATES,
      payload: result?.data?.statusCode === 200 ? result?.data?.data : [],
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
};

const getExamDetailsWithCourseid = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/backoffice/rollnumber/${payload?.courseid}`)
    );
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    yield put({
      type: rollNumberActions.SET_EXAM_DETAILS_WITH_COURSEID,
      payload:
        result?.data?.statusCode === 200
          ? result?.data?.data
          : {
              batches: [],
              dates: [],
            },
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
};
const generateRollNumber = function* (data) {
  const { payload } = data;
  let { getDataInterval, setIntervalId, ...values } = payload;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/backoffice/rollnumber/generaterollnumber`, {
        ...values,
      })
    );
    if(result?.data?.statusCode === 200){
      payload?.setStatusModalOpen({
        open: true,
        statustext: "Processing...",
      });
      let intervalId = getDataInterval(payload?.courseid, result?.data?.data);
      setIntervalId(intervalId);
    }
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });

   
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });

    // yield put({
    //     type: rollNumberActions.SET_BATCH_CANDIDATES,
    //     payload: (result?.data?.statusCode === 200) ? result?.data?.data : []
    // });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
};

const getDownloadRollNoData = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(
        `${API_URL}/backoffice/rollnumber/getrollnumbercandidatedata`,
        payload
      )
    );
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });

    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });

    yield put({
      type: rollNumberActions.SET_DOWNLOAD_ROLL_NO_DATA,
      payload: result?.data?.statusCode === 200 ? result?.data?.data : [],
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
};

const resetRollNumber = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/backoffice/rollnumber/resetrollnumber`, payload)
    );
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    if(result?.data?.statusCode === 200){
      yield put({ type: rollNumberActions.GET_DOWNLOAD_ROLL_NO_DATA, payload: {
        courseid:payload?.courseid,
        batchid:payload?.batchid,
      },})
    }
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
};
export default rollNumberSagas;
