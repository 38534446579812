import { all, call, takeEvery, put, delay } from "redux-saga/effects";
import courseExamAndBatchActions from "./actions";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import commonActions from "../common/actions";
import forcePushAllocationActions from "../forcePushAllocation/actions";
import Cookies from "js-cookie";
import masterActions from "../master/actions";


const courseExamANdBatchSagas = function* () {
    yield all([
        yield takeEvery(courseExamAndBatchActions.GET_COURSE_BY_DRIVE, getCourseByDrive),
        yield takeEvery(courseExamAndBatchActions.CREATE_EXAMDATE, createExamDate),
        yield takeEvery(courseExamAndBatchActions.CREATE_EXAM_BATCH, createExamBatch),
        yield takeEvery(courseExamAndBatchActions.EDIT_EXAM_BATCH, updateExamBatch),
        
    ])
};

const getCourseByDrive = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/backoffice/course/bydriveid/${payload?.data}`)
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: courseExamAndBatchActions.SET_COURSE_BY_DRIVE, payload: result?.data?.result });
        };
    } catch (err) {
        console.log(err);
    };
};

const createExamDate = function* (data) {
    const { payload } = data;
    const { handleReset } = payload;

    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/backoffice/course/exam`, payload?.data)
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: forcePushAllocationActions.GET_EXAM_DATES_BY_COURSEID, payload: { courseid: payload?.data?.courseid } });
            yield put({ type: commonActions.SET_SHOW_MODAL, payload: { show: false, type: null, method: null, data: null } })
            handleReset();
        };
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                status: (result?.data?.statusCode === 200) ? "success" : "failed",
                show: true,
                message: result?.data?.message,
            },
        });
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { status: null, show: false, message: null },
        });
    } catch (err) {
        console.log(err);
    };
};
const createExamBatch = function* (data) {
    const { payload } = data;
    const { handleReset,navigate } = payload;

    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/master/batch/addbatch`, payload?.data)
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: courseExamAndBatchActions.GET_COURSE_BY_DRIVE, payload: { courseid: payload?.data?.driveid } });
            yield put({ type: commonActions.SET_SHOW_MODAL, payload: { show: false, type: null, method: null, data: null } })
            handleReset();
            navigate(`/${Cookies.get("mhet_mst_project")}/master-list`)
        };
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                status: (result?.data?.statusCode === 200) ? "success" : "failed",
                show: true,
                message: result?.data?.message,
            },
        });
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { status: null, show: false, message: null },
        });
    } catch (err) {
        console.log(err);
    };
};

const updateExamBatch = function* (data) {
    const { payload } = data;
    const { handleReset,handleClose } = payload;

    try {
        const result = yield call(() =>
            axios.put(`${API_URL}/master/batch/updatebatch`, payload?.data)
        );
        if (result?.data?.statusCode === 200) {
            yield put({type: masterActions.GET_ALL_BATCH  });
            yield put({ type: commonActions.SET_SHOW_MODAL, payload: { show: false, type: null, method: null, data: null } })
            handleReset();
            handleClose()
        };
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                status: (result?.data?.statusCode === 200) ? "success" : "failed",
                show: true,
                message: result?.data?.message,
            },
        });
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { status: null, show: false, message: null },
        });
    } catch (err) {
        console.log(err);
    };
};


export default courseExamANdBatchSagas;