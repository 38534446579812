import customerActions from "./actions";

const initialState = {
  reports: {
    applicationstatuswisereports: [],
    candidaturetypewisereport: [],
    categorywisereport: [],
    statewisereport: [],
    religionwisereport: [],
    examcenterwisereport: [],
    regionwisereport: [],
    genderwisereport: [],
    pwdwisereport: [],
    districtwisereport: [],
    stepwisereport: [],
  },

  payments: {
    paymenthistoryreport: [],
  },

  applicationStatus: {
    applicationstatusreport: [],
  },

  searchCandidate: {
    searchcandidatereport: [],
  },

  generateReports: {
    candidatewisedatareport: [],
    reginprogressdatawisereport: [],
    districtdatawisereport: [],
  },

  dateWiseRegistration: {
    datewiseregreport: [],
  },

  consolidateRept: {
    consolidatewisereport: [],
  },

  genderCategeoryRept: {
    genderCategeorywisereport: [],
  },
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case customerActions.SET_REPORTS:
      return {
        ...state,
        reports: action.payload,
      };

    case customerActions.SET_PAYMENT_HISTORY:
      return {
        ...state,
        payments: {
         
          paymenthistoryreport: action.payload,
        },
      };

    case customerActions.SET_APPLICATION_STATUS:
      return {
        ...state,
        applicationStatus: {
          
          applicationstatusreport: action.payload,
        },
      };

    case customerActions.SET_SEARCH_CANDIDATE:
      return {
        ...state,
        searchCandidate: {
          ...state.searchCandidate,
          searchcandidatereport: action.payload,
        },
      };

    case customerActions.SET_GENERATE_REPORT:
      return {
        ...state,
        generateReports: action.payload,
      };

    case customerActions.SET_DATEWISE_REPORT:
      return {
        ...state,
        dateWiseRegistration: action.payload,
      };

    case customerActions.SET_CONSOLIDATE_REPORT:
      return {
        ...state,
        consolidateRept: action.payload,
      };

    case customerActions.SET_GENDERCATEGEORY_REPORT:
      return {
        ...state,
        genderCategeoryRept: action.payload,
      };

    default:
      return state;
  }
};

export default customerReducer;
