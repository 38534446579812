import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Grid, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import candidateActions from "../../redux/candidate/actions";
import { useSearchParams } from "react-router-dom";
import { DecryptFunction } from "../../utils/cryptoFunction";

export default function UpdateCandidate() {
  const dispatch = useDispatch();
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    watch,
    setError,
    clearErrors,
    getValues,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });
  const { candidateUpdateDetails } = useSelector(
    (state) => state.candidateReducer
  );
  const [searchParams] = useSearchParams();
  const candidateId = DecryptFunction(searchParams.get("cid"));

  const handleUpdate = (data) => {
    dispatch({
      type: candidateActions.UPDATE_CANDIDATE_DETAILS,
      payload: {
        ssouserid: data?.ssouserid,
        candidateid: parseInt(data?.candidateid),
        candidatename: data?.candidatename,
        emailid:data?.emailid,
        mobileno:data?.mobileno,
        dob:data?.dob
      },
    });
  };

  useEffect(() => {
    dispatch({
      type: candidateActions.GET_SEARCH_CANDIDATE_DETAILS,
      payload: { data: { candidateid: candidateId } },
    });
    
  }, []);

  useEffect(() => {
    if (candidateId && candidateUpdateDetails) {
      const formattedDob = dayjs(candidateUpdateDetails[0]?.dob).format(
        "DD/MM/YYYY"
      );
      reset({ ...candidateUpdateDetails[0], dob: formattedDob });
    }
  }, [candidateId, candidateUpdateDetails]);

  const setAndClearError = ({ type, statusCode, message }) => {
    if (statusCode === 409) {
      setError(type, { message: message });
    } else {
      clearErrors(type);
    }
  };

  function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  const handleCheckDuplicate = (type) => {
    if (getValues(type)) {
      dispatch({
        type: candidateActions.CHECK_DUPLICATE_EMAIL_OR_MOBILENO,
        payload: {
          data: { type: type, value: getValues(type) },
          setAndClearError: setAndClearError,
        },
      });
    }
  };

  const debouncedCheck = debounce(handleCheckDuplicate, 100);

  return (
    <>
      <Grid container spacing={2} mt={2}>
        <Grid item md={6} sm={12}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            label={<span>Candidate Name</span>}
            placeholder="Enter name"
            {...register("candidatename", {
              required: "Candidate name is required",
            })}
            error={!!errors.candidatename}
            helperText={errors?.candidatename?.message}
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
            InputLabelProps={{
              shrink: !!watch("candidatename"),
            }}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <TextField
            fullWidth
            type="text"
            label={<span>Email Id</span>}
            placeholder="Enter email id"
            {...register("emailid", {
              required: "Email id is required",
            })}
            onKeyUp={() => {
              if (!errors.emailid) {
                debouncedCheck("emailid");
              }
            }}
            error={!!errors.emailid}
            helperText={errors.emailid?.message}
            FormHelperTextProps={{
              style: {
                margin: 0,
              },
            }}
            InputLabelProps={{
              shrink: !!watch("emailid"),
            }}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <TextField
            fullWidth
            type="text"
            label={<span>Mobile No</span>}
            placeholder="Enter mobile no"
            {...register("mobileno", {
              required: "Mobile no is required",
            })}
            onKeyUp={() => {
              if (!errors.mobileno) {
                debouncedCheck("mobileno");
              }
            }}
            error={!!errors.mobileno}
            helperText={errors?.mobileno?.message}
            FormHelperTextProps={{
              style: {
                margin: 0,
              },
            }}
            InputLabelProps={{
              shrink: !!watch("mobileno"),
            }}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              fullWidth
              name="dob"
              id="dob"
              value={watch("dob") ? dayjs(watch("dob"), "DD/MM/YYYY") : null}
              onChange={(date) => {
                const localDate = dayjs(date).format("DD/MM/YYYY");
                setValue("dob", localDate, { shouldValidate: true });
              }}
              slotProps={{
                textField: {
                  helperText: errors.dob ? errors.dob.message : "",
                  error: !!errors.dob,
                  style: {
                    width: "100%",
                    borderRadius: "8px",
                  },
                  FormHelperTextProps: {
                    style: {
                      margin: 0,
                    },
                  },
                },
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>

        <Grid
          item
          md={12}
          sm={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
          mt={2}
        >
          <MauvelousPrimaryButton
            title={"Update Candidate"}
            handleClick={handleSubmit(handleUpdate)}
            // disabled={errors}
          />
        </Grid>
      </Grid>
    </>
  );
}
