import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BluePrimaryButton from "../../common/button/bluePrimaryButton";
import CommonTable from "../../common/CommonTable";
import { useDispatch, useSelector } from "react-redux";
import masterActions from "../../redux/master/actions";
import customerActions from "../../redux/customer/actions";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import candidateActions from "../../redux/candidate/actions";

export default function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [courseid, setCourseId] = useState(0);
  const [emailid, setEmailId] = useState("");
  const [mobileno, setMobileNo] = useState("");
  const [applicantionid, setapplicantionId] = useState("");
  const [candidatename, setcandidateName] = useState("");
  const [dob, setDob] = useState(null);
  const [buttonClick,setButtonClick] = useState(false);


  const [courseError, setCourseError] = useState("");
  const [commonError, setCommonError] = useState("");
  const [pdfUrl, setPdfUrl] = useState(null);
  const { courseList } = useSelector((state) => state.masterReducer);
  const { searchCandidate } = useSelector((state) => state.customerReducer);

  console.log("search-candidate", searchCandidate);

  useEffect(() => {
    dispatch({ type: masterActions.GET_COURSE_LIST });
  }, []);

  // useEffect(() => {
  //   if (searchCandidate && searchCandidate.length > 0) {
  //     console.log('searchCandidate data:', searchCandidate); // Debugging
  //     const candidate = searchCandidate[0];
  //     setCandateId(candidate.candidateid);
  //   }
  // }, [searchCandidate]);


  useEffect(() => {
    const storedSearchCandidate = localStorage.getItem("searchCandidateData");
    if (storedSearchCandidate) {
      const parsedData = JSON.parse(storedSearchCandidate);
      setCourseId(parsedData.courseid || 0);
      setEmailId(parsedData.emailid || "");
      setMobileNo(parsedData.mobileno || "");
      setapplicantionId(parsedData.applicantionid || "");
      setcandidateName(parsedData.candidatename || "");
      setDob(parsedData.dob || null);
    }
  }, []);
  

  const onChangeCourse = (e) => {
    setCourseId(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmailId(e.target.value);
  };

  const handleMobileChange = (e) => {
    setMobileNo(e.target.value);
  };

  const handleApplicationChange = (e) => {
    setapplicantionId(e.target.value);
  };

  const handleCandidatenameChange = (e) => {
    setcandidateName(e.target.value);
  };

  const handleDobChange = (newValue) => {
    setDob(newValue);
  };

  const handleGenerate = () => {
    setButtonClick(true)
    let hasError = false;

    if (courseid === 0) {
      setCourseError("Course is required");
      hasError = true;
    } else {
      setCourseError("");
    }

    if (!emailid && !mobileno && !applicantionid && !dob && !candidatename) {
      setCommonError(
        "Atleast Email ID or Mobile No or Application No or DOB or Candidate Name is required"
      );
      hasError = true;
    } else {
      setCommonError("");
    }

    if (!hasError) {
      const searchPayload = {
        courseid,
        emailid,
        mobileno,
        applicantionid,
        dob: dob ? dob.format("YYYY-MM-DD") : "",
        candidatename,
      };
  
      localStorage.setItem("searchCandidateData", JSON.stringify(searchPayload));
  
      dispatch({
        type: customerActions.GET_SEARCH_CANDIDATE,
        payload: searchPayload,
      });
    }
  };


    // useEffect(() => {
    //     dispatch({
    //       type: customerActions.GET_SEARCH_CANDIDATE,
    //       payload: [],
    //     });
   
    // }, []);

  const navigateToReceipt = (
    candidateid,
    courseid,
    coursename,
    amount,
    fullCandidateDetails,
    uploadDetails
  ) => {
    if (!candidateid) {
      console.error("Missing candidateid");
      return;
    }

    dispatch({
      type: candidateActions.GET_FULL_CANDIDATE_DETAILS,
      payload: {
        id: candidateid,
        fullCandidateDetails: fullCandidateDetails,
      },
    });

    dispatch({
      type: candidateActions.GET_UPLOAD_PERSONAL_DETAILS,
      payload: {
        id: candidateid,
        uploadDetails: uploadDetails,
      },
    });

    navigate(`/${Cookies.get("mhet_mst_project")}/PrintApplicationForm`, {
      state: { candidateid, courseid, coursename,amount },
      replace: true,
    });
  };

  const statusMapping = {
    1: "Registration/Personal Details",
    2: "Registration/Personal Details",
    3: "Domicile and Category Details",
    4: "Qualification Details",
    5: "Set Examination Center Preferences",
    6: "Upload Required Document for Proof of Identity",
    7: "Preview and Validate Information",
    8: "Fee Paid And Confirmed",
    9: "Application Printed",
  };

  let candidateInfoColumns = [
    {
      name: "Sr. No",
      dataIndex: "srNo",
    },
    {
      name: "Application No",
      dataIndex: "applicationNo",
      render: (text, record) => {
        if (record.applicationNo) {
          return (
            <Button
              variant="text"
              sx={{ color: "blue" }}
              onClick={() =>
                navigateToReceipt(
                  record.candidateid,
                  record.courseid,
                  record.coursename,
                  record.amount

                )
              }
            >
              {record.applicationNo}
            </Button>
          );
        }
        return "N/A";
      },
    },
    {
      name: "Candidate's Name",
      dataIndex: "candidateName",
    },
    {
      name: "E-Mail ID",
      dataIndex: "emailId",
    },
    {
      name: "DOB",
      dataIndex: "dob",
    },

    {
      name: "Application Status",
      dataIndex: "applicationStatus",
    },
  ];

  const formattedTableData = searchCandidate?.searchcandidatereport.map(
    (item, index) => ({
      srNo: index + 1,
      applicationNo: item.applicantionid,
      candidateName: item.candidatename,
      emailId: item.emailid,
      dob: item.dateofbirth,
      applicationStatus: statusMapping[item.status] || "N/A",
      candidateid: item.candidateid,
      courseid: item.courseid,
      coursename: item.coursename,
      amount:item.amount,

    })
  );

  useEffect(() => {
    const removeLocalStorage = () => {
      localStorage.removeItem("searchCandidateData");
    };
  
    window.addEventListener('beforeunload', removeLocalStorage);
  
    return () => {
      window.removeEventListener('beforeunload', removeLocalStorage);
    };
  }, []);

  return (
    <>
      <Card sx={{ p: 2 }}>
        <Typography>Search Candidate</Typography>

        <Box
          sx={{
            margin: "1rem auto",
            backgroundColor: "rgba(255, 255, 255, 1)",
            borderRadius: "8px",
            padding: "10px",
          }}
        >
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="subtitle1"
              sx={{ color: "red", fontWeight: "bold" }}
            >
              Instructions :
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "500",
                color: "red",
                fontSize: "16px",
                lineHeight: "24px",
                mb: 2,
                backgroundColor: "#f6e9e9",
                border: "1px solid red",
                padding: "8px",
                borderRadius: "4px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box display="flex" alignItems="center">
                <span style={{ fontSize: "24px", marginRight: "8px" }}>•</span>
                Please Enter Application No and/or Candidate's Name and/or DOB
                and/or Mobile No and/or E-mail Id to Search Candidate
              </Box>

              <Box display="flex" alignItems="center" marginTop="4px">
                <span style={{ fontSize: "24px", marginRight: "8px" }}>•</span>
                Please Enter atleast one parameter
              </Box>
            </Typography>
          </Box>
          <Grid item sm={12}>
            <Box
              display="flex"
              justifyContent="flex"
              sx={{ gap: 2, mt: 2, mb: 1 }}
            >
              <FormControl
                error={!!courseError}
                sx={{ width: "100%", height: "80px", display: "flex" }}
              >
                <Select
                  size="small"
                  onChange={onChangeCourse}
                  value={courseid}
                  defaultValue={0}
                  sx={{ height: "56px", width: "100%" }}
                >
                  <MenuItem value={0}>
                    Select Course <span style={{ color: "red" }}>*</span>
                  </MenuItem>
                  {courseList?.all
                    ?.filter((course) => course.isactive === "1")
                    .map((course, i) => (
                      <MenuItem key={i} value={course.courseid}>
                        {course.coursename}
                      </MenuItem>
                    ))}
                </Select>
                {courseError && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ marginTop: "4px" }}
                  >
                    {courseError}
                  </Typography>
                )}
              </FormControl>

              <FormControl
                sx={{ width: "100%" }}
                variant="outlined"
                error={!!commonError}
              >
                <InputLabel htmlFor="search-application">
                  Search by Application No
                </InputLabel>
                <OutlinedInput
                  id="search-application"
                  value={applicantionid}
                  onChange={handleApplicationChange}
                  label="Search by Application No"
                />
                {commonError && (
                  <Typography variant="caption" color="error">
                    {commonError}
                  </Typography>
                )}
              </FormControl>

              <FormControl
                sx={{ width: "100%" }}
                variant="outlined"
                error={!!commonError}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="DOB (DD/MM/YYYY)"
                    value={dob}
                    onChange={handleDobChange}
                    slotProps={{
                      textField: {
                        error: !!commonError,
                      },
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                {commonError && (
                  <Typography variant="caption" color="error">
                    {commonError}
                  </Typography>
                )}
              </FormControl>
            </Box>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <FormControl
                sx={{ width: "100%" }}
                variant="outlined"
                error={!!commonError}
              >
                <InputLabel htmlFor="search-candidate-name">
                  Candidate's Name
                </InputLabel>
                <OutlinedInput
                  id="search-candidate-name"
                  value={candidatename}
                  onChange={handleCandidatenameChange}
                  label="Candidate's Name"
                />
                {commonError && (
                  <Typography variant="caption" color="error">
                    {commonError}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl
                sx={{ width: "100%" }}
                variant="outlined"
                error={!!commonError}
              >
                <InputLabel htmlFor="search-email">
                  Search by Email ID
                </InputLabel>
                <OutlinedInput
                  id="search-email"
                  value={emailid}
                  onChange={handleEmailChange}
                  label="Search by Email ID"
                />
                {commonError && (
                  <Typography variant="caption" color="error">
                    {commonError}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl
                sx={{ width: "100%" }}
                variant="outlined"
                error={!!commonError}
              >
                <InputLabel htmlFor="search-mobile">
                  Search by Mobile No
                </InputLabel>
                <OutlinedInput
                  id="search-mobile"
                  value={mobileno}
                  onChange={handleMobileChange}
                  label="Search by Mobile No"
                />
                {commonError && (
                  <Typography variant="caption" color="error">
                    {commonError}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" sx={{ marginTop: 2 }}>
            <BluePrimaryButton
              title={"Search Candidate"}
              handleSubmit={handleGenerate}
            />
          </Box>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "500",
              color: "black",
              fontSize: "20px",
              lineHeight: "24px",
              mb: 2,
              backgroundColor: "whitesmoke",
              padding: "8px",
              borderRadius: "4px",
            }}
          >
            Candidate Details
          </Typography>
        </Box>

        <Box
          sx={{
            margin: "1rem auto",
            backgroundColor: "rgba(255, 255, 255, 1)",
            borderRadius: "8px",
            padding: "10px",
          }}
        >
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="subtitle1"
              sx={{ color: "red", fontWeight: "bold" }}
            >
              Instructions :
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "500",
                color: "red",
                fontSize: "16px",
                lineHeight: "24px",
                mb: 2,
                backgroundColor: "#f6e9e9",
                border: "1px solid red",
                padding: "8px",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: "24px", marginRight: "8px" }}>•</span>
              Please Click on Application No. to Print Application Form.
            </Typography>
          </Box>
       { (formattedTableData && formattedTableData?.length>0) && <CommonTable
            columnData={candidateInfoColumns}
            tableData={formattedTableData || []}
          />}

{
  ((formattedTableData?.length == 0 || formattedTableData == undefined)&&(buttonClick))&&
<Box>
<Typography
              variant="subtitle1"
              sx={{    fontWeight: "bold",textAlign:"center" }}
            >
No Records Found 
            </Typography>
</Box>}
        </Box>
      </Card>
    </>
  );
}
