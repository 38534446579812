import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import { cssProperties } from "../utils/commonCssProperties";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import TablePagination from "./tablePagination";

export default function DashboardTable({ tableData, columnData }) {
  const [rows, setRows] = React.useState(tableData);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);

  React.useEffect(() => {
    setRows(tableData);
  }, [tableData]);

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setCurrentPage(1); // Reset to first page when rows per page changes
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedRows = rows.slice(startIndex, endIndex);

  const totalPages = Math.ceil(rows.length / rowsPerPage);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          mb: 2,
          pr:2
        }}
      >
        {/* <Box>
          <FormControl variant="outlined" size="small">
            <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
            <Select
              labelId="rows-per-page-label"
              id="rows-per-page"
              value={rowsPerPage}
              label="Rows per page"
              size="small"
              onChange={handleRowsPerPageChange}
              sx={{ minWidth: 100 }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
        </Box> */}
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          background: "none",
          border: `1px solid ${cssProperties.bordercolor.primary2blue}`,
          boxShadow: "none",
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {columnData?.map((obj, i) => (
                <TableCell
                  sx={{
                    color: cssProperties.bordercolor.primary2blue,
                    textAlign: "center",
                  }}
                  key={i}
                >
                  {obj.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.length > 0 ? (
              paginatedRows.map((row, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {columnData?.map((param, i) => (
                    <TableCell
                      component="td"
                      key={i}
                      sx={{ textAlign: "center" }}
                    >
                      {param["render"]
                        ? param["render"](row[param.dataIndex], row)
                        : row[param.dataIndex]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columnData?.length}
                  component="td"
                  sx={{
                    textAlign: "center",
                    color: cssProperties.bordercolor.primary2blue,
                  }}
                >
                  No Result Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <TablePagination
          pageCount={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </Box> */}
    </div>
  );
}
