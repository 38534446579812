import { all, call, takeEvery, put } from "redux-saga/effects";
import customerActions from "./actions";
import axios from "axios";
import { API_URL } from "../../utils/constants";

const customerSagas = function* () {
  yield all([
    yield takeEvery(customerActions.GET_REPORTS, getReports),
    yield takeEvery(customerActions.GET_PAYMENT_HISTORY, getPaymentHistory),
    yield takeEvery(customerActions.GET_APPLICATION_STATUS,getApplicationStatus   ),
    yield takeEvery(customerActions.GET_SEARCH_CANDIDATE, getSearchCandidate),
    yield takeEvery(customerActions.GET_GENERATE_REPORT, getGenerateReport),
    yield takeEvery(customerActions.GET_DATEWISE_REPORT, getDatewiseReport),
    yield takeEvery(customerActions.GET_CONSOLIDATE_REPORT, getConsolidateReport),
    yield takeEvery(customerActions.GET_GENDERCATEGEORY_REPORT, getGenderCategeoryReport),


  ]);
};

const getReports = function* (data) {
  const { payload } = data;

  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/backoffice/customer/reports/${payload?.courseid}`)
    );
    if (result?.data?.statusCode === 200) {
      yield put({
        type: customerActions.SET_REPORTS,
        payload: result?.data?.data,
      });
    }
    else{
      
    }
  } catch (err) {
    console.log(err);
  }
};

const getPaymentHistory = function* (data) {
  const { courseid, emailid, mobileno, applicantionid } = data.payload;
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/backoffice/customer/payment-history`, {
        params: {
          courseid: courseid,
          emailid: emailid,
          mobileno: mobileno,
          applicantionid: applicantionid,
        },
      })
    );
    if (result?.data?.statusCode === 200) {
      yield put({
        type: customerActions.SET_PAYMENT_HISTORY,
        payload: result?.data?.result,
      });
    }
    else{
      yield put({
        type: customerActions.SET_PAYMENT_HISTORY,
        payload: [],
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getApplicationStatus = function* (data) {
  const { courseid, emailid, mobileno, applicantionid, dob, candidatename } =
    data.payload;
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/backoffice/customer/application-status`, {
        params: {
          courseid: courseid,
          emailid: emailid,
          mobileno: mobileno,
          applicantionid: applicantionid,
          dob: dob,
          candidatename: candidatename,
        },
      })
    );
    if (result?.data?.statusCode === 200) {
      yield put({
        type: customerActions.SET_APPLICATION_STATUS,
        payload: result?.data?.result,
      });
    }else{
      yield put({
        type: customerActions.SET_APPLICATION_STATUS,
        payload: [],
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getSearchCandidate = function* (data) {
  const { courseid, emailid, mobileno, applicantionid, dob, candidatename } =
    data.payload;
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/backoffice/customer/search-candiate`, {
        params: {
          courseid: courseid,
          emailid: emailid,
          mobileno: mobileno,
          applicantionid: applicantionid,
          dob: dob,
          candidatename: candidatename,
        },
      })
    );
    if (result?.data?.statusCode === 200) {
      yield put({
        type: customerActions.SET_SEARCH_CANDIDATE,
        payload: result?.data?.result,
      });
    }
    else{
      yield put({
        type: customerActions.SET_SEARCH_CANDIDATE,
        payload: [],
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getGenerateReport = function* (data) {
  const { payload } = data;

  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/backoffice/customer/generate-report/${payload?.courseid}`
      )
    );
    if (result?.data?.statusCode === 200) {
      yield put({
        type: customerActions.SET_GENERATE_REPORT,
        payload: result?.data?.data,
      });
    }
    
  } catch (err) {
    console.log(err);
  }
};

const getDatewiseReport = function* () {
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/backoffice/customer/datewise-reg-report`)
    );

    if (result?.data?.statusCode === 200) {
      const dateWiseReportData = result.data.result[0]?.rpt_datewaisereport;

      yield put({
        type: customerActions.SET_DATEWISE_REPORT,
        payload: dateWiseReportData,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

const getConsolidateReport = function* () {
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/backoffice/customer/consolidate-report`)
    );

    if (result?.data?.statusCode === 200) {
      const consolidateReportData = result.data.result;

      yield put({
        type: customerActions.SET_CONSOLIDATE_REPORT,
        payload: consolidateReportData,
      });
    }
  } catch (err) {
    console.error("Error fetching consolidate report:", err);
  }
};


const getGenderCategeoryReport = function* () {
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/backoffice/customer/gender-categeory-report`)
    );

    if (result?.data?.statusCode === 200) {
      const genderCategoryData = result.data.result[0]?.rpt_gendercategoryreport;

      yield put({
        type: customerActions.SET_GENDERCATEGEORY_REPORT,
        payload: genderCategoryData,
      });
    }
  } catch (err) {
    console.error(err);
  }
};




export default customerSagas;
