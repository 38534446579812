import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import masterFormsActions from "../../redux/masterForms/actions";
import CreateMasterModal from "./modals/createMasterModal";
import { useForm } from "react-hook-form";
import EditMasterModal from "./modals/editMasterModal";
import commonActions from "../../redux/common/actions";
import { Box, Radio } from "@mui/material";
import Searchbar from "../../common/searchbar";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import DataTable from "../../common/dataTable";
import TablePagination from "../../common/tablePagination";
import { itemsPerPage } from "../../utils/constants";
import BrowseUploadButton from "../../common/button/browseUploadButton";
import { MdOutlinePictureAsPdf } from "react-icons/md";
import masterActions from "../../redux/master/actions";
import BulkUploadModal from "./modals/bulkUploadModal";
import { PiPlusBold } from "react-icons/pi";

const tableKeys = [
  {
    name: "SI.NO",
    accessor: "id",
    // accessor: 'genderid',
    sortOption: false,
  },
  {
    name: "Sports Participation",
    accessor: "sportsparticipation",
    sortOption: false,
  },
  {
    name: "Enable",
    accessor: "enable",
    sortOption: false,
  },
  {
    name: "Disable",
    accessor: "disable",
    sortOption: false,
  },
];

export default function SportsParticipationMaster() {
  const dispatch = useDispatch();

  const { sportsParticipationList } = useSelector(
    (state) => state.masterFormsReducer
  );

  const { backofficeTokenDetails } = useSelector((state) => state.authReducer);

  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    trigger,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {},
  });

  useEffect(() => {
    dispatch({ type: masterFormsActions.GET_SPORTS_PARTICIPATION });
    //eslint-disable-next-line
  }, []);

  const handleActive = (active, type, sportsparticipationid) => {
    if (type !== active) {
      let data = {
        userid: backofficeTokenDetails?.id,
        id: sportsparticipationid,
        idname: "sportsparticipationid",
        status: type,
        action: masterFormsActions.GET_SPORTS_PARTICIPATION,
      };

      dispatch({
        type: masterActions.SET_MASTERS_STATUS,
        payload: data,
      });
    }
  };

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = sportsParticipationList?.filtered
    ?.map((obj) => {
      return {
        ...obj,
        enable: (
          <Radio
            checked={obj.status === "1"}
            onClick={() =>
              handleActive(obj.status, "1", obj.sportsparticipationid)
            }
          />
        ),
        disable: (
          <Radio
            checked={obj.status === "0"}
            onClick={() =>
              handleActive(obj.status, "0", obj.sportsparticipationid)
            }
          />
        ),
      };
    })
    ?.slice(itemOffset, endOffset);

  const pageCount = Math.ceil(
    sportsParticipationList?.filtered?.length / itemsPerPage
  );

  const handlePageChange = (event, value) => {
    const newOffset = (value - 1) * itemsPerPage;
    setItemOffset(newOffset);
    setCurrentPage(value);
  };

  const handleCreate = (data) => {
    data["sportsparticipationid"] = parseInt(data.sportsparticipationid);
    data["sportsparticipation"] = data.name;
    data["status"] = "1";
    data["userid"] = Number(backofficeTokenDetails?.id);
    dispatch({
      type: masterFormsActions.CREATE_SPORTS_PARTICIPATION,
      payload: { data: data, resetForm: resetForm },
    });
  };

  const resetForm = () => {
    reset({
      name: "",
    });
  };

  const handleSearch = (e) => {
    let filtered = [];
    setCurrentPage(1);
    setItemOffset(0);
    if (e.target.value) {
      //eslint-disable-next-line
      filtered = sportsParticipationList?.all?.filter((val) => {
        if (
          val?.sportsparticipation
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase())
        ) {
          return val;
        }
      });
    } else {
      filtered = [...sportsParticipationList?.all];
    }
    dispatch({
      type: masterFormsActions.SET_SPORTS_PARTICIPATION,
      payload: { ...sportsParticipationList, filtered: filtered },
    });
  };

  const handleMasteredit = (page, value) => {
    dispatch({
      type: commonActions.SET_SHOW_MODAL,
      payload: { show: true, type: page, method: "U", data: null },
    });
    reset({
      name: value?.sportsparticipation,
      sportsparticipationid: value?.sportsparticipationid,
      userid: value?.userid,
    });
  };

  const handleUpdate = (data) => {
    data["sportsparticipationid"] = parseInt(data.sportsparticipationid);
    data["sportsparticipation"] = data.name;
    data["status"] = "1";
    data["userid"] = Number(backofficeTokenDetails?.id);
    dispatch({
      type: masterFormsActions.UPDATE_SPORTS_PARTICIPATION,
      payload: {
        id: data?.sportsparticipationid,
        data: data,
        resetForm: resetForm,
      },
    });
  };

  const handleshowModal = () => {
    dispatch({
      type: commonActions.SET_SHOW_MODAL,
      payload: {
        show: true,
        type: "sportsparticipation",
        method: "C",
        data: null,
      },
    });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: "24px",
          marginBottom: "14px",
        }}
      >
        {/* <Box></Box> */}
        <Searchbar height={48} handleSearch={handleSearch} />
        <MauvelousPrimaryButton
          title={"Add"}
          icon={<PiPlusBold />}
          handleClick={handleshowModal}
        />
      </Box>
      <Box sx={{ height: "calc(100vh - 280px)" }}>
        <DataTable
          keys={tableKeys}
          values={currentItems}
          page={"sportsparticipation"}
          handleMasteredit={handleMasteredit}
        />
      </Box>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          right: "24px",
          padding: "15px 0",
          width: "100%",
          py: 1,
        }}
      >
        <TablePagination
          pageCount={pageCount}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
        />
      </Box>
      <CreateMasterModal
        register={register}
        getValues={getValues}
        trigger={trigger}
        handleSubmit={handleSubmit}
        errors={errors}
        handleCreate={handleCreate}
        resetForm={resetForm}
      />
      <EditMasterModal
        register={register}
        handleSubmit={handleSubmit}
        getValues={getValues}
        trigger={trigger}
        errors={errors}
        handleUpdate={handleUpdate}
        resetForm={resetForm}
      />
      <BulkUploadModal />
    </>
  );
}
